export default function ExponentialCallback() {
  async function wait(ms: number): Promise<void> {
    await new Promise(res => setTimeout(res, ms));
  }

  const callWithRetry = async (
    func: Function,
    depth: number = 0,
    maxDepth: number = 3,
    ms: number = 100
  ): Promise<any> => {
    try {
      return await func();
    } catch (e) {
      if (depth > maxDepth) {
        throw new Error();
    }
    await wait(maxDepth ** depth * ms);
    return callWithRetry(func, depth + 1, maxDepth, ms);
    }
  };
  
  return callWithRetry;
}