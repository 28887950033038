import React, { useContext, useEffect } from 'react';
import { useCurrentSite } from '../use-current-site';
import { useCurrentSiteEndpoint } from '../use-current-site-endpoint';
import { useImmer } from '../use-immer';
const Context = React.createContext(undefined);
function* reportContextIterator() {
    for (const item of Object.values(this)) {
        yield item;
    }
}
function* datasetContextIterator() {
    for (const item of Object.values(this)) {
        yield item;
    }
}
/**
 * Declares a PowerBI context which fetches and manages access to reports and datasets inside
 * the PowerBI workspace for the current site.
 *
 * All components inside this context have access to the <Hooks TBD> hooks.
 *
 * @component
 * @category RAPID Application
 */
export function ManagedPowerBIContext({ children, }) {
    const [site] = useCurrentSite();
    const ep = useCurrentSiteEndpoint();
    const workspaceId = site.PowerBIWorkspaceID;
    async function fetchReports() {
        let res;
        updateContext(draft => {
            draft.loading = true;
        });
        try {
            res = await ep.Reports.get({ workspaceId });
            if (res.ok) {
                const data = await res.json();
                updateContext(draft => {
                    draft.reports = data.reduce((prev, curr) => ({ ...prev, [curr.id]: curr }), {
                        [Symbol.iterator]: reportContextIterator,
                    });
                });
            }
        }
        finally {
            updateContext(draft => {
                draft.loading = false;
            });
        }
    }
    async function fetchDatasets() {
        let res;
        updateContext(draft => {
            draft.loading = true;
        });
        try {
            res = await ep.Reports.Datasets.get();
            if (res.ok) {
                const data = await res.json();
                updateContext(draft => {
                    draft.datasets = data.reduce((prev, curr) => ({ ...prev, [curr.id]: curr }), {
                        [Symbol.iterator]: datasetContextIterator,
                    });
                });
            }
        }
        finally {
            updateContext(draft => {
                draft.loading = false;
            });
        }
    }
    async function deleteReport(reportId) {
        updateContext(draft => {
            draft.loading = true;
        });
        try {
            const resp = await ep.Reports.delete({ reportId });
            if (resp.ok) {
                updateContext(draft => {
                    delete draft.reports[reportId];
                });
            }
        }
        finally {
            updateContext(draft => {
                draft.loading = false;
            });
        }
    }
    async function deleteDataset(datasets) {
        let res;
        updateContext(draft => {
            draft.loading = true;
        });
        try {
            res = await ep.Xmla.Datasets.delete({}, { Datasets: datasets.map(dataset => dataset.name) });
            if (res.ok) {
                for (let dataset of datasets) {
                    updateContext(draft => {
                        delete draft.datasets[dataset.id];
                    });
                }
            }
        }
        finally {
            updateContext(draft => {
                draft.loading = false;
            });
        }
    }
    const [context, updateContext] = useImmer({
        reports: {
            [Symbol.iterator]: reportContextIterator,
        },
        datasets: {
            [Symbol.iterator]: datasetContextIterator,
        },
        loading: false,
        fetchReports,
        fetchDatasets,
        deleteReport,
        deleteDataset,
    });
    useEffect(function fetchReportsEffect() {
        fetchReports();
    }, []);
    useEffect(function fetchDatasetsEffect() {
        fetchDatasets();
    }, []);
    return (React.createElement(Context.Provider, { value: [context, updateContext] }, children));
}
/**
 * Provides access to the Power BI workspace anywhere underneath a {@link ManagedPowerBIContext}.
 *
 * @hook
 * @category RAPID Application
 */
export function usePowerBIContext() {
    const context = useContext(Context);
    return context;
}
/**
 * Provides access to the Power BI reports for a workspace anywhere underneath a {@link ManagedPowerBIContext}.
 *
 * @hook
 * @category RAPID Application
 */
export function usePowerBIReports() {
    const [powerbi, updatePowerBI] = usePowerBIContext();
    return [
        powerbi.reports,
        function updateReports(d) {
            updatePowerBI(draft => {
                draft.reports = d(draft.reports);
            });
        },
    ];
}
/**
 * Provides access to a Power BI report for a workspace anywhere underneath a {@link ManagedPowerBIContext}.
 *
 * @hook
 * @category RAPID Application
 */
export function usePowerBIReport(id) {
    const [powerBIReports, updatePowerBIReports] = usePowerBIReports();
    return [
        powerBIReports[id],
        function updatePowerBIReport(d) {
            updatePowerBIReports(draft => {
                draft[id] = d(draft[id]);
            });
        },
    ];
}
/**
 * Provides access to the Power BI datasets for a workspace anywhere underneath a {@link ManagedPowerBIContext}.
 *
 * @hook
 * @category RAPID Application
 */
export function usePowerBIDatasets() {
    const [powerbi, updatePowerBI] = usePowerBIContext();
    return [
        powerbi.datasets,
        function updateDatasets(d) {
            updatePowerBI(draft => {
                draft.datasets = d(draft.datasets);
            });
        },
    ];
}
/**
 * Provides access to a Power BI dataset for a workspace anywhere underneath a {@link ManagedPowerBIContext}.
 *
 * @hook
 * @category RAPID Application
 */
export function usePowerBIDataset(id) {
    const [powerBIDatasets, updatePowerBIDatasets] = usePowerBIDatasets();
    return [
        powerBIDatasets[id],
        function updatePowerBIDataset(d) {
            updatePowerBIDatasets(draft => {
                draft[id] = d(draft[id]);
            });
        },
    ];
}
