import { FormElementInputProps } from '@rapid/adaptive-forms';
import { IQueryBuilderOrderBy } from '@rapid/data-model/lib/query-builder';
import { useComposedClassName } from '@rapid/sdk';
import { Radio, RadioChangeEvent, Select } from 'antd';
import { useMemo } from 'react';
import {
  FieldAlias,
  IQueryBuilderType,
  useQueryBuilderContext,
} from '../QueryBuilder';
import { removeData } from '../utils/helpers';

export function OrderBySelect({
  disabled,
  id,
  value,
  label,
  onChange,
}: FormElementInputProps<IQueryBuilderOrderBy>) {
  const { Option } = Select;
  const [context, updateContext] = useQueryBuilderContext();

  const orderOptions = [
    { label: 'Ascending', value: 'asc' },
    { label: 'Descending', value: 'desc' },
  ];

  const OrderBySelectClass = useComposedClassName(
    function* () {
      yield 'Label OrderBySelect';

      if (disabled) yield 'disabled';
    },
    [disabled],
  );

  const selectedFields = useMemo(() => {
    if (!!context.data) {
      const rootId = context.form.id;
      const data = context.data[rootId];

      if (!!data) {
        for (const key of Object.keys(data)) {
          if (key.match(/^Select/i)) {
            if (!!data[key]) return data[key] as string[];
          }
        }
      }
    }
  }, [context.data]);

  const onOrderChangeInner = (e: RadioChangeEvent) => {
    e.preventDefault();
    e.stopPropagation();

    if (value?.column) {
      const val: IQueryBuilderOrderBy = {
        ...value,
        order: e.target.value,
      };

      onChange?.(val);
    }
  };

  const onColumnChangeInner = (str: string) => {
    if (!str) {
      updateContext(d => {
        removeData(id, d.data);
      });
      return;
    }
    const val: IQueryBuilderOrderBy = {
      ...value,
      column: str,
    };

    onChange?.(val);
  };

  const Options = useMemo(() => {
    if (context.type === IQueryBuilderType.Basic) {
      return (context.fieldChoices as string[])
        ?.filter(choice => selectedFields?.some(s => s === choice))
        ?.map(choice => (
          <Option key={choice} value={choice}>
            {choice}
          </Option>
        ));
    }

    return (context.fieldChoices as FieldAlias[])
      ?.filter(choice =>
        selectedFields?.some(s => {
          const [id, column] = s.split('.');
          return choice.id === id && choice.column === column;
        }),
      )
      ?.map(choice => (
        <Option
          key={`${choice.id}.${choice.column}`}
          value={`${choice.id}.${choice.column}`}
        >
          {`${choice.tableAlias || choice.table}.${choice.column}`}
        </Option>
      ));
  }, [selectedFields, context.fieldChoices, context.type]);

  return (
    <label className={OrderBySelectClass} id={id}>
      {!!label && <span className="Name">{label}</span>}
      <div className="flex flex-center">
        <Select
          showSearch
          allowClear
          placeholder="Select Fields"
          value={value?.column}
          onChange={onColumnChangeInner}
          size="small"
        >
          {Options}
        </Select>
        &ensp;
        <Radio.Group
          options={orderOptions}
          onChange={onOrderChangeInner}
          value={value?.order}
          optionType="button"
          size="small"
        />
      </div>
    </label>
  );
}
