import React from 'react';
import { useEnvironments } from '../use-environments';
/**
 * Select element that contains a list of RAPID Platform environments.
 *
 * @category UI
 * @component
 */
export function EnvironmentPicker({ value, onChange, }) {
    const [, envGroups] = useEnvironments();
    function onChangeInternal(ev) {
        onChange === null || onChange === void 0 ? void 0 : onChange(ev.target.value);
    }
    return (React.createElement("select", { className: "rp-input rp-select", id: "environment", placeholder: "Select an environment", onChange: onChangeInternal, value: value }, Object.entries(envGroups).map(([key, envs]) => (React.createElement("optgroup", { key: key, label: key }, envs.map(env => (React.createElement("option", { key: env.key, value: env.key }, env.name))))))));
}
