/**
* Throttles a function
*
* @example
* ```ts
* const throtFunc = throttle(func, 1000);
*
* throtFunc(arg);
* ```
**/
export const throttle = (func, waitFor) => {
    const now = () => new Date().getTime();
    const resetStartTime = () => startTime = now();
    let timeout;
    let startTime = now() - waitFor;
    return (...args) => new Promise((resolve) => {
        const timeLeft = (startTime + waitFor) - now();
        if (timeout) {
            clearTimeout(timeout);
        }
        if (startTime + waitFor <= now()) {
            resetStartTime();
            resolve(func(...args));
        }
        else {
            timeout = setTimeout(() => {
                resetStartTime();
                resolve(func(...args));
            }, timeLeft);
        }
    });
};
