import { IList } from "@rapid/data-model";
import { useCurrentSite } from "@rapid/sdk";

export function useTitleField() {
  const [currentSite] = useCurrentSite();

  function getTitleField(list: string | IList<any>): string|undefined {
     
    const innerList =
      typeof list !== 'string'
        ? list
        : currentSite?.Lists.find(l => l.ListName.localeCompare(list) === 0)!;
  
    if (innerList.Extends === undefined)
      return innerList.TitleField;
  
    const extendedList = currentSite?.Lists.find(
        l =>
          l.ListName.localeCompare(
            innerList.Extends!) === 0,
      )!;

    return extendedList.TitleField;  
  }

  return { getTitleField };
}