import React from 'react';
import { descriptionTag, fieldAttributesSchemaTag, iconTag, titleTag, } from '../symbols';
import { NumberField } from './number-field';
export function CurrencyField(props) {
    return React.createElement(NumberField, { ...props });
}
CurrencyField[titleTag] = 'Currency field';
CurrencyField[descriptionTag] = 'Number input with currency symbol burnt in';
CurrencyField[iconTag] = 'fa-money-bill-wave';
CurrencyField[fieldAttributesSchemaTag] = [
    {
        $type: 'Input.Text',
        id: 'label',
        label: 'Label',
    },
    {
        $type: 'Input.Number',
        id: 'defaultValue',
        label: 'Default Value',
    },
    {
        $type: 'Input.Text',
        id: 'placeholder',
        label: 'Placeholder',
    },
    {
        $type: 'Input.Choice',
        id: '$.attributes.currencyType',
        label: 'Currency',
        attributes: {
            choices: [
                {
                    symbol: '$',
                    Text: '$',
                    name: 'US Dollar',
                    symbol_native: '$',
                    code: 'USD',
                },
                {
                    symbol: 'CA$',
                    Text: 'CA$',
                    name: 'Canadian Dollar',
                    symbol_native: '$',
                    code: 'CAD',
                },
                {
                    symbol: '€',
                    Text: '€',
                    name: 'Euro',
                    symbol_native: '€',
                    code: 'EUR',
                },
                {
                    symbol: 'AED',
                    Text: 'AED',
                    name: 'United Arab Emirates Dirham',
                    symbol_native: 'د.إ.‏',
                    code: 'AED',
                },
                {
                    symbol: 'Af',
                    Text: 'Af',
                    name: 'Afghan Afghani',
                    symbol_native: '؋',
                    code: 'AFN',
                },
                {
                    symbol: 'ALL',
                    Text: 'ALL',
                    name: 'Albanian Lek',
                    symbol_native: 'Lek',
                    code: 'ALL',
                },
                {
                    symbol: 'AMD',
                    Text: 'AMD',
                    name: 'Armenian Dram',
                    symbol_native: 'դր.',
                    code: 'AMD',
                },
                {
                    symbol: 'AR$',
                    Text: 'AR$',
                    name: 'Argentine Peso',
                    symbol_native: '$',
                    code: 'ARS',
                },
                {
                    symbol: 'AU$',
                    Text: 'AU$',
                    name: 'Australian Dollar',
                    symbol_native: '$',
                    code: 'AUD',
                },
                {
                    symbol: 'man.',
                    Text: 'man.',
                    name: 'Azerbaijani Manat',
                    symbol_native: 'ман.',
                    code: 'AZN',
                },
                {
                    symbol: 'KM',
                    Text: 'KM',
                    name: 'Bosnia-Herzegovina Convertible Mark',
                    symbol_native: 'KM',
                    code: 'BAM',
                },
                {
                    symbol: 'Tk',
                    Text: 'Tk',
                    name: 'Bangladeshi Taka',
                    symbol_native: '৳',
                    code: 'BDT',
                },
                {
                    symbol: 'BGN',
                    Text: 'BGN',
                    name: 'Bulgarian Lev',
                    symbol_native: 'лв.',
                    code: 'BGN',
                },
                {
                    symbol: 'BD',
                    Text: 'BD',
                    name: 'Bahraini Dinar',
                    symbol_native: 'د.ب.‏',
                    code: 'BHD',
                },
                {
                    symbol: 'FBu',
                    Text: 'FBu',
                    name: 'Burundian Franc',
                    symbol_native: 'FBu',
                    code: 'BIF',
                },
                {
                    symbol: 'BN$',
                    Text: 'BN$',
                    name: 'Brunei Dollar',
                    symbol_native: '$',
                    code: 'BND',
                },
                {
                    symbol: 'Bs',
                    Text: 'Bs',
                    name: 'Bolivian Boliviano',
                    symbol_native: 'Bs',
                    code: 'BOB',
                },
                {
                    symbol: 'R$',
                    Text: 'R$',
                    name: 'Brazilian Real',
                    symbol_native: 'R$',
                    code: 'BRL',
                },
                {
                    symbol: 'BWP',
                    Text: 'BWP',
                    name: 'Botswanan Pula',
                    symbol_native: 'P',
                    code: 'BWP',
                },
                {
                    symbol: 'BYR',
                    Text: 'BYR',
                    name: 'Belarusian Ruble',
                    symbol_native: 'BYR',
                    code: 'BYR',
                },
                {
                    symbol: 'BZ$',
                    Text: 'BZ$',
                    name: 'Belize Dollar',
                    symbol_native: '$',
                    code: 'BZD',
                },
                {
                    symbol: 'CDF',
                    Text: 'CDF',
                    name: 'Congolese Franc',
                    symbol_native: 'FrCD',
                    code: 'CDF',
                },
                {
                    symbol: 'CHF',
                    Text: 'CHF',
                    name: 'Swiss Franc',
                    symbol_native: 'CHF',
                    code: 'CHF',
                },
                {
                    symbol: 'CL$',
                    Text: 'CL$',
                    name: 'Chilean Peso',
                    symbol_native: '$',
                    code: 'CLP',
                },
                {
                    symbol: 'CN¥',
                    Text: 'CN¥',
                    name: 'Chinese Yuan',
                    symbol_native: 'CN¥',
                    code: 'CNY',
                },
                {
                    symbol: 'CO$',
                    Text: 'CO$',
                    name: 'Colombian Peso',
                    symbol_native: '$',
                    code: 'COP',
                },
                {
                    symbol: '₡',
                    Text: '₡',
                    name: 'Costa Rican Colón',
                    symbol_native: '₡',
                    code: 'CRC',
                },
                {
                    symbol: 'CV$',
                    Text: 'CV$',
                    name: 'Cape Verdean Escudo',
                    symbol_native: 'CV$',
                    code: 'CVE',
                },
                {
                    symbol: 'Kč',
                    Text: 'Kč',
                    name: 'Czech Republic Koruna',
                    symbol_native: 'Kč',
                    code: 'CZK',
                },
                {
                    symbol: 'Fdj',
                    Text: 'Fdj',
                    name: 'Djiboutian Franc',
                    symbol_native: 'Fdj',
                    code: 'DJF',
                },
                {
                    symbol: 'Dkr',
                    Text: 'Dkr',
                    name: 'Danish Krone',
                    symbol_native: 'kr',
                    code: 'DKK',
                },
                {
                    symbol: 'RD$',
                    Text: 'RD$',
                    name: 'Dominican Peso',
                    symbol_native: 'RD$',
                    code: 'DOP',
                },
                {
                    symbol: 'DA',
                    Text: 'DA',
                    name: 'Algerian Dinar',
                    symbol_native: 'د.ج.‏',
                    code: 'DZD',
                },
                {
                    symbol: 'Ekr',
                    Text: 'Ekr',
                    name: 'Estonian Kroon',
                    symbol_native: 'kr',
                    code: 'EEK',
                },
                {
                    symbol: 'EGP',
                    Text: 'EGP',
                    name: 'Egyptian Pound',
                    symbol_native: 'ج.م.‏',
                    code: 'EGP',
                },
                {
                    symbol: 'Nfk',
                    Text: 'Nfk',
                    name: 'Eritrean Nakfa',
                    symbol_native: 'Nfk',
                    code: 'ERN',
                },
                {
                    symbol: 'Br',
                    Text: 'Br',
                    name: 'Ethiopian Birr',
                    symbol_native: 'Br',
                    code: 'ETB',
                },
                {
                    symbol: '£',
                    Text: '£',
                    name: 'British Pound Sterling',
                    symbol_native: '£',
                    code: 'GBP',
                },
                {
                    symbol: 'GEL',
                    Text: 'GEL',
                    name: 'Georgian Lari',
                    symbol_native: 'GEL',
                    code: 'GEL',
                },
                {
                    symbol: 'GH₵',
                    Text: 'GH₵',
                    name: 'Ghanaian Cedi',
                    symbol_native: 'GH₵',
                    code: 'GHS',
                },
                {
                    symbol: 'FG',
                    Text: 'FG',
                    name: 'Guinean Franc',
                    symbol_native: 'FG',
                    code: 'GNF',
                },
                {
                    symbol: 'GTQ',
                    Text: 'GTQ',
                    name: 'Guatemalan Quetzal',
                    symbol_native: 'Q',
                    code: 'GTQ',
                },
                {
                    symbol: 'HK$',
                    Text: 'HK$',
                    name: 'Hong Kong Dollar',
                    symbol_native: '$',
                    code: 'HKD',
                },
                {
                    symbol: 'HNL',
                    Text: 'HNL',
                    name: 'Honduran Lempira',
                    symbol_native: 'L',
                    code: 'HNL',
                },
                {
                    symbol: 'kn',
                    Text: 'kn',
                    name: 'Croatian Kuna',
                    symbol_native: 'kn',
                    code: 'HRK',
                },
                {
                    symbol: 'Ft',
                    Text: 'Ft',
                    name: 'Hungarian Forint',
                    symbol_native: 'Ft',
                    code: 'HUF',
                },
                {
                    symbol: 'Rp',
                    Text: 'Rp',
                    name: 'Indonesian Rupiah',
                    symbol_native: 'Rp',
                    code: 'IDR',
                },
                {
                    symbol: '₪',
                    Text: '₪',
                    name: 'Israeli New Sheqel',
                    symbol_native: '₪',
                    code: 'ILS',
                },
                {
                    symbol: 'Rs',
                    Text: 'Rs',
                    name: 'Indian Rupee',
                    symbol_native: 'টকা',
                    code: 'INR',
                },
                {
                    symbol: 'IQD',
                    Text: 'IQD',
                    name: 'Iraqi Dinar',
                    symbol_native: 'د.ع.‏',
                    code: 'IQD',
                },
                {
                    symbol: 'IRR',
                    Text: 'IRR',
                    name: 'Iranian Rial',
                    symbol_native: '﷼',
                    code: 'IRR',
                },
                {
                    symbol: 'Ikr',
                    Text: 'Ikr',
                    name: 'Icelandic Króna',
                    symbol_native: 'kr',
                    code: 'ISK',
                },
                {
                    symbol: 'J$',
                    Text: 'J$',
                    name: 'Jamaican Dollar',
                    symbol_native: '$',
                    code: 'JMD',
                },
                {
                    symbol: 'JD',
                    Text: 'JD',
                    name: 'Jordanian Dinar',
                    symbol_native: 'د.أ.‏',
                    code: 'JOD',
                },
                {
                    symbol: '¥',
                    Text: '¥',
                    name: 'Japanese Yen',
                    symbol_native: '￥',
                    code: 'JPY',
                },
                {
                    symbol: 'Ksh',
                    Text: 'Ksh',
                    name: 'Kenyan Shilling',
                    symbol_native: 'Ksh',
                    code: 'KES',
                },
                {
                    symbol: 'KHR',
                    Text: 'KHR',
                    name: 'Cambodian Riel',
                    symbol_native: '៛',
                    code: 'KHR',
                },
                {
                    symbol: 'CF',
                    Text: 'CF',
                    name: 'Comorian Franc',
                    symbol_native: 'FC',
                    code: 'KMF',
                },
                {
                    symbol: '₩',
                    Text: '₩',
                    name: 'South Korean Won',
                    symbol_native: '₩',
                    code: 'KRW',
                },
                {
                    symbol: 'KD',
                    Text: 'KD',
                    name: 'Kuwaiti Dinar',
                    symbol_native: 'د.ك.‏',
                    code: 'KWD',
                },
                {
                    symbol: 'KZT',
                    Text: 'KZT',
                    name: 'Kazakhstani Tenge',
                    symbol_native: 'тңг.',
                    code: 'KZT',
                },
                {
                    symbol: 'LB£',
                    Text: 'LB£',
                    name: 'Lebanese Pound',
                    symbol_native: 'ل.ل.‏',
                    code: 'LBP',
                },
                {
                    symbol: 'SLRs',
                    Text: 'SLRs',
                    name: 'Sri Lankan Rupee',
                    symbol_native: 'SL Re',
                    code: 'LKR',
                },
                {
                    symbol: 'Lt',
                    Text: 'Lt',
                    name: 'Lithuanian Litas',
                    symbol_native: 'Lt',
                    code: 'LTL',
                },
                {
                    symbol: 'Ls',
                    Text: 'Ls',
                    name: 'Latvian Lats',
                    symbol_native: 'Ls',
                    code: 'LVL',
                },
                {
                    symbol: 'LD',
                    Text: 'LD',
                    name: 'Libyan Dinar',
                    symbol_native: 'د.ل.‏',
                    code: 'LYD',
                },
                {
                    symbol: 'MAD',
                    Text: 'MAD',
                    name: 'Moroccan Dirham',
                    symbol_native: 'د.م.‏',
                    code: 'MAD',
                },
                {
                    symbol: 'MDL',
                    Text: 'MDL',
                    name: 'Moldovan Leu',
                    symbol_native: 'MDL',
                    code: 'MDL',
                },
                {
                    symbol: 'MGA',
                    Text: 'MGA',
                    name: 'Malagasy Ariary',
                    symbol_native: 'MGA',
                    code: 'MGA',
                },
                {
                    symbol: 'MKD',
                    Text: 'MKD',
                    name: 'Macedonian Denar',
                    symbol_native: 'MKD',
                    code: 'MKD',
                },
                {
                    symbol: 'MMK',
                    Text: 'MMK',
                    name: 'Myanma Kyat',
                    symbol_native: 'K',
                    code: 'MMK',
                },
                {
                    symbol: 'MOP$',
                    Text: 'MOP$',
                    name: 'Macanese Pataca',
                    symbol_native: 'MOP$',
                    code: 'MOP',
                },
                {
                    symbol: 'MURs',
                    Text: 'MURs',
                    name: 'Mauritian Rupee',
                    symbol_native: 'MURs',
                    code: 'MUR',
                },
                {
                    symbol: 'MX$',
                    Text: 'MX$',
                    name: 'Mexican Peso',
                    symbol_native: '$',
                    code: 'MXN',
                },
                {
                    symbol: 'RM',
                    Text: 'RM',
                    name: 'Malaysian Ringgit',
                    symbol_native: 'RM',
                    code: 'MYR',
                },
                {
                    symbol: 'MTn',
                    Text: 'MTn',
                    name: 'Mozambican Metical',
                    symbol_native: 'MTn',
                    code: 'MZN',
                },
                {
                    symbol: 'N$',
                    Text: 'N$',
                    name: 'Namibian Dollar',
                    symbol_native: 'N$',
                    code: 'NAD',
                },
                {
                    symbol: '₦',
                    Text: '₦',
                    name: 'Nigerian Naira',
                    symbol_native: '₦',
                    code: 'NGN',
                },
                {
                    symbol: 'C$',
                    Text: 'C$',
                    name: 'Nicaraguan Córdoba',
                    symbol_native: 'C$',
                    code: 'NIO',
                },
                {
                    symbol: 'Nkr',
                    Text: 'Nkr',
                    name: 'Norwegian Krone',
                    symbol_native: 'kr',
                    code: 'NOK',
                },
                {
                    symbol: 'NPRs',
                    Text: 'NPRs',
                    name: 'Nepalese Rupee',
                    symbol_native: 'नेरू',
                    code: 'NPR',
                },
                {
                    symbol: 'NZ$',
                    Text: 'NZ$',
                    name: 'New Zealand Dollar',
                    symbol_native: '$',
                    code: 'NZD',
                },
                {
                    symbol: 'OMR',
                    Text: 'OMR',
                    name: 'Omani Rial',
                    symbol_native: 'ر.ع.‏',
                    code: 'OMR',
                },
                {
                    symbol: 'B/.',
                    Text: 'B/.',
                    name: 'Panamanian Balboa',
                    symbol_native: 'B/.',
                    code: 'PAB',
                },
                {
                    symbol: 'S/.',
                    Text: 'S/.',
                    name: 'Peruvian Nuevo Sol',
                    symbol_native: 'S/.',
                    code: 'PEN',
                },
                {
                    symbol: '₱',
                    Text: '₱',
                    name: 'Philippine Peso',
                    symbol_native: '₱',
                    code: 'PHP',
                },
                {
                    symbol: 'PKRs',
                    Text: 'PKRs',
                    name: 'Pakistani Rupee',
                    symbol_native: '₨',
                    code: 'PKR',
                },
                {
                    symbol: 'zł',
                    Text: 'zł',
                    name: 'Polish Zloty',
                    symbol_native: 'zł',
                    code: 'PLN',
                },
                {
                    symbol: '₲',
                    Text: '₲',
                    name: 'Paraguayan Guarani',
                    symbol_native: '₲',
                    code: 'PYG',
                },
                {
                    symbol: 'QR',
                    Text: 'QR',
                    name: 'Qatari Rial',
                    symbol_native: 'ر.ق.‏',
                    code: 'QAR',
                },
                {
                    symbol: 'RON',
                    Text: 'RON',
                    name: 'Romanian Leu',
                    symbol_native: 'RON',
                    code: 'RON',
                },
                {
                    symbol: 'din.',
                    Text: 'din.',
                    name: 'Serbian Dinar',
                    symbol_native: 'дин.',
                    code: 'RSD',
                },
                {
                    symbol: 'RUB',
                    Text: 'RUB',
                    name: 'Russian Ruble',
                    symbol_native: 'руб.',
                    code: 'RUB',
                },
                {
                    symbol: 'RWF',
                    Text: 'RWF',
                    name: 'Rwandan Franc',
                    symbol_native: 'FR',
                    code: 'RWF',
                },
                {
                    symbol: 'SR',
                    Text: 'SR',
                    name: 'Saudi Riyal',
                    symbol_native: 'ر.س.‏',
                    code: 'SAR',
                },
                {
                    symbol: 'SDG',
                    Text: 'SDG',
                    name: 'Sudanese Pound',
                    symbol_native: 'SDG',
                    code: 'SDG',
                },
                {
                    symbol: 'Skr',
                    Text: 'Skr',
                    name: 'Swedish Krona',
                    symbol_native: 'kr',
                    code: 'SEK',
                },
                {
                    symbol: 'S$',
                    Text: 'S$',
                    name: 'Singapore Dollar',
                    symbol_native: '$',
                    code: 'SGD',
                },
                {
                    symbol: 'Ssh',
                    Text: 'Ssh',
                    name: 'Somali Shilling',
                    symbol_native: 'Ssh',
                    code: 'SOS',
                },
                {
                    symbol: 'SY£',
                    Text: 'SY£',
                    name: 'Syrian Pound',
                    symbol_native: 'ل.س.‏',
                    code: 'SYP',
                },
                {
                    symbol: '฿',
                    Text: '฿',
                    name: 'Thai Baht',
                    symbol_native: '฿',
                    code: 'THB',
                },
                {
                    symbol: 'DT',
                    Text: 'DT',
                    name: 'Tunisian Dinar',
                    symbol_native: 'د.ت.‏',
                    code: 'TND',
                },
                {
                    symbol: 'T$',
                    Text: 'T$',
                    name: 'Tongan Paʻanga',
                    symbol_native: 'T$',
                    code: 'TOP',
                },
                {
                    symbol: 'TL',
                    Text: 'TL',
                    name: 'Turkish Lira',
                    symbol_native: 'TL',
                    code: 'TRY',
                },
                {
                    symbol: 'TT$',
                    Text: 'TT$',
                    name: 'Trinidad and Tobago Dollar',
                    symbol_native: '$',
                    code: 'TTD',
                },
                {
                    symbol: 'NT$',
                    Text: 'NT$',
                    name: 'New Taiwan Dollar',
                    symbol_native: 'NT$',
                    code: 'TWD',
                },
                {
                    symbol: 'TSh',
                    Text: 'TSh',
                    name: 'Tanzanian Shilling',
                    symbol_native: 'TSh',
                    code: 'TZS',
                },
                {
                    symbol: '₴',
                    Text: '₴',
                    name: 'Ukrainian Hryvnia',
                    symbol_native: '₴',
                    code: 'UAH',
                },
                {
                    symbol: 'USh',
                    Text: 'USh',
                    name: 'Ugandan Shilling',
                    symbol_native: 'USh',
                    code: 'UGX',
                },
                {
                    symbol: '$U',
                    Text: '$U',
                    name: 'Uruguayan Peso',
                    symbol_native: '$',
                    code: 'UYU',
                },
                {
                    symbol: 'UZS',
                    Text: 'UZS',
                    name: 'Uzbekistan Som',
                    symbol_native: 'UZS',
                    code: 'UZS',
                },
                {
                    symbol: 'Bs.F.',
                    Text: 'Bs.F.',
                    name: 'Venezuelan Bolívar',
                    symbol_native: 'Bs.F.',
                    code: 'VEF',
                },
                {
                    symbol: '₫',
                    Text: '₫',
                    name: 'Vietnamese Dong',
                    symbol_native: '₫',
                    code: 'VND',
                },
                {
                    symbol: 'FCFA',
                    Text: 'FCFA',
                    name: 'CFA Franc BEAC',
                    symbol_native: 'FCFA',
                    code: 'XAF',
                },
                {
                    symbol: 'CFA',
                    Text: 'CFA',
                    name: 'CFA Franc BCEAO',
                    symbol_native: 'CFA',
                    code: 'XOF',
                },
                {
                    symbol: 'YR',
                    Text: 'YR',
                    name: 'Yemeni Rial',
                    symbol_native: 'ر.ي.‏',
                    code: 'YER',
                },
                {
                    symbol: 'R',
                    Text: 'R',
                    name: 'South African Rand',
                    symbol_native: 'R',
                    code: 'ZAR',
                },
                {
                    symbol: 'ZK',
                    Text: 'ZK',
                    name: 'Zambian Kwacha',
                    symbol_native: 'ZK',
                    code: 'ZMK',
                },
            ],
        },
    },
    {
        $type: 'Input.Boolean',
        id: 'required',
        label: 'Required',
    },
];
