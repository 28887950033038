import { FormElementInputProps } from '@rapid/adaptive-forms';
import { IQueryBuilderTable } from '@rapid/data-model/lib/query-builder';
import { useComposedClassName } from '@rapid/sdk';
import { Input, Select } from 'antd';
import { useQueryBuilderContext } from '../QueryBuilder';

export function TableAliasInput({
  disabled,
  id,
  value,
  label,
  onChange,
}: FormElementInputProps<IQueryBuilderTable>) {
  const { Option } = Select;
  const [context] = useQueryBuilderContext();

  const TableAliasClass = useComposedClassName(
    function* () {
      yield 'Label TableAliasClass';

      if (disabled) yield 'disabled';
    },
    [disabled],
  );

  const onAliasChangeInner = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (value?.table) {
      onChange?.({ ...value, alias: e.target.value });
    }
  };

  const onTableChangeInner = (table: string) => onChange?.({ ...value, table });

  return (
    <label className={TableAliasClass} id={id}>
      {!!label && <span className="Name">{label}</span>}
      <div className="flex flex-center">
        <Select
          allowClear
          showSearch
          placeholder="Select Table"
          value={value?.table}
          onChange={value => onTableChangeInner?.(value)}
          size="small"
        >
          {context.tableChoices?.map(choice => (
            <Option key={choice} value={choice}>
              {choice}
            </Option>
          ))}
        </Select>
        <p>as</p>
        <Input
          disabled={disabled}
          onChange={onAliasChangeInner}
          value={value?.alias ?? ''}
          size="small"
        />
      </div>
    </label>
  );
}
