import {
  FormElementInputProps,
  TextField,
  useAdaptiveFormContext,
} from '@rapid/adaptive-forms';
import { useComposedClassName } from '@rapid/sdk';
import { Radio, RadioChangeEvent } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { useQueryBuilderContext } from '../QueryBuilder';
import { FieldTypeInput } from './field-type-input';
import { FieldSelect } from './field-select';
import { IField } from '@rapid/data-model';
import { returnData } from '../utils/helpers';
import { allFields } from '../../../utils/allFields';
import { IQueryBuilderTable } from '@rapid/data-model/lib/query-builder';

interface IGenericFieldAttributeProps {
  fieldRefId: string;
}

export function GenericField({
  disabled,
  placeholder,
  id,
  value,
  label,
  onChange,
  fieldRefId,
}: FormElementInputProps<
  string | number | boolean | Date,
  IGenericFieldAttributeProps
>) {
  const [form, data] = useAdaptiveFormContext();
  const [context] = useQueryBuilderContext();

  const radioOptions = ['Value', 'Field'];
  const [radioValue, setRadioValue] = useState<string>();

  useEffect(
    function onRadioValueUndefined() {
      if (
        radioValue === undefined &&
        !!value &&
        !!context.fieldChoices &&
        context.fieldChoices.length > 0
      ) {
        if (
          typeof value === 'string' &&
          isColumn(value)
        ) {
          setRadioValue(radioOptions[1]);
        } else {
          setRadioValue(radioOptions[0]);
        }
      }
    },
    [value, radioValue, context.fieldChoices],
  );

  const isColumn = (str: string) =>
    str.match(
      /^[A-Z]*:~:[0-9A-F]{8}-[0-9A-F]{4}-[0-9A-F]{4}-[0-9A-F]{4}-[0-9A-F]{12}./i,
    ) !== null;

  const genericFieldClass = useComposedClassName(
    function* () {
      yield 'Label GenericField';

      if (disabled) yield 'disabled';
    },
    [disabled],
  );

  const onRadioChange = (e: RadioChangeEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setRadioValue(e.target.value);
  };

  const onChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    e.stopPropagation();
    onChange?.(e.target.value);
  };

  const field = useMemo(() => {
    if (!!fieldRefId && !!data && context.lists) {
      const strSplit = (data[fieldRefId] as string)?.split(
        '.',
      );

      if (strSplit?.length !== 2) return;

      const [tableRef, columnName] = strSplit;

      if (columnName === 'id') {
        return {
          ColumnName: 'id',
          FieldType: 'Number',
          SystemManaged: true,
          Title: 'Id',
        } as IField;
      }
      
      const tableName = (returnData(tableRef, context.data) as IQueryBuilderTable).table;
      const list = context.lists.find(l => l.Table === tableName);

      if (!!list)
        return allFields(list).find(f => f.ColumnName === columnName);
    }
  }, [fieldRefId, data, context.lists]);

  return !!field ? (
    <label className={genericFieldClass} id={id}>
      {!!label && <span className="Name">{label}</span>}
      <Radio.Group
        options={radioOptions}
        onChange={onRadioChange}
        value={radioValue}
        optionType="button"
        size="small"
      />
      {radioValue === 'Field' ? (
        <FieldSelect
          disabled={false}
          id={id}
          $type="Input.FieldSelect"
          value={value as string}
          onChange={value => onChange?.(value as string)}
          multiple={false}
        />
      ) : (
        <FieldTypeInput
          disabled={disabled}
          placeholder={placeholder}
          id={id}
          value={value as string}
          label={label}
          onChange={onChange}
          field={field}
        />
      )}
    </label>
  ) : (
    <label className={genericFieldClass} id={id}>
      {!!label && <span className="Name">{label}</span>}
      <Radio.Group
        options={radioOptions}
        onChange={onRadioChange}
        value={radioValue}
        optionType="button"
        size="small"
      />
      <TextField 
          $type="Input.Text"
          label={label}
          id={id}
          value={typeof value === 'string' ? value as string : undefined}
          disabled={disabled}
          onChange={e => {
            onChange?.(e);
          }}
        />
    </label>
  );
}
