import './commandBar.scss';
import React from 'react';

export function CommandBar(props: React.PropsWithChildren<{}>) {
  return (
    <div className="commandBar">
      {props.children}
    </div>
  );
}

export function CommandBarLeft(props: React.PropsWithChildren<{}>) {
  return <div className="left-section">{props.children}</div>;
}

export function CommandBarCenter(props: React.PropsWithChildren<{}>) {
  return <div className="main-section">{props.children}</div>;
}

export function CommandBarRight(props: React.PropsWithChildren<{}>) {
  return <div className="right-section">{props.children}</div>;
}
