import { useCallback } from 'react';
import { throttle } from './utils/throttle';
/**
 * Accepts a function and returns a new throttled yet memoized version of that same function that waits the defined time
 * before allowing the next execution.
 * If time is not defined, its default value will be 100ms.
 */
export const useThrottledFn = (fn, wait = 100, dependencies) => {
    const throttled = throttle(fn, wait);
    return useCallback(throttled, dependencies);
};
