import { AnyListItem } from '@rapid/data-model';
import { IQueryBuilderSQL } from '@rapid/data-model/lib/query-builder';
import { useCurrentSiteEndpoint, useViewContext } from '@rapid/sdk';
import { ReactNode, useState } from 'react';
import {
  CommandBar,
  CommandBarCenter,
  CommandBarLeft,
  CommandBarRight,
} from '../../components/commandbar/commandBar';
import { ManagedViewContextTable } from '../../components/dataTable/ManagedViewContextTable';
import { Breadcrumbs } from '../../sdk/breadcrumbs/breadcrumbs';
import { useBreadcrumbText } from '../../utils/breadcrumbText';
import { feedbackWrapper } from '../../utils/feedbackWrapper';
import { useNavigation } from '../../utils/Navigation';
import { AdaptiveViewForm } from './forms/addAdaptiveViewForm';

export interface IAdaptiveView extends AnyListItem {
  title: string;
  slug: string;
  description: string;
  view_name: string;
  schema: IQueryBuilderSQL;
}

export function AdaptiveViewsList() {
  const { Navigate } = useNavigation();
  const [context] = useViewContext();
  const ep = useCurrentSiteEndpoint();
  const [addCustomViewVisible, setAddCustomViewVisible] =
    useState<boolean>(false);

  async function postAdaptiveView(item: IAdaptiveView) {
    await feedbackWrapper(
      () => ep.Lists['Adaptive Views'][''].Items.postJson({}, [item]).then(() => context.fetchItems()),
      `Adding ${item.title}...`,
      `${item.title} added!`,
      `Unable to add ${item.title}.`,
    );
  }

  async function createAdaptiveView(item: IAdaptiveView) {
    await feedbackWrapper(
      () =>
        ep.Views[item.view_name]
          .postJson({}, item.schema),
      `Creating ${item.title}...`,
      `${item.title} view created!`,
      `Unable to create ${item.title}.`,
    );
  }

  function customTitleRender(): ReactNode {
    return (text: string, record: any) => {
      return (
        <a onClick={() => Navigate(`/adaptiveview:${record.id}`)} target="_blank">
          {text}
        </a>
      );
    };
  }

  const onSubmit = async (item: IAdaptiveView) => {
      setAddCustomViewVisible(false);
      await postAdaptiveView(item);
      await createAdaptiveView(item);
  }

  return (
    <>
      <CommandBar>
        <CommandBarLeft>
          <Breadcrumbs breadcrumbText={useBreadcrumbText()} />
        </CommandBarLeft>
        <CommandBarCenter></CommandBarCenter>
        <CommandBarRight>
          <button
            title="Add Dashboard"
            className="button default margin-horizontal-small"
            onClick={() => setAddCustomViewVisible(true)}
          >
            <i className="fal fa-plus" />
          </button>
        </CommandBarRight>
      </CommandBar>
      <div className="content">
          <ManagedViewContextTable 
            customTitleRender={customTitleRender}
          />
      </div>
      <AdaptiveViewForm
        visible={addCustomViewVisible}
        onSubmit={onSubmit}
        onCancel={() => setAddCustomViewVisible(false)}
      />
    </>
  );
}
