import React from 'react';
import { useComposedClassName } from '../use-composed-class-names';
/**
 * An icon component containing all RAPID & Fontawesome icons.
 *
 *  List of available props:
 * - **iconName** | All Fontawesome & RAPID icons [***defaults to RAPID logo***]
 * - **iconStyle** | All Fontawesome styles (_does not effect RAPID icons_) [***defaults to regular***]
 * - **iconSize** | A range of sizes from 1x to 10x
 * - **iconRotation** | Static rotation from 90 - 270deg
 * - **iconFlip** | Static flip Horizontal or Vertaical or Both
 * - **iconAnimation** | Simple animation styles. Great for making quick spinners
 * - **fixedWidth** | Set's all icons to fixed width. Great for vertically aligning icons in menus.
 *
 * [Fontawesome Documentation](https://fontawesome.com/how-to-use/on-the-web/referencing-icons/basic-use "The complete fontawesome documentation. For those of you who are interested in how all of this works.....")
 */
export function Icon({ className, iconName, iconStyle, iconSize, iconRotation, iconFlip, iconAnimation, fixedWidth, }) {
    /**
     * Setting the icon style
     */
    let style;
    switch (iconStyle) {
        case 'duotone':
            style = 'fad';
            break;
        case 'solid':
            style = 'fas';
            break;
        case 'light':
            style = 'fal';
            break;
        case 'brands':
            style = 'fab';
            break;
        default:
            style = 'far';
            break;
    }
    const generatedClassName = useComposedClassName(function* () {
        yield className;
        yield style;
        yield !!iconName ? iconName : 'ri-rapid';
        if (fixedWidth) {
            yield 'fa-fw';
        }
        if (iconRotation) {
            yield `fa-rotate-${iconRotation}`;
        }
        if (iconFlip) {
            yield `fa-flip-${iconFlip}`;
        }
        if (iconSize) {
            yield `fa-${iconSize}`;
        }
        if (iconAnimation) {
            yield `fa-${iconAnimation}`;
        }
    }, [className, iconName, style, iconRotation, iconFlip, iconAnimation]);
    // const iconClassName = `${style} ${!!iconName ? iconName : "ri-rapid"} ${
    //   !!iconSize ? `fa-${iconSize}` : ""
    // } ${fixedWidth ? "fa-fw" : ""} ${
    //   !!iconRotation ? `fa-rotate-${iconRotation}` : ""
    // } ${!!iconFlip ? `fa-flip-${iconFlip}` : ""} ${
    //   !!iconAnimation ? `fa-${iconAnimation}` : ""
    // }`;
    return React.createElement("i", { className: generatedClassName });
}
