import { IQueryBuilderJoin, IQueryBuilderOrderBy, IQueryBuilderSQL, IQueryBuilderTable } from '@rapid/data-model/lib/query-builder';
import { ASTParser } from '@rapid/odata';
import { ODataNode } from '@rapid/odata-parser';
import { RuleGroupType, RuleType } from 'react-querybuilder';
import { getColumn, getFormElementId, isColumn } from './helpers';

export class SQLQueryConverter {
  protected data: Record<string, any>;
  protected name?: string;
  protected select: string[];
  protected from: IQueryBuilderTable;
  protected joins?: IQueryBuilderJoin[];
  protected where?: ODataNode;
  protected orderBy?: IQueryBuilderOrderBy;

  constructor(data: Record<string, any>, id: string = 'root') {
    this.data = data;
    this.select = [];
    this.from = {
      table: '',
    };

    for (const key of Object.keys(data[id])) {
      switch (getFormElementId(key)?.type) {
        case 'Select':
          this.convertSelect(data[id][key] as string[]);
          break;
        case 'Name':
          this.name = data[id][key] as string;
          break;
        case 'From':
          this.from = data[id][key] as IQueryBuilderTable;
          break;
        case 'OrderBy':
          this.convertOrderBy(data[id][key] as IQueryBuilderOrderBy);
          break;
        case 'JoinsGroup':
          this.convertToSQLJoinsQuery(data[id][key]);
          break;
        case 'QueryGroup':
          this.where = this.ruleGroupToAST(
            this.convertToRuleGroup(key, data[id][key]),
          );
          break;
      }
    }
  }

  getQuery(): IQueryBuilderSQL {
    return {
      data: this.data,
      name: this.name,
      select: this.select,
      from: this.from,
      joins: this.joins,
      where: this.where,
      orderBy: this.orderBy,
    };
  }

  protected convertSelect = (select: string[]) =>
    (this.select = select.map(s => getColumn(s, this.data)));

  protected convertOrderBy = (orderBy: IQueryBuilderOrderBy) =>
    (this.orderBy = {
      ...orderBy,
      column: getColumn(orderBy.column, this.data),
    });

  protected convertToRule(id: string, data: Record<string, any>): RuleType {
    const rule: RuleType = {
      id: id,
      field: '',
      operator: '',
      value: '',
    };

    for (const key of Object.keys(data)) {
      switch (getFormElementId(key)?.type) {
        case 'Operator':
          rule.operator = data[key] as string;
          break;
        case 'lValue':
          const lValue = data[key] as string;
          rule.field = isColumn(lValue) ? getColumn(lValue, this.data) : lValue;
          break;
        case 'rValue':
          const rValue = data[key];
          rule.value =
            typeof rValue == 'string' && isColumn(rValue)
              ? getColumn(rValue, this.data)
              : rValue;
          break;
      }
    }

    return rule;
  }

  protected convertToRuleGroup(
    id: string,
    data: Record<string, any>,
  ): RuleGroupType {
    const query: RuleGroupType = {
      id: id,
      combinator: 'and',
      rules: [],
      not: false,
    };

    for (const key of Object.keys(data)) {
      switch (getFormElementId(key)?.type) {
        case 'RadioAndOr':
          query.combinator = data[key] as string;
          break;
        case 'QueryRule':
          query.rules.push(this.convertToRule(key, data[key]));
          break;
        case 'QueryGroup':
          query.rules.push(this.convertToRuleGroup(key, data[key]));
          break;
      }
    }

    return query;
  }

  protected convertToSQLJoinsQuery(data: Record<string, any>) {
    this.joins = [];

    for (const key of Object.keys(data)) {
      const elementId = getFormElementId(key);
      if (elementId?.type === 'JoinRule') {
        const join: IQueryBuilderJoin = {
          ...(data[key][`Join:~:${elementId.uuid}`] as IQueryBuilderTable),
        };
        if (data[key].hasOwnProperty(`QueryGroup:~:${elementId.uuid}`)) {
          join.rules = this.ruleGroupToAST(
            this.convertToRuleGroup(`QueryGroup:~:${elementId.uuid}`, data[key][`QueryGroup:~:${elementId.uuid}`]),
          );
        }
        this.joins.push(join);
      }
    }
  }

  protected ruleGroupToAST(ruleGroup: RuleGroupType): ODataNode|undefined {
    return new ASTParser().convertToAst(ruleGroup) ?? undefined;
  }
}
