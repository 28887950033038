import produce from 'immer';
import { useCallback, useState } from 'react';
/**
 * `useState` that supports immer producers.
 */
export function useImmer(initialValue) {
    const [val, updateValue] = useState(initialValue);
    return [
        val,
        useCallback(updater => {
            let nextState;
            if (typeof updater === 'function')
                nextState = produce(updater);
            else
                nextState = updater;
            updateValue(nextState);
            return nextState;
        }, []),
    ];
}
