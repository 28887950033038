import { FormElementInputProps } from '@rapid/adaptive-forms';
import { useComposedClassName } from '@rapid/sdk';
import { Select } from 'antd';
import { useQueryBuilderContext } from '../QueryBuilder';

export function TableSelect({
  disabled,
  id,
  value,
  label,
  onChange,
}: FormElementInputProps<string>) {
  const { Option } = Select;

  const [context] = useQueryBuilderContext();

  const tableSelectClass = useComposedClassName(
    function* () {
      yield 'Label TableSelect';

      if (disabled) yield 'disabled';
    },
    [disabled],
  );

  return (
    <label className={tableSelectClass} id={id}>
      {!!label && <span className="Name">{label}</span>}
      <Select
        allowClear
        showSearch
        placeholder="Select Table"
        value={value}
        onChange={value => onChange?.(value)}
        size="small"
      >
        {context.tableChoices?.map(choice => (
          <Option key={choice} value={choice}>
            {choice}
          </Option>
        ))}
      </Select>
    </label>
  );
}
