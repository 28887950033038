import { AnyListItem } from '@rapid/data-model';
import { useCurrentSiteEndpoint, useViewContext } from '@rapid/sdk';
import { Card, Popconfirm } from 'antd';
import { useState } from 'react';
import AddDashboardForm from './dashboardsLists.AddDashboardForm';
import { CommandBar, CommandBarCenter, CommandBarLeft, CommandBarRight } from '../../components/commandbar/commandBar';
import { Breadcrumbs } from '../../sdk/breadcrumbs/breadcrumbs';
import { useBreadcrumbText } from '../../utils/breadcrumbText';
import {feedbackWrapper} from '../../utils/feedbackWrapper';
import { useNavigation } from '../../utils/Navigation';

import './dashboardsList.scss';
import { IDashboardSchema } from '../dashboard/dashboard';

export interface IDashboard extends AnyListItem {
  title: string;
  slug: string;
  description: string;
  schema?: IDashboardSchema;
}

export function DashboardsList() {
  const ep = useCurrentSiteEndpoint();
  const [view] = useViewContext();
  const { Navigate } = useNavigation();
  const [addDashboardVisible, setAddDashboardVisible] = useState<boolean>(false);

  function deleteDashboard(item: IDashboard) {
    feedbackWrapper(
      () => view.deleteItems([item.id!]),
      `Deleting ${item.title}...`,
      `${item.title} deleted!`,
      `Unable to delete ${item.title}.`,
    );
  }

  async function postDashboard(item: IDashboard) {
    feedbackWrapper(
      () => ep.Lists['Adaptive Dashboards'].Items.postJson({},[
        item
      ]).then(() => view.fetchItems()),
      `Adding ${item.title}...`,
      `${item.title} added!`,
      `Unable to add ${item.title}.`,
    );
  }

  function onSubmit(item: IDashboard) {
    postDashboard(item);
    setAddDashboardVisible(false);
  }

  return (
    <>
      <CommandBar>
        <CommandBarLeft>
          <Breadcrumbs breadcrumbText={useBreadcrumbText()} />
        </CommandBarLeft>
        <CommandBarCenter></CommandBarCenter>
        <CommandBarRight>
          <button
            title="Add Dashboard"
            className="button default margin-horizontal-small"
            onClick={() => setAddDashboardVisible(true)}
          >
            <i className="fal fa-plus" />
          </button>
        </CommandBarRight>
      </CommandBar>
      <div className="content card-list">
        {(Array.from(view.items) as IDashboard[]).map(item => (
          <Card
          key={item.title}
          cover={<i className="fal fa-tachometer fa-7x" />}
          hoverable
          extra={
            <Popconfirm
              title={`Delete ${item.title}`}
              onConfirm={(e) => {e?.stopPropagation(); deleteDashboard(item)}}
              onCancel={(e) => e?.stopPropagation()}
              okText="Delete"
              okButtonProps={{ danger: true }}
              cancelText="No"
            >
              <button
                title="Delete"
                className="delete-button"
                onClick={(ev) => ev.stopPropagation()}
              >
                <i className="fal fa-trash-alt" />
              </button>
            </Popconfirm>
          }
          onClick={ev => {
            ev.stopPropagation();
            Navigate(`/dashboard:${item.id}`);
          }}
        >
          <Card.Meta title={item.title} description={item.description} />
        </Card>
        ))}
      </div>
      <AddDashboardForm 
        visible={addDashboardVisible}
        onSubmit={onSubmit}
        onCancel={() => setAddDashboardVisible(false)}
      />
    </>
  );
}
