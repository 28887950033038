import { IEnvTenantSiteParams } from '@rapid/sdk';
import { Breadcrumb } from 'antd';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';

import './breadcrumbs.scss';

interface IBreadCrumbItem {
  text?: string;
  url?: string;
}

export interface IBreadcrumbsProps {
  breadcrumbText?(path: string): string|undefined;
}

export function Breadcrumbs(props: React.PropsWithRef<IBreadcrumbsProps>) {
  const history = useHistory();
  const breadcrumbText = props.breadcrumbText;
  const params = useParams<IEnvTenantSiteParams>();
  const baseUrl: string = `/_${params.env}/${params.tenant}/${params.site}`;
  const paths = history.location.pathname.split('/');

  const breadcrumbs: IBreadCrumbItem[] = [
    {
      text: 'Rapid Platform',
      url: '/',
    },
  ];

  paths.splice(0, 4); // removes "", "_env", "tenant", "site" from breadcrumbs
  for (let i = 0; i<paths.length; i++) {
    breadcrumbs.push({
      text: breadcrumbText ? breadcrumbText(paths[i]) : paths[i],
      url: (paths[i].length - 1) === i ? undefined : (i === 0 ? breadcrumbs[i].url + paths[i] : breadcrumbs[i].url + '/' + paths[i])
    });
  }

  function BaseNavigate(path: string) {
    history.push(baseUrl + path);
  }

  return (
    <>
      <button
        title="Go Back"
        className="button default margin-right-small"
        onClick={() => history.goBack()}
      >
        <i className="fal fa-arrow-to-left" />
      </button>
      <Breadcrumb className="breadcrumbs">
        {breadcrumbs.filter(breadcrumb => !!breadcrumb.text).map((breadcrumb, i) =>
          !!breadcrumb.url ? (
            <Breadcrumb.Item key={i} onClick={() => BaseNavigate(breadcrumb.url!)}>
              <span title={breadcrumb.text}>{breadcrumb.text}</span>
            </Breadcrumb.Item>
          ) : (
            <Breadcrumb.Item key={i}>
              <span title={breadcrumb.text}>{breadcrumb.text}</span>
            </Breadcrumb.Item>
          ),
        )}
      </Breadcrumb>
    </>
  );
}
