import React from 'react';
import { Divider } from 'antd';
import { descriptionTag, fieldAttributesSchemaTag, iconTag, titleTag, } from '../symbols';
export function LayoutDivider({ label, type, orientation, dashed, plain, }) {
    return (React.createElement(Divider, { type: type, orientation: orientation, dashed: dashed, plain: plain }, label));
}
LayoutDivider[titleTag] = 'Divider';
LayoutDivider[descriptionTag] = 'Simple divider to draw lines between things';
LayoutDivider[iconTag] = 'fa-horizontal-rule';
// LayoutDivider[configComponentTag] = DesignerConfig;
LayoutDivider[fieldAttributesSchemaTag] = [
    {
        $type: 'Input.Text',
        id: 'label',
        label: 'Label',
    },
    {
        $type: 'Input.Choice',
        id: '$.attributes.orientation',
        label: 'Label orientation',
        defaultValue: 'center',
        attributes: {
            choices: [{ Text: 'left' }, { Text: 'right' }, { Text: 'center' }],
        },
    },
    {
        $type: 'Input.Choice',
        id: '$.attributes.type',
        label: 'Layout direction',
        defaultValue: 'vertical',
        attributes: {
            choices: [{ Text: 'horizontal' }, { Text: 'vertical' }],
        },
    },
    {
        $type: 'Input.Boolean',
        defaultValue: false,
        id: '$.attributes.dashed',
        label: 'Dashed',
    },
    {
        $type: 'Input.Boolean',
        defaultValue: false,
        id: '$.attributes.plain',
        label: 'Plain text',
    },
];
