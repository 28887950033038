import React, { useCallback, useMemo } from 'react';
import * as Controls from './index';
const adaptiveFormContext = React.createContext(null);
const FormProvider = adaptiveFormContext.Provider;
adaptiveFormContext.displayName = 'Adaptive Form Context';
export function AdaptiveFormContext({ formSchema, data, onChange, components, children, }) {
    const componentLibrary = useMemo(() => ({
        'Input.Text': Controls.TextField,
        'Input.Number': Controls.NumberField,
        'Input.WholeNumber': Controls.NumberField,
        'Input.Percentage': Controls.PercentageField,
        'Input.Currency': Controls.CurrencyField,
        'Input.Choice': Controls.ChoiceField,
        'Input.MultiLookup': Controls.MultiLookupField,
        'Input.Lookup': Controls.LookupField,
        'Input.User': Controls.UserField,
        'Input.DateTime': Controls.DateTimeField,
        'Input.Boolean': Controls.BooleanField,
        'Input.Email': Controls.EmailField,
        'Input.Computed': Controls.ComputedField,
        'Input.Subquery': Controls.ComputedField,
        'Input.Radio': Controls.RadioField,
        'Input.Rating': Controls.StarRating,
        'Layout.Heading': Controls.HeadingLayout,
        'Layout.Section': Controls.Section,
        'Layout.Divider': Controls.LayoutDivider,
        'Layout.Spacer': Controls.LayoutSpacer,
        'Layout.Likert': Controls.LikertScale,
        AdaptiveForm: Controls.AdaptiveForm,
        ...components,
    }), [components]);
    return (React.createElement(FormProvider, { value: [{ formSchema, onChange, components: componentLibrary }, data] }, children));
}
/**
 * Returns the current Adaptive form context
 *
 * @hook
 * @category RAPID Adaptive Forms
 */
export function useAdaptiveFormContext() {
    const [form, data] = React.useContext(adaptiveFormContext);
    return [form, data];
}
/**
 * Returns the current Adaptive formSchema and it's updater
 *
 * @hook
 * @category RAPID Adaptive Forms
 */
export function useAdaptiveFormElement() {
    const [formContext] = useAdaptiveFormContext();
    return [formContext.formSchema];
}
export function removeField(fieldRef, formElement) {
    if (formElement.id === fieldRef) {
        return;
    }
    if (!!formElement.$children) {
        const ind = formElement.$children.findIndex(i => i.id === fieldRef);
        if (ind !== -1) {
            formElement.$children.splice(ind, 1);
        }
        else {
            for (let child of formElement.$children) {
                removeField(fieldRef, child);
            }
        }
    }
}
export function returnField(fieldRef, formElement) {
    var _a;
    if ((formElement === null || formElement === void 0 ? void 0 : formElement.id) === fieldRef) {
        return formElement;
    }
    if (((_a = formElement.attributes) === null || _a === void 0 ? void 0 : _a.columnName) === fieldRef) {
        return formElement;
    }
    if (!!formElement.$children) {
        for (let child of formElement.$children) {
            const field = returnField(fieldRef, child);
            if (!!field) {
                return field;
            }
        }
    }
}
export function useAdaptiveData() {
    const [context, data] = useAdaptiveFormContext();
    return data;
}
/**
 * Returns the specified feildSchema, onChange handler, and Component
 *
 * @hookn
 * @category RAPID Adaptive Forms
 */
export function useAdaptiveField(elementRef) {
    const [formContext, data] = useAdaptiveFormContext();
    const formElement = useMemo(function findFeildTypeOnMount() {
        const field = returnField(elementRef, formContext.formSchema);
        return field;
    }, [elementRef, formContext.formSchema]);
    const onChange = useCallback(function onChange(value) {
        formContext.onChange(formElement, value);
    }, [formContext.onChange, formElement]);
    const onBlur = () => { };
    // const key = formElement.attributes?.columnName ?? formElement.id;
    const key = formElement.id;
    return [
        formElement,
        data === null || data === void 0 ? void 0 : data[key],
        { onChange, onBlur },
        formContext.components[formElement === null || formElement === void 0 ? void 0 : formElement['$type']],
        data === null || data === void 0 ? void 0 : data[key.replace(/_id$/, '')],
    ];
}
