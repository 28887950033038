import React from 'react';
import { useLayoutEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { MultiSiteApplication, SingleSiteApplication, } from './rapid-application';
/**
 * A {@link MultiSiteApplication} with a bundled browser router and react strictmode
 * wrappers.
 *
 * @Component
 * @category RAPID Application
 */
export function ManagedMultiSiteApplication(props) {
    useLayoutEffect(function displayNameChangedEffect() {
        if (typeof props.displayName === 'string') {
            document.title = props.displayName;
        }
    }, [props.displayName]);
    return (React.createElement(React.StrictMode, null,
        React.createElement(BrowserRouter, null,
            React.createElement(MultiSiteApplication, Object.assign({}, props)))));
}
export function ManagedSingleSiteApplication(props) {
    useLayoutEffect(function displayNameChangedEffect() {
        if (typeof props.displayName === 'string') {
            document.title = props.displayName;
        }
    }, [props.displayName]);
    return (React.createElement(React.StrictMode, null,
        React.createElement(BrowserRouter, null,
            React.createElement(SingleSiteApplication, Object.assign({}, props)))));
}
