import React, { useEffect, useState } from "react";

export interface IEditPreviewProps {
  startInEdit?: boolean;
  onEditClicked(): void;
  onPreviewClicked(): void;
}

export function EditPreview(props: React.PropsWithRef<IEditPreviewProps>) {
  const [editSelected, setEditSelected] = useState<Boolean>(false);

  useEffect(function startInEdit() {
    if (props.startInEdit) {
      setEditSelected(true);
    }
  }, [props.startInEdit]);

  function editClicked() {
    setEditSelected(true);
    props.onEditClicked();
  }

  function previewClicked() {
    setEditSelected(false);
    props.onPreviewClicked();
  }

  return (
    <>
      <button
        title="Edit"
        className={editSelected ? 'button selected' : 'button default'}
        onClick={editClicked}
      >
        <i className="fal fa-pencil-alt" />
      </button>
      <button
        title="Preview"
        className={!editSelected ? 'button selected' : 'button default'}
        onClick={previewClicked}
      >
        <i className="fal fa-eye" />
      </button>
    </>
  );
}
