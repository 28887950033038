import { ManagedViewContext, useComposedClassName, useCurrentSite, } from '@rapid/sdk';
import inflection from 'inflection';
import { useState } from 'react';
import { descriptionTag, fieldAttributesSchemaTag, iconTag, rapidAttributesSchemaTag, titleTag, } from '../../symbols';
import React from 'react';
import { LookupSelect } from './lookup-select';
import { FilteredSelectDropdown } from './filtered-select-dropdown';
export function MultiLookupField({ columnName, lookupList, lookupField, label, id, disabled, onChange, $type, value, }) {
    var _a, _b, _c, _d;
    const [site] = useCurrentSite();
    const [dataSource, setDataSource] = useState('All');
    const [searchTerm, setSearchTerm] = useState('');
    const multiLookupFieldClass = useComposedClassName(function* () {
        yield 'Email Label flex column';
        if (disabled)
            yield 'disabled';
    }, [disabled]);
    const valueKey = useComposedClassName(function* () {
        if ($type === 'Input.User') {
            yield 'principal_id';
            return;
        }
        yield 'id';
    }, [lookupField]);
    const filters = (_d = (_c = (_a = site === null || site === void 0 ? void 0 : site.DataSources) === null || _a === void 0 ? void 0 : _a[inflection.singularize(inflection.dasherize((_b = lookupList === null || lookupList === void 0 ? void 0 : lookupList.toLocaleLowerCase()) !== null && _b !== void 0 ? _b : ''))]) === null || _c === void 0 ? void 0 : _c.map(d => d.Title)) !== null && _d !== void 0 ? _d : [];
    const onChangeInner = (option) => {
        let newValue = [...(value !== null && value !== void 0 ? value : [])];
        const ind = newValue.findIndex(i => i.value === option.value);
        if (ind === -1) {
            newValue.push(option);
        }
        else {
            newValue.splice(ind, 1);
        }
        onChange === null || onChange === void 0 ? void 0 : onChange(newValue);
    };
    return (React.createElement("label", { className: multiLookupFieldClass, id: id },
        !!label && React.createElement("span", { className: "Name" }, label),
        React.createElement(ManagedViewContext, { list: lookupList !== null && lookupList !== void 0 ? lookupList : '', dataSource: dataSource, searchTerm: searchTerm },
            React.createElement(LookupSelect, { id: id, value: value, dropdownRender: menu => (React.createElement(FilteredSelectDropdown, { menu: menu, setFilter: setDataSource, filter: dataSource, filters: filters, filtersTitle: "Views" })), onChange: onChangeInner, labelKey: lookupField !== null && lookupField !== void 0 ? lookupField : 'title', valueKey: valueKey, setSearchTerm: setSearchTerm, searchTerm: searchTerm, setFilter: setDataSource, mode: "multiple", columnName: columnName }))));
}
MultiLookupField[titleTag] = 'Multilookup';
MultiLookupField[descriptionTag] = 'RAPID Platform multilookup field';
MultiLookupField[iconTag] = 'fa-search-plus';
MultiLookupField[fieldAttributesSchemaTag] = [
    {
        $type: 'Input.Text',
        id: 'label',
        label: 'Label',
    },
    {
        $type: 'Input.Text',
        id: 'placeholder',
        label: 'Placeholder',
    },
    {
        $type: 'Input.Boolean',
        id: 'required',
        label: 'Required',
    },
];
MultiLookupField[rapidAttributesSchemaTag] = [
    {
        $type: 'Input.List',
        id: '$.attributes.lookupList',
        label: 'Lookup List',
    },
    {
        $type: 'Input.Text',
        id: '$.attributes.lookupField',
        label: 'Lookup Field',
    },
    {
        $type: 'Input.Text',
        id: '$.attributes.columnName',
        label: 'RAPID Column Name',
    },
];
