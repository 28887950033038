export const percentTag = Symbol.for('percent');
export const blobUrlTag = Symbol.for('blob-url');
export const isUploadingTag = Symbol.for('is-uploading');
export const nameTag = Symbol.for('name');
export const blobTag = Symbol.for('blob');
export const iconTag = Symbol.for('icon');
export const driveItemTag = Symbol.for('graph-drive-item');
export const webUrlTag = Symbol.for('graph-web-url');
export const downloadUrlTag = Symbol.for('graph-download-url');
export const loadingTag = Symbol.for('loading');
