import { EventEmitter } from 'events';
const CHUNK_SIZE = 163840 * 2 * 10;
export class UploadSession extends EventEmitter {
    constructor(client, driveId, driveItemId, filePath, data) {
        super();
        this._fetch = client;
        this.driveId = driveId;
        this.driveItemId = driveItemId;
        this.filePath = filePath;
        this.data = data;
        this.currentChunk = 0;
    }
    get size() {
        return this.data.size;
    }
    get chunks() {
        return Math.ceil(this.data.size / CHUNK_SIZE);
    }
    get chunkData() {
        return this.data.slice(this.currentChunk * CHUNK_SIZE, this.currentChunk * CHUNK_SIZE + CHUNK_SIZE);
    }
    get percent() {
        return Math.floor(((this.currentChunk + 1) / this.chunks) * 100);
    }
    get contentRange() {
        const start = this.currentChunk * CHUNK_SIZE;
        const end = start + this.chunkData.size;
        return `bytes ${start}-${end - 1}/${this.size}`;
    }
    async sendChunk() {
        if (!this.session) {
            this.session = await this._createUploadSession();
            this.emit('start', this.session);
        }
        try {
            await this._uploadChunk();
        }
        catch (e) {
            this.emit('error', e);
        }
    }
    async sendAllChunks() {
        while (this.currentChunk < this.chunks) {
            await this.sendChunk();
        }
        this.emit('end', this.driveItem);
        return this.driveItem;
    }
    async _uploadChunk() {
        var _a;
        if (!((_a = this.session) === null || _a === void 0 ? void 0 : _a.uploadUrl)) {
            throw new Error('UploadChunk called with no session or upload URL');
        }
        if (this.currentChunk >= this.chunks) {
            return;
        }
        const resp = await this._fetch(this.session.uploadUrl, {
            method: 'PUT',
            body: this.chunkData,
            headers: {
                'Content-Range': this.contentRange,
            },
        });
        if (!resp.ok) {
            throw new Error(`Uploading chunk ${this.currentChunk} failed: ${resp.status}`);
        }
        if (resp.status === 200 || resp.status === 201) {
            const driveItem = await resp.json();
            this.percent === 100;
            this.emit('progress', this.percent);
            this.driveItem = driveItem;
        }
        this.currentChunk++;
        this.emit('progress', this.percent);
    }
    async cancel() {
        var _a;
        if (!((_a = this.session) === null || _a === void 0 ? void 0 : _a.uploadUrl)) {
            throw new Error('cancel called with no session or upload url');
        }
        const resp = await this._fetch(this.session.uploadUrl, {
            method: 'DELETE',
        });
        if (!resp.ok) {
            const errorText = await resp.text();
            throw new Error(`Deleting upload session failed: ${resp.status}: ${errorText}`);
        }
        this.session = void 0;
    }
    async _createUploadSession() {
        var _a, _b, _c;
        const filePath = this.filePath.replace(/^\/+/g, '');
        const fileExt = (_c = (_b = ((_a = this.data.name) !== null && _a !== void 0 ? _a : 'unknown.jpeg').match(/(?<name>.*)\.(?<ext>.*)/)) === null || _b === void 0 ? void 0 : _b.groups) === null || _c === void 0 ? void 0 : _c.ext;
        let fullPath = filePath;
        if (!!fileExt) {
            fullPath = filePath + '.' + fileExt;
        }
        const response = await this._fetch(`https://graph.microsoft.com/v1.0/drives/${this.driveId}/items/${this.driveItemId}:/${fullPath}:/createUploadSession`, {
            method: 'POST',
            body: JSON.stringify({
                item: {
                    '@microsoft.graph.conflictBehavior': 'replace',
                },
            }),
        });
        if (!response.ok) {
            const errorText = await response.text();
            const error = new Error(`Cannot upload ${this.filePath}: createUploadSession response did not indicate success: ${response.status}: ${errorText}`);
            this.emit('error', error);
            throw error;
        }
        const session = await response.json();
        return session;
    }
}
