import React, { createContext, useContext, useEffect } from 'react';
import { useImmer } from '../use-immer';
const embeddedFilesContext = createContext({});
function parseEmbeddedFiles(input) {
    var _a;
    if (!input) {
        return [];
    }
    if (Array.isArray(input)) {
        return input;
    }
    try {
        return (_a = JSON.parse(input)) !== null && _a !== void 0 ? _a : [];
    }
    catch {
        return [];
    }
}
/**
 * Creates a React context that automatically parses and handles access to
 * a task's embedded files. Provides access to the {@link useEmbeddedFiles} hooks
 *
 * @component
 * @category RAPID Application
 */
export function EmbeddedFilesContext({ children, item, onEmbeddedFilesChanged, }) {
    const [embeddedFiles, updateEmbeddedFiles] = useImmer(parseEmbeddedFiles(item.embedded_files));
    useEffect(function embeddedFilesChangedEffect() {
        if (item.embedded_files) {
            updateEmbeddedFiles(() => parseEmbeddedFiles(item.embedded_files));
        }
    }, [item.embedded_files]);
    useEffect(function localEmbeddedFilesChangedEffect() {
        onEmbeddedFilesChanged === null || onEmbeddedFilesChanged === void 0 ? void 0 : onEmbeddedFilesChanged(item, JSON.stringify(embeddedFiles));
    }, [embeddedFiles]);
    return (React.createElement(embeddedFilesContext.Provider, { value: {
            embeddedFiles,
            updateEmbeddedFiles,
            item,
        } }, children));
}
/**
 * Hook that uses the embedded files array from the nearest {@link EmbeddedFilesContext}.
 *
 * @hook
 * @category RAPID Application
 */
export function useEmbeddedFiles() {
    const { embeddedFiles, updateEmbeddedFiles } = useContext(embeddedFilesContext);
    return [embeddedFiles, updateEmbeddedFiles];
}
