import { IFormElement } from '@rapid/adaptive-forms';
import { v4 as uuidv4 } from 'uuid';
import { QueryGroup } from './base-layout';

export function JoinGroup(uuid: string = uuidv4()): IFormElement {
  return {
    $type: 'AdaptiveForm',
    id: `JoinsGroup:~:${uuid}`,
    $children: [
      {
        $type: 'Layout.Section',
        id: `JoinsGroupCommandBar:~:${uuid}`,
        label: 'JoinsGroupCommandBar',
        attributes: {
          direction: 'horizontal',
        },
        $children: [
          {
            $type: 'Form.AddJoin' as any,
            id: `AddJoin:~:${uuid}`,
            attributes: {
              parentId: `JoinsGroup:~:${uuid}`,
            },
          },
        ],
      },
    ],
  } as IFormElement;
}

export function JoinRule(uuid: string = uuidv4()): IFormElement {
  return {
    $type: 'AdaptiveForm',
    id: `JoinRule:~:${uuid}`,
    $children: [
      {
        $type: 'Layout.Section',
        id: `JoinRuleCommandBar:~:${uuid}`,
        label: 'JoinRuleCommandBar',
        attributes: {
          direction: 'horizontal',
        },
        $children: [
          {
            $type: 'Input.TableAlias' as any,
            id: `Join:~:${uuid}`,
            label: 'Join',
          },
          {
            $type: 'Form.RemoveItem' as any,
            id: `RemoveItem:~:${uuid}`,
            attributes: {
              itemId: `JoinRule:~:${uuid}`,
            },
          },
        ],
      },
      {
        $type: 'Layout.Heading' as any,
        id: `Heading:~:${uuid}-1`,
        attributes: {
          label: 'On',
          type: 'h5',
        },
      },
      QueryGroup(uuid),
    ],
  };
}

export function JoinGroupWithoutQuery(uuid: string = uuidv4()): IFormElement {
  return {
    $type: 'AdaptiveForm',
    id: `JoinRule:~:${uuid}`,
    $children: [
      {
        $type: 'Layout.Section',
        id: `JoinRuleCommandBar:~:${uuid}`,
        label: 'JoinRuleCommandBar',
        attributes: {
          direction: 'horizontal',
        },
        $children: [
          {
            $type: 'Input.TableAlias' as any,
            id: `Join:~:${uuid}`,
            label: 'Join',
          },
          {
            $type: 'Form.RemoveItem' as any,
            id: `RemoveItem:~:${uuid}`,
            attributes: {
              itemId: `JoinRule:~:${uuid}`,
            },
          },
        ],
      },
      {
        $type: 'Layout.Heading' as any,
        id: `Heading:~:${uuid}-1`,
        attributes: {
          label: 'On',
          type: 'h5',
        },
      },
    ],
  };
}
