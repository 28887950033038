import { AnyListItem, IChoice, IField } from '@rapid/data-model';
import { Table, Tag } from 'antd';
import Paragraph from 'antd/lib/typography/Paragraph';
import { ReactNode, useMemo } from 'react';
import { useListFields } from '../../utils/ListFields';

export interface IAdaptiveViewTableColumn {
  columnName: string;
  tableName: string;
  dataIndex: string;
}

export interface IAdaptiveViewTableProps {
  columns?: IAdaptiveViewTableColumn[];
  data?: AnyListItem[];
}

export function AdaptiveViewTable(
  props: React.PropsWithoutRef<IAdaptiveViewTableProps>,
) {
  const { getListFields } = useListFields();

  function renderColumn(field: IField): ReactNode {

    switch (field.Settings?.DisplayAs || field.FieldType) {
      case 'DateTime':
        return (value: string) => {
          if (!value) return null;
          return new Date(value).toLocaleDateString();
        };
      case 'Choice':
        return (value: string,) => {
          if (!value) return null;
          const choice = field.Settings?.Choices?.find(
            choice => (choice as IChoice).Text === value,
          ) as IChoice;
          return !!choice?.AccentColour ? (
            <Tag color={choice?.AccentColour}>{choice.Text}</Tag>
          ) : (
            value
          );
        };
      case 'Boolean':
        return (value: boolean) => {
          if (value === true) {
            return (
              <Tag icon={<i className="fal fa-check" />} color="success">
                &ensp;Yes
              </Tag>
            );
          }

          if (value === false) {
            return (
              <Tag icon={<i className="fal fa-times" />} color="error">
                &ensp;No
              </Tag>
            );
          }

          return null;
        };
      case 'JSON':
        return (value: JSON) => {
          if (!value) return null;
          const json = JSON.stringify(value);
          return <Paragraph copyable={{
            text: json,
            icon: [<i className='fal fa-copy' key='copy-icon' />, <i className='fal fa-check' key='copied-icon' />]
          }}>
            {json.length > 150 ? `${json.slice(0,140)}...` : json}
          </Paragraph>;
        }
      case 'Percentage':
        return (value: string) => {
          return !!value ? `${value}%` : null;
        } 
      case 'Currency':
        return (value: string) => {
          return !!value ? `${field.Settings?.CurrencyType} ${value}` : null;
        }
      default:
        return (value: string) => {
          return !!value ? `${value}` : null;
        }
    }
  }

  const columns = useMemo(() => {
    if (!!props.columns) {
      const columns: any[] = [];
      for (const column of props.columns) {
        const fields = getListFields(column.tableName);
        const field = fields?.find(f => f.ColumnName === column.columnName);
        columns.push({
          key: column.dataIndex,
          title: field ? field.Title : column.columnName,
          dataIndex: column.dataIndex,
          render: field
            ? renderColumn(field)
            : (value: string) => (!!value ? `${value}` : null),
          width: 150,
        });
      }
      return columns;
    }
  }, [props.columns]);

  return !!props.data && !!columns ? (
    <Table
      columns={columns}
      dataSource={props.data}
      pagination={false}
      bordered={true}
      scroll={{ 
        x: 'max-content', 
      }}
      size="small"
      sticky
      rowKey="uid"
    />
  ) : (
    <></>
  );
}
