import React, { useState } from 'react';
import { Modal, Form, Select, Checkbox, Row, notification, Input } from 'antd';
import { Xmla } from '@rapid/data-model';
import { useRapidApplication } from '@rapid/sdk';
import { ICustomView } from '../../../components/dataTypes';

interface AddTableFormProps {
  visible: boolean;
  views: ICustomView[];
  tables: string[];
  onSubmit: (table: Xmla.IXmlaTable) => void;
  onCancel: () => void;
}

const AddTableForm: React.FC<AddTableFormProps> = ({
  visible,
  views,
  tables,
  onSubmit,
  onCancel,
}) => {
  const [form] = Form.useForm();
  const [app] = useRapidApplication();
  const [currentView, setCurrentView] = useState<ICustomView>();
  const { Option } = Select;
  const viewOptions: string[] = [];

  for (const view of views) {
    if (!tables.find(table => table === view.Name))
      viewOptions.push(view.Name!);
  }

  return (
    <Modal
      visible={visible}
      title="Add Table"
      okText="Add"
      cancelText="Cancel"
      onCancel={onCancel}
      onOk={() => {
        form
          .validateFields()
          .then(values => {
            const dbSchema = values.view.match(/\s\([A-Z]*\)$/i) !== null ? 'dbo' : 'adaptive_views';
            const partitionSource: Xmla.IXmlaMPartitionSource = {
              type: Xmla.XmlaPartitionSourceType.M,
              attributes: '',
              expression:
              `let Source = Sql.Database("db_placeholder", "Muze Data (${app.tenant} - ${app.site})", [Query="select * from ${dbSchema}.[${values.view}]"]) in Source`,
            }
            const partition: Xmla.IXmlaPartition = {
              modeType: Xmla.XmlaPartitionModeType.DirectQuery,
              source: partitionSource
            };
            const table: Xmla.IXmlaTable = {
              name: values.view,
              partitions: [
                partition,
              ],
              columns: values.columns ?? [],
              measures: [],
            };    
            if (dbSchema === 'dbo') {
              table.columns.push({
                name: 'id',
                source: 'id',
                expression: '',
                type: Xmla.XmlaColumnType.DataColumn,
                dataType: 'Number',
              });
            }
            form.resetFields();
            onSubmit(table);
          })
          .catch(info => {
            notification.error({
              message: 'Validate Failed:',
              description: info,
              duration: 5,
            });
          });
      }}
    >
      <Form form={form} layout="vertical" name="form-in-modal">
        <Form.Item
          name="view"
          label="Select a view"
          rules={[{ required: true, message: 'Please select a view.' }]}
        >
          <Select
            showSearch
            style={{ width: 200 }}
            placeholder="Select a view"
            optionFilterProp="children"
            onChange={ev =>
              setCurrentView(views.find(view => view.Name === ev))
            }
            filterOption={(input, option) =>
              option?.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {viewOptions.map((option, index) => (
              <Option key={`adaptive-view-${index}`} value={option}>
                {option}
              </Option>
            ))}
          </Select>
        </Form.Item>
        {!!currentView && (
          <Form.Item name="columns">
            <Checkbox.Group>
              {currentView.Fields?.filter(
                field => !field.FieldType.match(/(multilookup|multichoice)/i),
              ).map(field => (
                <Row>
                  <Checkbox
                    key={`${currentView} ${field.Title}`}
                    value={
                      {
                        name: field.Title,
                        source: field.ColumnName,
                        expression: '',
                        dataType: field.FieldType,
                      } as Xmla.IXmlaColumn
                    }
                  >
                    {field.Title}
                  </Checkbox>
                </Row>
              ))}
            </Checkbox.Group>
          </Form.Item>
        )}
      </Form>
    </Modal>
  );
};

export default AddTableForm;
