import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { ApplicationAuthenticationContext } from '../authentication';
import { ApplicationContext } from './application-context';
import { AddConnectionCard } from './login/add-connection-card';
import { MultiSiteContext, SiteContext } from './site-context';
/**
 * Creates a multi-site RAPID Platform application.
 *
 * Multi-site RAPID Applications have routes, and enough of a user interface
 * to facilitate a user sign-in and management of connections to site databases.
 *
 * Child components under a RAPID application have access to the {@link useRapidApplication}
 * hook, the site file and all relevant authentication and application state.
 *
 * @Component
 * @category RAPID Application
 */
export function MultiSiteApplication({ clientId, displayName, children, className, namespace, loginExperience: OverriddenLoginExperience, }) {
    return (React.createElement("div", { className: className },
        React.createElement(ApplicationContext, { displayName: displayName, clientId: clientId, namespace: namespace },
            React.createElement(ApplicationAuthenticationContext, null,
                React.createElement(Switch, null,
                    React.createElement(Route, { path: "/_:env/:tenant/:site" },
                        React.createElement(MultiSiteContext, null, children)),
                    React.createElement(Redirect, { from: "/:tenant/:site", to: "/_prod/:tenant/:site" }),
                    React.createElement(Route, { path: "/" }, OverriddenLoginExperience ? (React.createElement(OverriddenLoginExperience, null)) : (React.createElement(AddConnectionCard, null))))))));
}
export function SingleSiteApplication({ children, className, displayName, clientId, namespace, tenant, site, env }) {
    return (React.createElement("div", { className: className },
        React.createElement(ApplicationContext, { displayName: displayName, clientId: clientId, namespace: namespace, tenant: tenant, site: site, environment: env },
            React.createElement(ApplicationAuthenticationContext, null,
                React.createElement(SiteContext, null, children)))));
}
