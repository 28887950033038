/**
 * Groups an array of items by an iteratee
 *
 * @example
 * ```ts
 *
 * ```
 */
export function groupBy(ary, groupFn) {
    return ary.reduce((prev, curr) => {
        const groupingKey = typeof groupFn === 'function'
            ? groupFn(curr)
            : curr[groupFn];
        if (!prev.hasOwnProperty(groupingKey)) {
            prev[groupingKey] = [];
        }
        prev[groupingKey].push(curr);
        return prev;
    }, {});
}
