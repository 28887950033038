import { useRapidAuthenticatedFetch } from './authentication';
import { proxyRestClient } from './proxy-rest-client';
import { useRapidApplication } from './rapid-application/application-context';
import { useCurrentEnvironment } from './use-environments';
export function useCurrentSiteEndpoint() {
    const [app] = useRapidApplication();
    const environment = useCurrentEnvironment();
    const fetch = useRapidAuthenticatedFetch();
    const client = proxyRestClient(environment.api_url, fetch)[app.tenant][app.site];
    return client;
}
