import ReactDOM from 'react-dom';
import {
  ManagedPowerBIContext,
  ManagedMultiSiteApplication,
  ManagedViewContext,
} from '@rapid/sdk';
import {
  Sidebar,
  SidebarHeader,
  SidebarHeaderItem,
  SidebarMain,
  SidebarMenuItem,
} from './components/sidebar/sidebar';
import { Pages } from './Pages';

import '@rapid/adaptive-forms/css/adaptive-forms.css';
import 'antd/dist/antd.css';
import './index.scss';

ReactDOM.render(
  <ManagedMultiSiteApplication
    clientId="cd5db0ec-1419-4ae6-9434-21cfb83fc42d"
    namespace="rapid::reports"
    displayName="Rapid Reports"
    className="Reports"
  >
    <ManagedPowerBIContext>
      <ManagedViewContext
        list="Adaptive Dashboards"
        dataSource="All"
        columns={['id', 'title', 'slug', 'schema', 'description']}
      >
        <Sidebar>
          <SidebarHeader>
            <SidebarHeaderItem>
              <i className="fak fa-rapid-brand-mark fa-3x" style={{color:"white"}} />
            </SidebarHeaderItem>
          </SidebarHeader>
          <SidebarMain>
            <SidebarMenuItem
              name="Dashboards"
              icon={<i className="fal fa-tachometer" />}
              route="/dashboards"
            />
            <SidebarMenuItem
              name="PBI Reports"
              icon={<i className="fak fa-powerbi fa-xs" />}
              route="/pbireports"
            />
            <SidebarMenuItem
              name="PBI Datasets"
              icon={<i className="fal fa-database" />}
              route="/pbidatasets"
            />
            <SidebarMenuItem
              name="Adaptive Views"
              icon={<i className="fal fa-table" />}
              route="/adaptiveviews"
            />
          </SidebarMain>
        </Sidebar>
        <Pages />
      </ManagedViewContext>
    </ManagedPowerBIContext>
  </ManagedMultiSiteApplication>,
  document.getElementById('root'),
);
