import { FormElementInputProps } from '@rapid/adaptive-forms';
import { useComposedClassName } from '@rapid/sdk';
import { Select } from 'antd';

export function OperandField({
  disabled,
  id,
  value,
  label,
  onChange,
}: FormElementInputProps<string>) {
  const { Option } = Select;

  const operandFieldClass = useComposedClassName(
    function* () {
      yield 'Label OperandField';

      if (disabled) yield 'disabled';
    },
    [disabled],
  );

  const QBOperator = [
    { value: 'eq', label: '=' },
    { value: 'ne', label: '!=' },
    { value: 'lt', label: '<' },
    { value: 'le', label: '<=' },
    { value: 'gt', label: '>' },
    { value: 'ge', label: '>=' },
    { value: 'value exists', label: 'has value', voidTag: true },
    {
      value: 'value does not exist',
      label: 'no value',
      voidTag: true,
    },
    { value: 'substringof', label: 'contains' },
  ];

  return (
    <label className={operandFieldClass} id={id}>
      {!!label && <span className="Name">{label}</span>}
      <Select value={value} onChange={value => onChange?.(value)} size="small">
        {QBOperator?.map(operator => (
          <Option key={operator.value} value={operator.value}>
            {operator.label}
          </Option>
        ))}
      </Select>
    </label>
  );
}
