import { AnyListItem, IChoice, IField } from '@rapid/data-model';
import { useCurrentSiteEndpoint, useListItemContext } from '@rapid/sdk';
import { Table, Tag } from 'antd';
import Paragraph from 'antd/lib/typography/Paragraph';
import { ReactNode, useEffect, useMemo, useState } from 'react';
import { IAdaptiveView } from '../../features/AdaptiveViewsList/adaptiveViewsList';
import { adaptiveViewColumns } from '../../utils/adaptiveViewColumns';
import { useListFields } from '../../utils/ListFields';

export function ManagedAdaptiveViewTable() {
  const ep = useCurrentSiteEndpoint();
  const [context] = useListItemContext();
  const [items, setItems] = useState<AnyListItem[]>();

  const viewColumns = useMemo(() => { 
    if (!!context.item && !!(context.item as IAdaptiveView)) {
      return adaptiveViewColumns(context.item as IAdaptiveView);
    }
  }, [context.item]);

  const { getListFields } = useListFields();

  function renderColumn(field: IField): ReactNode {

    switch (field.Settings?.DisplayAs || field.FieldType) {
      case 'DateTime':
        return (value: string) => {
          if (!value) return null;
          return new Date(value).toLocaleDateString();
        };
      case 'Choice':
        return (value: string,) => {
          if (!value) return null;
          const choice = field.Settings?.Choices?.find(
            choice => (choice as IChoice).Text === value,
          ) as IChoice;
          return !!choice?.AccentColour ? (
            <Tag color={choice?.AccentColour}>{choice.Text}</Tag>
          ) : (
            value
          );
        };
      case 'Boolean':
        return (value: boolean) => {
          if (value === true) {
            return (
              <Tag icon={<i className="fal fa-check" />} color="success">
                &ensp;Yes
              </Tag>
            );
          }

          if (value === false) {
            return (
              <Tag icon={<i className="fal fa-times" />} color="error">
                &ensp;No
              </Tag>
            );
          }

          return null;
        };
      case 'JSON':
        return (value: JSON) => {
          if (!value) return null;
          const json = JSON.stringify(value);
          return <Paragraph copyable={{
            text: json,
            icon: [<i className='fal fa-copy' key='copy-icon' />, <i className='fal fa-check' key='copied-icon' />]
          }}>
            {json.length > 150 ? `${json.slice(0,140)}...` : json}
          </Paragraph>;
        }
      case 'Percentage':
        return (value: string) => {
          return !!value ? `${value}%` : null;
        } 
      case 'Currency':
        return (value: string) => {
          return !!value ? `${field.Settings?.CurrencyType} ${value}` : null;
        }
      default:
        return (value: string) => {
          return !!value ? `${value}` : null;
        }
    }
  }

  async function updateAdaptiveViewItems(viewName: string) {
    try {
      const data = await ep.Views[viewName].getJson();
      setItems(data.value as AnyListItem[]);
    } catch (err) {}
  }

  const columns = useMemo(() => {
    if (!!viewColumns) {
      return viewColumns.map(col => {
        const fields = getListFields(col.tableName);
        const field = fields?.find(f => f.ColumnName === col.columnName);
        return {
          key: col.dataIndex,
          title: field ? field.Title : col.columnName,
          dataIndex: col.dataIndex,
          render: field
            ? renderColumn(field)
            : (value: string) => (!!value ? `${value}` : null),
          width: 150,
        } as any;
      });
    }
  }, [viewColumns]);

  useEffect(
    function onItemUpdated() {
      if (!!context.item) {
        updateAdaptiveViewItems((context.item as IAdaptiveView).view_name);
      }
    },
    [context.item],
  );

  return !!columns && !!items ? (
    <Table
      columns={columns}
      dataSource={items}
      pagination={false}
      bordered={true}
      scroll={{ 
        x: 'max-content', 
      }}
      size="small"
      sticky
      rowKey="uid"
    />
  ) : (
    <></>
  );
}
