import React, { useState } from 'react';
import { Modal } from 'antd';
import { useCurrentSite } from '@rapid/sdk';
import { IQueryBuilderType, QueryBuilder } from '../../components/QueryBuilder/QueryBuilder';
import { IAdaptiveView } from '../AdaptiveViewsList/adaptiveViewsList';
import { SQLQueryConverter } from '../../components/QueryBuilder/utils/sql-query-converter';

interface EditAdaptiveViewFormProps {
  initialData: IAdaptiveView;
  visible: boolean;
  onSubmit: (item: IAdaptiveView) => void;
  onCancel: () => void;
}

export const EditAdaptiveViewForm: React.FC<EditAdaptiveViewFormProps> = ({
  initialData,
  visible,
  onSubmit,
  onCancel,
}) => {
  const [currentSite] = useCurrentSite();
  const type = IQueryBuilderType.SQL;
  const [data, setData] = useState<Record<string, any>>();

  return (
    <Modal
      width="700px"
      visible={visible}
      title="Edit Adaptive View"
      okText="Save "
      cancelText="Cancel"
      onCancel={() => {
        onCancel();
        setData(undefined);
      }}
      onOk={() => {
        if (!!data) {
          const sql = new SQLQueryConverter(data).getQuery();
          const item: IAdaptiveView = {
            ...initialData,
            schema: sql,
          };
          onSubmit(item);
          setData(undefined);
        }
      }}
    >
      {!!currentSite?.Lists && (
        <QueryBuilder
          type={type}
          lists={currentSite.Lists}
          data={initialData.schema}
          onChange={(data) => {
            setData(data);
          }}
        />
      )}
    </Modal>
  );
};
