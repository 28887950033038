import * as React from 'react';
import { useComposedClassName } from '@rapid/sdk';
import { descriptionTag, fieldAttributesSchemaTag, iconTag, titleTag, } from '../../symbols';
import { useMemo } from 'react';
export function StarRating({ label, value, onChange, selected, unselected, maxNumber, className, disabled, }) {
    const ratingClassName = useComposedClassName(function* () {
        yield 'StarRating Label flex column';
        if (className)
            yield className;
        if (disabled)
            yield 'disabled';
    }, [className]);
    const onRatingClickedInner = (rating) => () => {
        if (disabled) {
            return;
        }
        if (value === rating) {
            onChange === null || onChange === void 0 ? void 0 : onChange(rating + 1);
            return;
        }
        onChange === null || onChange === void 0 ? void 0 : onChange(rating);
    };
    const stars = useMemo(function buildStarsOnMount() {
        return [...Array(maxNumber !== null && maxNumber !== void 0 ? maxNumber : 5).keys()].reverse();
    }, [maxNumber]);
    return (React.createElement("label", { className: ratingClassName },
        !!label && React.createElement("span", { className: "Name" }, label),
        React.createElement("div", { className: "rating" }, stars.map(i => (React.createElement("i", { key: i, className: `${value <= i
                ? selected !== null && selected !== void 0 ? selected : 'selected fas fa-star'
                : unselected !== null && unselected !== void 0 ? unselected : 'unselected far fa-star'}`, onClick: onRatingClickedInner(i) }))))));
}
StarRating[titleTag] = 'Star rating';
StarRating[descriptionTag] = 'Simple star rating component';
StarRating[iconTag] = 'fa-stars';
StarRating[fieldAttributesSchemaTag] = [
    {
        $type: 'Input.Text',
        id: 'label',
        label: 'Label',
    },
    {
        $type: 'Input.Text',
        id: '$.attributes.selected',
        label: 'Icon name for selected',
    },
    {
        $type: 'Input.Text',
        id: '$.attributes.unselected',
        label: 'Icon name for unselected',
    },
    {
        $type: 'Input.Number',
        id: '$.attributes.maxNumber',
        label: 'Number of rating icons that should render',
    },
];
