import { useRapidApplication } from './rapid-application';
export function useList(list) {
    var _a;
    const [app, updateApp] = useRapidApplication();
    if (!list) {
        throw new Error(`useList: Expected string or IList for list parameter, but got ${typeof list}`);
    }
    const innerList = typeof list !== 'string'
        ? list
        : (_a = app.currentSite) === null || _a === void 0 ? void 0 : _a.Lists.find(l => l.ListName.localeCompare(list) === 0);
    return [
        innerList,
        function updateList(d) {
            updateApp(draft => {
                var _a;
                const innerList = (_a = draft.currentSite) === null || _a === void 0 ? void 0 : _a.Lists.find(l => l.ListName.localeCompare(typeof list === 'string' ? list : list.ListName) === 0);
                d(innerList);
            });
        },
    ];
}
export function useField(list, field) {
    const [innerList, updateList] = useList(list);
    if (!field) {
        throw new Error(`useField: Expected string or IField for field parameter, but got ${typeof list}`);
    }
    const innerField = typeof field !== 'string'
        ? field
        : innerList.Fields.find(i => i.ColumnName.localeCompare(field) === 0);
    return [
        innerField,
        function updateField(d) {
            updateList(draft => {
                const innerField = draft.Fields.find(f => f.ColumnName.localeCompare(innerField.ColumnName) === 0);
                d(innerField);
            });
        },
    ];
}
export function useChoices(list, field) {
    var _a;
    const [innerField] = useField(list, field);
    if (innerField.FieldType !== 'Choice') {
        throw new Error(`useField: field ${innerField.ColumnName} on ${typeof list === 'string' ? list : list.ListName} is not a choice.`);
    }
    const choices = (_a = innerField.Settings) === null || _a === void 0 ? void 0 : _a.Choices.map(c => typeof c === 'string' ? { Text: c, Value: c } : c);
    return [choices];
}
