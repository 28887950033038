import React from 'react';
import { useComposedClassName } from '../use-composed-class-names';
/**
 * Button!
 *
 * This is a simple button that takes requires a label and onClick
 *
 * Props List:
 *
 * - **onClick()** | a function that is passed directly to the buttons onClick event
 * - **children** | The string the button is wrapped around that becomes it's label i.e. <Button>Hello</Button>
 * - **buttonStyle** | Changes the style of the button i.e. _Primary, Danger, Text_
 * - **buttonSize** | Changes the padding size of the button i.e. _Small, Large_
 * - **fullwidth** | Sets the button to take up the full width of it's parent container
 * - **isDisabled** | When true will render and show the item as disabled
 */
export function Button({ onClick, children, buttonSize, buttonStyle, fullWidth, isDisabled, className, margin, }) {
    const cssClass = useComposedClassName(function* () {
        yield 'rp-button';
        yield className;
        if (buttonStyle)
            yield `rp-button-${buttonStyle}`;
        if (buttonSize)
            yield `rp-button-${buttonSize}`;
        if (fullWidth)
            yield 'rp-width-1-1';
        if (Array.isArray(margin)) {
            yield* margin.map(item => `rp-margin-${item}`);
        }
        else if (margin) {
            yield `rp-margin-${margin}`;
        }
    }, [className, buttonSize, buttonStyle, margin, fullWidth]);
    return (React.createElement("button", { className: cssClass, onClick: event => onClick === null || onClick === void 0 ? void 0 : onClick(event), disabled: !!isDisabled }, children));
}
