import { IQueryBuilderTable } from "@rapid/data-model/lib/query-builder";

export type FormElementType =
  | 'root'
  | 'Heading'
  | 'Select'
  | 'From'
  | 'Name'
  | 'OrderBy'
  | 'JoinsGroup'
  | 'AddJoin'
  | 'JoinRule'
  | 'Join'
  | 'RemoveItem'
  | 'QueryGroup'
  | 'RadioAndOr'
  | 'AddRule'
  | 'AddGroup'
  | 'QueryGroupCommandBar'
  | 'QueryRule'
  | 'lValue'
  | 'Operator'
  | 'rValue';

export type FormElementId = {
  type: FormElementType;
  uuid: string;
};

export const getFormElementId = (str: string) =>
  str.match(
    /^(?<type>[A-Z]*):~:(?<uuid>[0-9A-F]{8}-[0-9A-F]{4}-[0-9A-F]{4}-[0-9A-F]{4}-[0-9A-F]{12})/i,
  )?.groups as FormElementId;

export const getType = (str: string) => str.match(/^([A-Z]*):~:/i)?.[1];

export const getUuid = (str: string) =>
  str.match(
    /([0-9A-F]{8}-[0-9A-F]{4}-[0-9A-F]{4}-[0-9A-F]{4}-[0-9A-F]{12})/i,
  )?.[1];

export const isColumn = (str: string) =>
  str.match(
    /^[A-Z]*:~:[0-9A-F]{8}-[0-9A-F]{4}-[0-9A-F]{4}-[0-9A-F]{4}-[0-9A-F]{12}\./i,
  ) !== null;

export const getColumn = (column: String, data: Record<string, any>) => {
  const [tableOrAliasRef, columnName] = column.split('.');
  const tableOrAlias = returnData(tableOrAliasRef, data) as IQueryBuilderTable;
  return (tableOrAlias.alias || tableOrAlias.table) + '.' + columnName;
};

export function removeData(
  dataRef: string, 
  data: Record<string, any>
) {
  for (const key of Object.keys(data)) {
    if (key === dataRef) {
      delete data[key];
      return;
    }

    if (data[key] instanceof Object) removeData(dataRef, data[key]);
  }
  return;
}

export const returnData = (
  dataRef: string,
  data: Record<string, any>,
): Record<string, any> | undefined => {
  if (data.hasOwnProperty(dataRef)) return data[dataRef];

  for (let key of Object.keys(data)) {
    if (typeof data[key] === 'object') {
      const d = returnData(dataRef, data[key]);

      if (!!d) return d;
    }
  }

  return;
};
