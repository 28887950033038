import React from 'react';
import { descriptionTag, fieldAttributesSchemaTag, titleTag, iconTag, configComponentTag, } from '../index';
import { SanitizedDiv, useComposedClassName } from '@rapid/sdk';
import { Button, Divider } from 'antd';
export function Section({ children, divider, direction, label, heading, headingType, showDescription, description, }) {
    const formClass = useComposedClassName(function* () {
        yield 'FormElement';
        if (direction)
            yield direction;
    }, [direction]);
    return (React.createElement("div", { className: formClass },
        React.createElement("div", { className: "Heading" },
            heading && React.createElement(headingType !== null && headingType !== void 0 ? headingType : 'h3', {}, label),
            showDescription && !!description && React.createElement(SanitizedDiv, { html: description }),
            divider && React.createElement(Divider, null)),
        React.createElement("div", { className: "SectionChildren" }, children)));
}
export function DesignerConfig({ updateElement, removeElement, element, }) {
    const changeDirection = (e) => {
        e.preventDefault();
        e.stopPropagation();
        updateElement(d => {
            var _a;
            if (((_a = element.attributes) === null || _a === void 0 ? void 0 : _a.direction) === 'horizontal') {
                d.attributes.direction = 'vertical';
            }
            else {
                d.attributes.direction = 'horizontal';
            }
        });
    };
    return (React.createElement("div", null,
        React.createElement(Button, { onClick: changeDirection }, "Change layout direction")));
}
Section[titleTag] = 'Section';
Section[descriptionTag] =
    'Form layout section allowing horizontal or vertical layouts and a section heading';
Section[iconTag] = 'fa-border-outer';
Section[configComponentTag] = DesignerConfig;
Section[fieldAttributesSchemaTag] = [
    {
        $type: 'Input.Text',
        id: 'label',
        label: 'Label',
    },
    {
        $type: 'Input.Choice',
        id: '$.attributes.direction',
        label: 'Layout direction',
        defaultValue: 'vertical',
        attributes: {
            choices: [{ Text: 'horizontal' }, { Text: 'vertical' }],
        },
    },
    {
        $type: 'Input.Boolean',
        id: '$.attributes.heading',
        defaultValue: false,
        label: 'Show heading',
    },
    {
        $type: 'Input.Choice',
        id: '$.attributes.headingType',
        label: 'Heading size',
        defaultValue: 'h3',
        attributes: {
            choices: [
                { Text: 'h1' },
                { Text: 'h2' },
                { Text: 'h3' },
                { Text: 'h4' },
                { Text: 'h5' },
                { Text: 'h6' },
            ],
        },
        conditions: [
            {
                elementRef: '$.attributes.heading',
                validation: [['yup.boolean'], ['yup.oneOf', [true, undefined, null]]],
            },
        ],
    },
    {
        $type: 'Input.Boolean',
        id: '$.attributes.divider',
        label: 'Show divider',
        defaultValue: false,
        conditions: [
            {
                elementRef: '$.attributes.heading',
                validation: [['yup.boolean'], ['yup.oneOf', [true, undefined, null]]],
            },
        ],
    },
    {
        $type: 'Input.Boolean',
        id: '$.attributes.showDescription',
        label: 'Show description',
        defaultValue: false,
    },
    {
        $type: 'Input.MultiLineText',
        id: '$.attributes.description',
        label: 'Description',
        conditions: [
            {
                elementRef: '$.attributes.showDescription',
                validation: [['yup.boolean'], ['yup.oneOf', [true, undefined, null]]],
            },
        ],
    },
];
