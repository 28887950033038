import React, { useEffect, useRef } from 'react';
import { useComposedClassName } from '@rapid/sdk';
import { descriptionTag, fieldAttributesSchemaTag, iconTag, titleTag, } from '../../index';
function LikertScale({ label, className, answers, children, id, mobileStyling, rowShading, }) {
    const likertClassName = useComposedClassName(function* () {
        yield 'Likert Label flex column';
        if (className)
            yield className;
        if (rowShading)
            yield 'row-shading';
        if (mobileStyling)
            yield 'mobile-styling';
    }, [className, rowShading, mobileStyling]);
    const ref = useRef(null);
    useEffect(function addGridColumnsOnMount() {
        var _a;
        (_a = ref.current) === null || _a === void 0 ? void 0 : _a.style.setProperty('--likert-grid-columns', answers === null || answers === void 0 ? void 0 : answers.length.toString());
    }, []);
    return (React.createElement("label", { ref: ref, className: likertClassName, id: id, title: label },
        !!label && React.createElement("span", { className: "Name" }, label),
        React.createElement("div", { className: "Answers" }, answers === null || answers === void 0 ? void 0 : answers.map(a => (React.createElement("span", { className: "Answer", key: a }, a)))),
        children));
}
LikertScale[titleTag] = 'Likert scale';
LikertScale[descriptionTag] = 'Select a single choice for multiple questions';
LikertScale[iconTag] = 'fa-smile-plus';
LikertScale[fieldAttributesSchemaTag] = [
    {
        $type: 'Input.Text',
        id: 'label',
        label: 'Label',
    },
    {
        $type: 'Input.Text',
        id: '$.attributes.listName',
        label: 'ListName',
    },
    {
        $type: 'Input.Boolean',
        id: '$.attributes.mobileStyling',
        label: 'Always use mobile styling',
    },
    {
        $type: 'Input.Boolean',
        id: '$.attributes.rowShading',
        label: 'Show row shading',
    },
    {
        $type: 'Input.Array',
        id: '$.attributes.answers',
        label: 'Answers',
    },
];
export { LikertScale };
