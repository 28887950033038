import { FormElementInputProps, returnField } from '@rapid/adaptive-forms';
import { useComposedClassName } from '@rapid/sdk';
import { Button } from 'antd';
import { JoinRule } from '../layouts/sql-join-layout';
import { useQueryBuilderContext } from '../QueryBuilder';

interface IAddJoinProps {
  parentId: string;
}

export function AddJoin({
  disabled,
  id,
  label,
  parentId,
}: FormElementInputProps<string, IAddJoinProps>) {
  const [context, updateContext] = useQueryBuilderContext();

  const addJoinClass = useComposedClassName(
    function* () {
      yield 'Label AddJoin';

      if (disabled) yield 'disabled';
    },
    [disabled],
  );

  const onClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();

    updateContext(d => {
      const parent = returnField(parentId, d.form);

      if (!Array.isArray(parent.$children)) {
        parent.$children = [];
      }

      parent.$children.push(JoinRule());
    })
  };

  return (
    <label className={addJoinClass} id={id}>
      {!!label && <span className="Name">{label}</span>}
      <Button onClick={onClick} size="small">
        <i className="fal fa-plus" />
        &ensp;Join
      </Button>
    </label>
  );
}
