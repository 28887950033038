import { IFormElement } from '@rapid/adaptive-forms';
import { v4 as uuidv4 } from 'uuid';

export function QueryGroup(uuid: string = uuidv4()): IFormElement {
  return {
    $type: 'AdaptiveForm',
    id: `QueryGroup:~:${uuid}`,
    $children: [
      {
        $type: 'Layout.Section',
        id: `QueryGroupCommandBar:~:${uuid}`,
        label: 'QueryGroupCommandBar',
        attributes: {
          direction: 'horizontal',
        },
        $children: [
          {
            $type: 'Input.AndOrToggle' as any,
            id: `RadioAndOr:~:${uuid}`,
          },
          {
            $type: 'Form.AddRule' as any,
            id: `AddRule:~:${uuid}`,
            attributes: {
              parentId: `QueryGroup:~:${uuid}`,
            },
          },
          {
            $type: 'Form.AddGroup' as any,
            id: `AddGroup:~:${uuid}`,
            attributes: {
              parentId: `QueryGroup:~:${uuid}`,
            },
          },
        ],
      },
    ],
  } as IFormElement;
}

export function QueryGroupRemovable(uuid: string = uuidv4()): IFormElement {
  return {
    $type: 'AdaptiveForm',
    id: `QueryGroup:~:${uuid}`,
    $children: [
      {
        $type: 'Layout.Section',
        id: `QueryGroupCommandBar:~:${uuid}`,
        label: 'QueryGroupCommandBar',
        attributes: {
          direction: 'horizontal',
        },
        $children: [
          {
            $type: 'Input.AndOrToggle' as any,
            id: `RadioAndOr:~:${uuid}`,
          },
          {
            $type: 'Form.AddRule' as any,
            id: `AddRule:~:${uuid}`,
            attributes: {
              parentId: `QueryGroup:~:${uuid}`,
            },
          },
          {
            $type: 'Form.AddGroup' as any,
            id: `AddGroup:~:${uuid}`,
            attributes: {
              parentId: `QueryGroup:~:${uuid}`,
            },
          },
          {
            $type: 'Form.RemoveItem' as any,
            id: `RemoveItem:~:${uuid}`,
            attributes: {
              itemId: `QueryGroup:~:${uuid}`,
            },
          },
        ],
      },
    ],
  } as IFormElement;
}

export function QueryRule(uuid: string = uuidv4()): IFormElement {
  return {
    $type: 'AdaptiveForm',
    id: `QueryRule:~:${uuid}`,
    attributes: {
      direction: 'horizontal',
    },
    $children: [
      {
        $type: 'Input.FieldSelect' as any,
        id: `lValue:~:${uuid}`,
      },
      {
        $type: 'Input.OperandField' as any,
        id: `Operator:~:${uuid}`,
      },
      {
        $type: 'Input.GenericField',
        id: `rValue:~:${uuid}`,
        attributes: {
          fieldRefId: `lValue:~:${uuid}`,
        },
      },
      {
        $type: 'Form.RemoveItem' as any,
        id: `RemoveItem:~:${uuid}`,
        attributes: {
          itemId: `QueryRule:~:${uuid}`,
        },
      },
    ],
  } as IFormElement;
}
