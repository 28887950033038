import { useViewContextListItemArray } from '@rapid/sdk';
import { Spinner } from '@rapid/sdk/lib/ui';
import { Select } from 'antd';
import React, { useMemo, useState } from 'react';
export function LookupSelect({ setSearchTerm, labelKey, selectedValue, valueKey, onChange, dropdownRender, mode, columnName, id, }) {
    const [items, context] = useViewContextListItemArray();
    const [innerSearchTerm, setInnerSearchTerm] = useState('');
    const options = useMemo(() => items.map(item => {
        var _a;
        let itemLabel = (_a = item[labelKey.replace(/_id$/, '')]) === null || _a === void 0 ? void 0 : _a.toString().trim();
        const label = itemLabel === 'undefined' || itemLabel === 'null' ? (React.createElement("span", { className: "gce-text-meta text-muted" },
            "<",
            context.list.ListNameSingular,
            " ",
            item.id,
            ">")) : (itemLabel);
        return {
            label,
            value: item[valueKey],
            item,
            column_name: columnName,
        };
    }), [items]);
    const onSearchChange = (value) => {
        setInnerSearchTerm(value);
    };
    const onSelect = (value, option) => {
        if (!!Array.isArray(option)) {
            return;
        }
        onChange === null || onChange === void 0 ? void 0 : onChange(option);
    };
    const onDeselect = (value) => {
        onChange === null || onChange === void 0 ? void 0 : onChange();
    };
    const onKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            e.stopPropagation();
            setSearchTerm(innerSearchTerm);
        }
    };
    const fetchItemsOnOpen = (open) => {
        if (open) {
            context.fetchItems();
        }
    };
    return (React.createElement(Select, { showArrow: true, notFoundContent: context.loading && React.createElement(Spinner, null), loading: context.loading, defaultActiveFirstOption: false, getPopupContainer: () => document.getElementById(id), value: selectedValue, dropdownRender: dropdownRender, onSelect: onSelect, onDeselect: onDeselect, onChange: onSelect, optionLabelProp: 'label', searchValue: innerSearchTerm, onInputKeyDown: onKeyDown, onSearch: onSearchChange, filterOption: false, showSearch: true, options: context.loading ? null : options, mode: mode, onDropdownVisibleChange: fetchItemsOnOpen }));
}
