import { useComposedClassName } from '@rapid/sdk';
import React from 'react';
import { iconTag, descriptionTag, fieldAttributesSchemaTag, titleTag, } from '../symbols';
export function LayoutSpacer({ direction, size, }) {
    const spacerClass = useComposedClassName(function* () {
        yield 'LayoutSpacer';
        yield !!direction ? direction : 'vertical';
        yield !!size ? size : 'medium';
    }, [direction, size]);
    return React.createElement("div", { className: spacerClass });
}
LayoutSpacer[titleTag] = 'Divider';
LayoutSpacer[descriptionTag] = 'Simple divider to draw lines between things';
LayoutSpacer[iconTag] = 'fa-user-astronaut';
// LayoutDivider[configComponentTag] = DesignerConfig;
LayoutSpacer[fieldAttributesSchemaTag] = [
    {
        $type: 'Input.Choice',
        id: '$.attributes.direction',
        label: 'Layout direction',
        defaultValue: 'vertical',
        attributes: {
            choices: [{ Text: 'horizontal' }, { Text: 'vertical' }],
        },
    },
    {
        $type: 'Input.Choice',
        id: '$.attributes.size',
        label: 'Size',
        defaultValue: 'medium',
        attributes: {
            choices: [{ Text: 'small' }, { Text: 'medium' }, { Text: 'large' }],
        },
    },
];
