import { useMemo } from 'react';
import { useRapidApplication } from './rapid-application/index';
import { groupBy } from './utils/group-by';
/**
 * Uses a list of RAPID platform environments.
 * @hook
 * @category General Hooks
 */
export function useEnvironments() {
    const [{ environments }] = useRapidApplication();
    const envGroups = useMemo(() => groupBy(Object.entries(environments).map(([key, env]) => ({ key, ...env })), 'category'), [environments]);
    return [environments, envGroups];
}
export function useCurrentEnvironment() {
    const [environments] = useEnvironments();
    const [app] = useRapidApplication();
    const env = {
        ...environments,
        local: {
            name: 'Minilith Local Development',
            location: 'Australia',
            api_url: `${window.location.origin}/api`,
            category: 'Dev',
            wf_url: 'http://api-test.rapidplatform.com/workflow',
        },
    };
    return env === null || env === void 0 ? void 0 : env[app.env];
}
