import React from 'react';
import { useComposedClassName } from '@rapid/sdk';
import { Input } from 'antd';
import { descriptionTag, fieldAttributesSchemaTag, iconTag, titleTag, } from '../symbols';
export function NumberField({ $type, id, value, label, onChange, disabled, placeholder, currencyType, size, }) {
    const onKeyDown = (e) => {
        var _a;
        if (e.key === 'Backspace' || e.key === 'Delete' || e.key === 'Enter')
            return null;
        if ((_a = e.key) === null || _a === void 0 ? void 0 : _a.match(/\D/))
            return e.key;
    };
    const onBlur = (e) => {
        if (!!e.target.value.includes('.'))
            onChange === null || onChange === void 0 ? void 0 : onChange(+e.target.value.replace('.', ''));
    };
    const onChangeInner = (e) => {
        if ($type === 'Input.WholeNumber' && !!e.target.value.match(/\D/))
            return;
        onChange === null || onChange === void 0 ? void 0 : onChange(+e.target.value);
    };
    const labelClass = useComposedClassName(function* () {
        yield 'Label flex column';
        if (disabled)
            yield 'disabled';
        switch ($type) {
            case 'Input.Percentage':
                yield 'PercentageField';
                break;
            case 'Input.Currency':
                yield 'CurrencyField';
                break;
            default:
                yield 'NumberField';
                break;
        }
    }, [$type, disabled]);
    return (React.createElement("label", { className: labelClass, id: id },
        !!label && React.createElement("span", { className: "Name" }, label),
        React.createElement(Input, { prefix: $type === 'Input.Currency' && currencyType, suffix: $type === 'Input.Percentage' && (React.createElement("i", { className: "Percentage far fa-percentage fa-fw" })), onKeyDown: onKeyDown, placeholder: placeholder, onBlur: onBlur, step: 1, type: "number", disabled: disabled, value: value, onChange: onChangeInner, size: size })));
}
NumberField[titleTag] = 'Number field';
NumberField[descriptionTag] = 'A number input';
NumberField[iconTag] = 'fa-tally';
NumberField[fieldAttributesSchemaTag] = [
    {
        $type: 'Input.Text',
        id: 'label',
        label: 'Label',
    },
    {
        $type: 'Input.Number',
        id: 'defaultValue',
        label: 'Default Value',
    },
    {
        $type: 'Input.Text',
        id: 'placeholder',
        label: 'Placeholder',
    },
    {
        $type: 'Input.Boolean',
        id: 'required',
        label: 'Required',
    },
];
