import { v4 as uuidv4 } from 'uuid';
import { message, notification, Modal } from 'antd';
import { RequestError, sanitizeHtml } from '@rapid/sdk';

export async function feedbackWrapper(
  func: Function,
  loading: string,
  success: string,
  error: string,
) {
  const key = uuidv4();
  message.loading({ content: loading, key, duration: 0 });
  try {
    await func();
    message.success({ content: success, key, duration: 2 });
  } catch (err) {
    message.destroy(key);
    notification.error({
      message: 'Error',
      description: error,
      duration: 5,
    });
  }
}

export async function feedbackWrapperErrorModal(
  func: Function,
  loading: string,
  success: string,
  error: string,
) {
  const key = uuidv4();
  message.loading({ content: loading, key, duration: 0 });
  try {
    await func();
    message.success({ content: success, key, duration: 2 });
  } catch (err) {
    message.destroy(key);
    const errorMessage = err instanceof RequestError ? await (err as RequestError).getData<string>() : (err as Error).message;
    const text = sanitizeHtml(errorMessage);
    Modal.error({
      width: 500,
      title: error,
      content: <div style={{maxHeight:'300px', overflow:'hidden auto'}}>
        {text}
      </div>,
    });
  }
}
