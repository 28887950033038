import { IField } from "@rapid/data-model";
import { IQueryBuilderSQL } from "@rapid/data-model/lib/query-builder";
import { useCurrentSite } from "@rapid/sdk";
import { ICustomView } from "../components/dataTypes";
import { IAdaptiveView } from "../features/AdaptiveViewsList/adaptiveViewsList";
import { findTable } from "./adaptiveViewColumns";
import { useListFields } from "./ListFields";

export function useSiteViews() {
  const { getListFields } = useListFields();
  const [site] = useCurrentSite();

  function getSiteViews() {
    const views: ICustomView[] = [];
  
    for (const list of site?.Lists!) {
      const sources = site?.DataSources![list.Table];
      const listFields = getListFields(list);
      if (sources?.length) {
        for (const ds of sources) {
          const name = `${list.ListNameSingular} (${ds.Title})`;
          const fields = ds.Columns.map(c => c.ColumnName);
          views.push({
            Name: name,
            List: list.ListName,
            View: ds.Title,
            Table: list.Table,
            Fields: listFields?.filter(f => {
              return fields.includes(f.ColumnName);
            }),
          });
        }
      }
    }
    return views;
  }

  function getAdaptiveFields(query: IQueryBuilderSQL): IField[] {
    const fields: IField[] = [];

    for (let select of query.select) {
      if (typeof select !== 'string') break;
      const str = select.split('.');
      if (str.length !== 2) break;
      const [tableOrAlias, column] = str;
      const field = getListFields(findTable(tableOrAlias, query))?.find(f => f.ColumnName === column);
      if (!!field) fields.push({
        ...field,
        ColumnName: select.replace('.', '$'),
      });
    }

    return fields;
  }

  function getAdaptiveViews(views: IAdaptiveView[]) {
    const customViews: ICustomView[] = [];

    for (const view of views) {
      customViews.push({
        Name: view.title,
        List: '__adaptive_view',
        View: view.view_name,
        Table: '__adaptive_view',
        Fields: getAdaptiveFields(view.schema).filter(f => !!f),
      });
    }

    return customViews;
  }

  return { getSiteViews, getAdaptiveViews };
}