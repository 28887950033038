import { IFormElement } from '@rapid/adaptive-forms';
import { IQueryBuilderSQL } from '@rapid/data-model/lib/query-builder';
import { QBOdataVisitor } from '@rapid/odata';
import { ODataNode } from '@rapid/odata-parser';
import { RuleGroupType, RuleType } from 'react-querybuilder';
import { v4 as uuidv4 } from 'uuid';
import { QueryGroupRemovable, QueryGroup, QueryRule } from '../layouts/base-layout';
import { SQLQuery } from '../layouts/sql-layout';
import {
  IQueryBuilderType,
} from '../QueryBuilder';
import { SQLQueryParser } from './sql-query-parser';

export function queryParse(
  type: IQueryBuilderType,
  _data?: RuleGroupType | IQueryBuilderSQL | ODataNode,
): [form: IFormElement, data: Record<string, any>] {
  if (!_data)
    return [
      type === IQueryBuilderType.Basic ? QueryGroup() : SQLQuery(),
      {},
    ];

  if (
    type === IQueryBuilderType.SQL &&
    (_data as IQueryBuilderSQL).from !== undefined
  ) {
    return new SQLQueryParser(_data as IQueryBuilderSQL).getFormData();
  }

  if ((_data as ODataNode).type !== undefined) {
    const ruleGroup = new QBOdataVisitor().visit(_data as ODataNode);
    const [id, form, data] = parseRuleGroup(ruleGroup as RuleGroupType);
    return [form, data];
  }

  const [id, form, data] = parseRuleGroup(_data as RuleGroupType);
  return [form, data];
}

export function parseRule(
  rule: RuleType,
): [id: string, form: IFormElement, data: Record<string, any>] {
  const uuid = uuidv4();
  const ruleId = `QueryRule:~:${uuid}`;

  const data: Record<string, any> = {};
  data[`Operator:~:${uuid}`] = rule.operator;
  data[`lValue:~:${uuid}`] = rule.field;
  data[`rValue:~:${uuid}`] = rule.value;

  return [ruleId, QueryRule(uuid), data];
}

export function parseRuleGroup(
  rule: RuleGroupType | RuleType,
  depth: number = 0,
): [id: string, form: IFormElement, data: Record<string, any>] {
  const uuid = uuidv4();
  const groupId = `QueryGroup:~:${uuid}`;
  const form = depth === 0 ? QueryGroup(uuid) : QueryGroupRemovable(uuid);

  const data: Record<string, any> = {};
  data[groupId] = {};

  if ((rule as RuleGroupType).combinator !== undefined) {
    data[groupId][`RadioAndOr:~:${uuid}`] = (rule as RuleGroupType).combinator;

    for (const r of (rule as RuleGroupType).rules) {
      const [ruleId, ruleForm, ruleData] =
        (r as RuleType).value !== undefined
          ? parseRule(r as RuleType)
          : parseRuleGroup(r as RuleGroupType, depth + 1);
      form.$children?.push(ruleForm);
      data[groupId][ruleId] =
        (r as RuleType).value !== undefined ? ruleData : ruleData[ruleId];
    }
  } else {
    data[groupId][`RadioAndOr:~:${uuid}`] = 'and';
    const [ruleId, ruleForm, ruleData] = parseRule(rule as RuleType);
    form.$children?.push(ruleForm);
    data[groupId][ruleId] = ruleData;
  }

  return [groupId, form, data];
}