import {
  Updater,
  useCurrentSiteEndpoint,
  useImmer,
  usePowerBIContext,
} from '@rapid/sdk';
import React, { useContext, useEffect } from 'react';
import { useParams } from 'react-router';
import { PowerBI, Xmla } from '@rapid/data-model';
import { IDatasetParams } from '../../components/dataTypes';
import { notification } from 'antd';

export interface IPBIDatasetContext {
  pbiDataset: PowerBI.Dataset;
  dataset: Xmla.IXmlaDataset;
  loading?: boolean;
  fetchDataset(): Promise<void>;
  deleteDataset(): Promise<void>;
}

type IPBIDatasetImmer = Omit<
  IPBIDatasetContext,
  'pbiDataset' | 'fetchDataset' | 'deleteDataset'
>;

const pbiDatasetContext = React.createContext<
  [IPBIDatasetContext, Updater<IPBIDatasetImmer>]
>({} as any);

export function PBIDatasetContext({ children }: React.PropsWithChildren<{}>) {
  const ep = useCurrentSiteEndpoint();
  const { datasetId } = useParams<IDatasetParams>();
  const [pbiContext] = usePowerBIContext();
  const pbiDataset = pbiContext.datasets[datasetId];

  async function fetchDataset() {
    updateContext(draft => {
      draft.loading = true;
    });
    try {
      const res = await ep.Xmla.Datasets[pbiDataset.name].get();

      if (res.ok) {
        const data = await res.json();
        updateContext(draft => {
          draft.dataset = data.dataset as Xmla.IXmlaDataset;
        });
      }
    } catch (err) {
      notification.error({
        message: 'Error',
        description: 'Unable to get dataset',
        duration: 5,
      });
    } finally {
      updateContext(draft => {
        draft.loading = false;
      });
    }
  }

  async function deleteDataset() {
    updateContext(draft => {
      draft.loading = true;
    });
    try {
      await ep.Xmla.Datasets[pbiDataset.name].delete();
    } finally {
      updateContext(draft => {
        draft.loading = false;
      });
    }
  }

  const [context, updateContext] = useImmer<IPBIDatasetImmer>({
    loading: false,
    dataset: {} as Xmla.IXmlaDataset,
  });

  useEffect(
    function fetchTablesEffect() {
      if (!!pbiDataset?.name) {
        fetchDataset();
      }
    },
    [pbiDataset],
  );

  return (
    <>
      <pbiDatasetContext.Provider
        value={[
          { ...context, pbiDataset, fetchDataset, deleteDataset },
          updateContext,
        ]}
      >
        {children}
      </pbiDatasetContext.Provider>
    </>
  );
}

export function usePBIDatasetContext(): [
  IPBIDatasetContext,
  Updater<IPBIDatasetImmer>,
] {
  const [context, updateContext] = useContext(pbiDatasetContext);
  return [context, updateContext];
}
