import { JSX_TYPES } from '@babel/types';
import { ODataResponse, useCurrentSiteEndpoint } from '@rapid/sdk';
import { Card, Popconfirm } from 'antd';
import { useEffect, useState } from 'react';
import { IDashboardItem } from '../dashboard';

interface DashboardCardBarProps {
  item: IDashboardItem;
  index: number;
  onSelected(): void;
  onDelete(): void;
}

export function DashboardBarCard({
  item,
  index,
  onDelete,
  onSelected,
}: React.PropsWithRef<DashboardCardBarProps>) {
  const ep = useCurrentSiteEndpoint();
  const [info, setInfo] = useState<React.ReactNode>();

  async function getItemCount(
    item: IDashboardItem,
  ): Promise<ODataResponse<number>> {
    const data: ODataResponse<number> = await ep.Lists[item.list!][
      item.view ?? 'All'
    ].Items.$count.getJson();
    return data;
  }

  async function getAdaptiveViewCount(
    viewName: string
  ) {
    const res = await ep.Views[viewName].$count.get();

    if (res.ok) {
      return await res.text();
    }
  }

  useEffect(
    function onMountEffect() {
      if (!!item.list) {
        getItemCount(item).then(info => setInfo(info as unknown as string));
      } else if (!!item.adaptiveView) {
        getAdaptiveViewCount(item.adaptiveView).then(info => !!info ? setInfo(info) : <i className="fal fa-eye" />);
      } else {
        setInfo(<i className="fak fa-powerbi" />);
      }
    },
    [item],
  );

  return (
    <Card
      key={`dashboard_card_${index}`}
      hoverable
      extra={
        <Popconfirm
          title={`Delete ${item.title}`}
          onConfirm={ev => {
            ev?.stopPropagation();
            onDelete();
          }}
          onCancel={ev => ev?.stopPropagation()}
          okText="Delete"
          okButtonProps={{ danger: true }}
          cancelText="No"
        >
          <button
            title="Delete"
            className="delete-button"
            onClick={ev => ev.stopPropagation()}
          >
            <i className="fal fa-trash-alt" />
          </button>
        </Popconfirm>
      }
      onClick={ev => {
        ev.stopPropagation();
        onSelected();
      }}
    >
      <div className="card-info">{info}</div>
      <div title={item.title} className="card-title">{item.title}</div>
    </Card>
  );
}
