import React from "react";
import { Button } from "./button";
import { Icon } from "./icon";
/**
 * Icon Button
 *
 * Takes the Icon component and wraps it in a button component.
 *
 * Useable props
 *
 * - **onClick** | _required_ Passes a function straight through tot he onClick action of the button
 * - **iconName** | the name of the Fontawesome or RAPID icon to display. [_defaults to RAPID Logo_]  for more information see [iconName documentation](https://design.rapidplatform.com.au/?path=/story/elements-icon--icon-name "RAPID Icon Name Documentation")
 * - **iconStyle** | The style for [Fontawesome](https://fontawesome.com/icons?d=gallery "Fontawesome Icon Search") Icons - for more information see [iconStyle documentation](https://design.rapidplatform.com.au/?path=/story/elements-icon--icon-style "RAPID Icon Style Dcumentation")
 * - **iconRotation** | The icon rotation (_This works for both Fontawesome & RAPID icons_) for more information see [iconRotation documentation](https://design.rapidplatform.com.au/?path=/story/elements-icon--icon-rotation "RAPID Icon Rotation Documentation")
 * - **iconFlip** | The icon flip (_This works for both Fontawesome & RAPID icons_) for more information see [iconFlip documentaion](https://design.rapidplatform.com.au/?path=/story/elements-icon--icon-flip "RAPID Icon Flip Documentation")
 * - **iconAlignment** | The alignment of the icon within the button for more information see [iconAlignment documentation](https://design.rapidplatform.com.au/?path=/story/components-iconbutton--default "RAPID Icon Alignment documentation") TODO Link this to the specific story. Currenlty doesnt exist
 * - **fixedWidth** | Fontawesome fixed width class (_also works with RAPID icons_) for more information see [fixedWidth documentation](https://design.rapidplatform.com.au/?path=/story/elements-icon--icon-fixed-width "RAPID Fixed Width icon Documentation")
 * - **buttonSize** | Adjusts the button sizing i.e. large, small. for more information see [buttonSize documentation](TODO Link this up when we have stories)
 * - **buttonStyle** | Adjusts the style of the button. i.e. primary, danger, text. for more information see [buttonStyle documentation](TODO link up the stories here)
 * - **isLogo** | Set's the icon as the header logo
 * - **fullWidth** | Sets the button to take up the full width of it's parent. for more information see [fullWidth documentation](TODO link up the storties)
 * - **isDisabled** | When true will render and show the item as disabled
 */
export function IconButton({ onClick, children, iconName, iconStyle, iconRotation, iconFlip, iconAlignment, fixedWidth, buttonSize, buttonStyle, fullWidth, isDisabled, isLogo, margin, className, }) {
    className += ` rp-icon-button${!!children ? " rp-icon-button-label" : ""}${!!iconAlignment ? ` rp-icon-${iconAlignment}` : ""}${isLogo ? ` rp-logo-button` : ""}`;
    if (Array.isArray(margin)) {
        for (let i = 0; i < margin.length; i++) {
            className += ` rp-margin-${margin[i]}`;
        }
    }
    else if (margin) {
        className += ` rp-margin-${margin}`;
    }
    return (React.createElement("div", { className: className },
        React.createElement(Button, { isDisabled: isDisabled, onClick: (event) => onClick === null || onClick === void 0 ? void 0 : onClick(event), buttonSize: buttonSize, buttonStyle: buttonStyle, fullWidth: fullWidth },
            React.createElement(Icon, { iconName: iconName, iconStyle: iconStyle, iconRotation: iconRotation, iconFlip: iconFlip, fixedWidth: fixedWidth }),
            !!children && React.createElement("span", { className: "rp-icon-button-text" }, children))));
}
