import { usePowerBIContext, useViewContext } from '@rapid/sdk';

export function useBreadcrumbText() {
  const [pbiContext] = usePowerBIContext();
  const [view] = useViewContext();

  function getBreadcrumbText(path: string): string | undefined {
    const params = path.split(':');
    if (params.length === 1) {
      switch (path) {
        case 'pbireports':
          return 'PBI Reports';
        case 'pbidatasets':
          return 'PBI Datasets';
        case 'adaptiveviews':
          return 'Adaptive Views';
        case 'dashboards':
          return 'Dashboards';
      }
    }
    if (params.length === 2) {
      switch (params[0]) {
        case 'pbireport':
          return pbiContext.reports[params[1]]?.name ?? undefined;
        case 'newreport':
          return 'new report';
        case 'pbidataset':
          return pbiContext.datasets[params[1]]?.name ?? undefined;
        case 'dashboard':
          return view.items[params[1]]?.title ?? undefined;
      }
    }
    if (params.length === 3) {
      switch (params[0]) {
        case 'customview':
          return `${params[1]} (${params[2]})`;
      }
    }
    return undefined;
  }

  return getBreadcrumbText;
}
