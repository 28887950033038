import { AnyListItem, Xmla } from '@rapid/data-model';
import { ODataResponse, useCurrentSite, useCurrentSiteEndpoint } from '@rapid/sdk';
import {
  IEnvTenantSiteParams, usePowerBIContext
} from '@rapid/sdk/lib/rapid-application';
import { Button, Dropdown, Menu, Popconfirm } from 'antd';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { CommandBar, CommandBarCenter, CommandBarLeft, CommandBarRight } from '../../components/commandbar/commandBar';
import { ICustomView } from '../../components/dataTypes';
import { Breadcrumbs } from '../../sdk/breadcrumbs/breadcrumbs';
import { useBreadcrumbText } from '../../utils/breadcrumbText';
import {feedbackWrapper, feedbackWrapperErrorModal} from '../../utils/feedbackWrapper';
import { useNavigation } from '../../utils/Navigation';
import { useSiteViews } from '../../utils/siteViews';
import { IAdaptiveView } from '../AdaptiveViewsList/adaptiveViewsList';
import { PBIDatasetRelationships } from './cards/relationshipCard';
import SkeletonCard from './cards/skeletonCard';
import { PBIDatasetTables } from './cards/tableCards';
import { usePBIDatasetContext } from './datasetContext';
import AddTableForm from './forms/addTableForm';

export function PBIDataset() {
  const params = useParams<IEnvTenantSiteParams>();
  const ep = useCurrentSiteEndpoint();
  const [site] = useCurrentSite();
  const [pbiContext] = usePowerBIContext();
  const { reports } = pbiContext;
  const [pbiDatasetContext, updatePBIDatasetContext] = usePBIDatasetContext();
  const { pbiDataset, dataset, fetchDataset, deleteDataset } =
    pbiDatasetContext;
  const [reportsMenu, setReportsMenu] = useState<JSX.Element>();
  const { Navigate, BaseNavigate } = useNavigation();
  const breadcrumbText = useBreadcrumbText();
  const { getSiteViews, getAdaptiveViews } = useSiteViews();

  const [updateDatasourceCredentials, setUpdateDatasourceCredentials] = useState<boolean>(false);
  const [addTableVisible, setAddDataVisible] = useState<boolean>(false);
  const [views, setViews] = useState<ICustomView[]>(getSiteViews());

  async function getAdaptiveViewList() {
    try {
      const data: ODataResponse<AnyListItem[]> = await ep.Lists[
        'Adaptive Views'
      ]['All$'].Items.getJson();      

      setViews([
        ...getSiteViews(),
        ...getAdaptiveViews(data.value as IAdaptiveView[]),
      ])
    } catch (err) { console.log(err)}
  } 

  async function onSubmit(table: Xmla.IXmlaTable) {
    setUpdateDatasourceCredentials(true);
    setAddDataVisible(false);
    updatePBIDatasetContext(d => {
      d.dataset.tables.push(table);
    });
  }

  async function refreshDataset() {
    setUpdateDatasourceCredentials(false);
    feedbackWrapper(
      fetchDataset,
      `Refreshing ${dataset.name}...`,
      `${dataset.name} refreshed!`,
      `Unable to refresh ${dataset.name}.`,
    );
  }

  async function undoChanges() {
    setUpdateDatasourceCredentials(false);
    feedbackWrapper(
      fetchDataset,
      `Undoing changes...`,
      `Done!`,
      `Unable to undo changes.`,
    );
  }

  async function saveChanges() {
    feedbackWrapperErrorModal(
      () => ep.Xmla.Datasets[dataset.name].put({}, { dataset }),
      `Saving ${dataset.name}...`,
      `${dataset.name} saved!`,
      `Unable to save ${dataset.name}.`,
    ).then(() => {
      if (updateDatasourceCredentials)
        updateCredentails();
    });
    
    setUpdateDatasourceCredentials(false);
  }

  function removeDataset() {
    feedbackWrapper(
      () =>
        deleteDataset().then(() => {
          BaseNavigate('/pbidatasets');
        }),
      `Deleting ${dataset.name}...`,
      `${dataset.name} deleted.`,
      `Unable to delete ${dataset.name}.`,
    );
  }

  function updateCredentails() {
    feedbackWrapper(
      () => 
        ep.Reports.Workspace[site?.PowerBIWorkspaceID!][pbiDataset.id].credentials.postJson({}, {
            Tenant: params.tenant,
            Site: params.site,
          }),
      `Updating ${dataset.name} credentials...`,
      `${dataset.name} credentials updated!`,
      `Unable to update ${dataset.name} credentials.`,
    );
  }

  useEffect(
    function updateReportsMenu() {
      if (!!reports && !!dataset) {
        setReportsMenu(
          <Menu>
            <Menu.Item>
              <Button
                style={{ width: '100%' }}
                type="primary"
                onClick={() => Navigate(`/newreport:${pbiDataset?.id}`)}
              >
                New Report
              </Button>
            </Menu.Item>
            {Array.from(reports)
              .filter(report => report.datasetId === pbiDataset?.id)
              .map(report => (
                <Menu.Item key={report.id}>
                  <a onClick={() => Navigate(`/pbireport:${report.id}`)}>
                    {report.name}
                  </a>
                </Menu.Item>
              ))}
          </Menu>,
        );
      }
    },
    [dataset, reports],
  );

  useEffect(function onMountEffect() {
    getAdaptiveViewList();
  }, []);

  return (
    <>
      <CommandBar>
        <CommandBarLeft>
          <Breadcrumbs breadcrumbText={breadcrumbText} />
        </CommandBarLeft>
        <CommandBarCenter></CommandBarCenter>
        <CommandBarRight>
          {!pbiDatasetContext.loading && dataset.tables !== undefined &&
          <>
            <button
              title="Update Credentials"
              className="button default margin-horizontal-small"
              onClick={() => updateCredentails()}
            >
              <i className="fal fa-key" />
            </button>
            {!!reportsMenu && (
              <Dropdown overlay={reportsMenu} placement="bottomCenter" arrow>
                <button
                  title="Reports"
                  className="button default margin-horizontal-small"
                  onClick={ev => ev.stopPropagation()}
                >
                  Reports
                </button>
              </Dropdown>
            )}
            <button
              title="Add Table"
              className="button default margin-horizontal-small"
              onClick={() => setAddDataVisible(true)}
            >
              <i className="fal fa-plus" />
              {' Add Table'}
            </button>
            <button
              title="Refresh"
              className="button default margin-horizontal-small"
              onClick={refreshDataset}
            >
              <i className="fal fa-sync" />
            </button>
            <button
              title="Undo"
              className="button default margin-horizontal-small"
              onClick={() => undoChanges()}
            >
              <i className="fal fa-undo" />
            </button>
            <button
              title="Save"
              className="button default margin-horizontal-small"
              onClick={() => saveChanges()}
            >
              <i className="fal fa-save" />
            </button>
            <div style={{ width: '20px' }}></div>
            <Popconfirm
              title={`Delete ${dataset.name}`}
              onConfirm={e => {
                e?.stopPropagation();
                removeDataset();
              }}
              onCancel={e => e?.stopPropagation()}
              okText="Delete"
              okButtonProps={{ danger: true }}
              cancelText="No"
              placement="bottomRight"
            >
              <button
                title="Delete"
                className="button delete margin-horizontal-small"
                onClick={ev => ev.stopPropagation()}
              >
                <i className="fal fa-trash-alt" />
              </button>
            </Popconfirm>
          </>}
        </CommandBarRight>
      </CommandBar>
      <div className="content">
        <div className="card-table">
          {!pbiDatasetContext.loading && dataset.tables?.length === 0 ? (
            <p>
              There are no tables in this dataset.
              <a onClick={() => setAddDataVisible(true)}>{' Add a table'}</a> to get
              started.
            </p>
          ) : pbiDatasetContext.loading ? (<SkeletonCard/>) : (
            <>
            <PBIDatasetTables views={views} />
            {dataset.tables && dataset.tables.length >= 2 && (
              <PBIDatasetRelationships />
            )}
            </>
          )}
        </div>
      </div>
      {views &&
      <AddTableForm
        visible={addTableVisible}
        views={views}
        tables={dataset.tables?.map(table => table.name) ?? []}
        onSubmit={values => onSubmit(values)}
        onCancel={() => setAddDataVisible(false)}
      />}
    </>
  );
}
