import {
  useCurrentSite,
  useCurrentSiteEndpoint,
  usePowerBIDataset,
} from '@rapid/sdk';
import { models, Embed } from 'powerbi-client';
import { PowerBIEmbed } from 'powerbi-client-react';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { IDatasetParams } from '../dataTypes';
import { PowerBI } from '@rapid/data-model';
export interface IEmbedNewPBIReportProps {
  datasetId?: string;
}

export function EmbedNewPBIReport(
  props: React.PropsWithRef<IEmbedNewPBIReportProps>,
) {
  const [site] = useCurrentSite();
  const ep = useCurrentSiteEndpoint();
  const [embedConfig, updateEmbedConfig] =
    useState<models.IReportEmbedConfiguration>();
  const [embedCreateConfig, updateCreateEmbedConfig] =
    useState<models.IReportCreateConfiguration>();
  const params = useParams<IDatasetParams>();
  const [dataset] = usePowerBIDataset(props.datasetId ?? params.datasetId);
  const [embeddedComponent, setEmbeddedComponent] = useState<Embed>();

  // Change to ENV variable
  const workspaceId = '15d2baa6-4d78-40ca-a883-36486d4e4890';
  const reportId = 'fcd9cb1d-1e66-4a9e-886e-4fa035c6a861';

  async function fetchEmbedToken(): Promise<PowerBI.EmbedToken> {
    const embedToken: PowerBI.EmbedToken = await ep.Reports.Embed.getJson({
      reportId: reportId,
      workspaceId: workspaceId,
      viewMode: 'Edit',
      allowSaveAs: true,
    });

    return embedToken;
  }

  async function fetchCreateEmbedToken(): Promise<PowerBI.EmbedToken> {
    const embedToken: PowerBI.EmbedToken = await ep.Reports.Datasets.Embed.getJson({
      workspaceId: site?.PowerBIWorkspaceID,
      datasetId: dataset.id,
      viewMode: 'Create',
      allowSaveAs: true,
    });

    return embedToken;
  }

  useEffect(function getEmbedToken() {
    if (!!dataset) {
      fetchEmbedToken().then(embedToken => {
        const embedConfig: models.IReportEmbedConfiguration = {
          type: 'report',
          id: reportId,
          tokenType: models.TokenType.Embed,
          accessToken: embedToken.token,
          permissions: models.Permissions.All,
          viewMode: models.ViewMode.Edit,
        };
        updateEmbedConfig(embedConfig);
      });
    }
  }, [dataset]);

  useEffect(function getCreateEmbedToken() {
    if (!!embedConfig && !!site) {
      fetchCreateEmbedToken().then(embedToken => {
        const createConfig: models.IReportCreateConfiguration = {
          type: 'create',
          accessToken: embedToken.token,
          datasetId: dataset.id,
          groupId: site.PowerBIWorkspaceID,
          tokenType: models.TokenType.Embed,
          embedUrl: dataset.createReportEmbedURL,
        };
        updateCreateEmbedConfig(createConfig);
      });
    }
  }, [embedConfig, dataset, site]);

  useEffect(function changeEmbedToCreate() {
    if (!!embedCreateConfig && !!embeddedComponent) {
      embeddedComponent.service.createReport(
        embeddedComponent.element,
        embedCreateConfig,
      );
    }
  }, [embedCreateConfig, embeddedComponent]);

  return !!embedConfig?.accessToken ? (
    <PowerBIEmbed
      embedConfig={embedConfig}
      getEmbeddedComponent={embeddedComponent =>
        setEmbeddedComponent(embeddedComponent)
      }
      cssClassName="embedded-report"
    />
  ): (<></>);
}
