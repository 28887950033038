import { ChoiceField, DateTimeField, NumberField, TextField } from '@rapid/adaptive-forms';
import { IChoice, IField } from '@rapid/data-model';
import { Switch } from 'antd';

interface IFieldTypeInputProps {
  field: IField;
  value?: string | number | boolean | Date;
  label?: string;
  id: string;
  placeholder?: string;
  disabled?: boolean;
  onChange?(value: string | number | boolean | Date): void;
}

export function FieldTypeInput({
  field,
  value,
  label,
  id,
  disabled,
  onChange,
}: IFieldTypeInputProps) {
  switch (field.FieldType) {
    case 'Boolean':
      return (
        <Switch
          size="small"
          defaultChecked={!!value}
          onChange={value => onChange?.(!value)}
        />
      );
    case 'DateTime':
      const date =
        value instanceof Date ? (value as Date) : new Date(value as string);
      return (
        <DateTimeField
          $type="Input.DateTimeField"
          label={label}
          value={
            date instanceof Date && !isNaN(date.valueOf()) ? date : undefined
          }
          onChange={e =>
            onChange?.(
              e instanceof Date ? (e as Date).toString() : (e as string),
            )
          }
          disabled={disabled}
          id={id}
          size='small'
        />
      );
    case 'Choice':
      const choices = field.Settings?.Choices?.map(choice =>
        typeof choice === 'string'
          ? ({ Text: choice } as IChoice)
          : (choice as IChoice),
      );
      return (
        <ChoiceField
          $type="Input.Choice"
          label={label}
          id={id}
          value={
            choices?.find(c => c.Text === (value as string)) &&
            typeof value === 'string'
              ? (value as string)
              : undefined
          }
          disabled={disabled}
          choices={choices ?? []}
          onChange={e => {
            onChange?.(e);
          }}
          size='small'
        />
      );
    case 'Currency':
    case 'Integer':
    case 'Number':
    case 'Percentage':
      return <NumberField 
        $type={field.FieldType === 'Currency' || field.FieldType === 'Percentage' ? `Input.${field.FieldType}` : 'Input.Number'}
        label={label}
        id={id}
        value={typeof value === 'number' ? (value as number) : undefined}
        disabled={disabled}
        size='small'
        onChange={e => {
          onChange?.(e);
        }}
      />
    case 'Text':
    case 'Note':
    case 'Computed':
    case 'Report':
    case 'Subquery':
    case 'Email':
    case 'XML':
    case 'GUID':
    case 'JSON':
    case 'Raw':
    case 'File':
    case 'Outcome':
    case 'User':
    case 'Lookup':
    case 'MultiLookup':
    case 'MultiChoice':
    case 'PrivateUsers':
    case 'Users':
    default:
      return (
        <TextField 
          $type="Input.Text"
          label={label}
          id={id}
          value={typeof value === 'string' ? value as string : undefined}
          disabled={disabled}
          onChange={e => {
            onChange?.(e);
          }}
          size='small'
        />
      );
  }
}
