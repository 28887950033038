import { useState } from 'react';
import { Xmla } from '@rapid/data-model';

export interface IPBIRelationshipProps {
  relationship: Xmla.IXmlaRelationship;
  onDeleteClicked(): void;
}

export function PBIRelationship(
  props: React.PropsWithRef<IPBIRelationshipProps>,
) {
  const [hover, useHover] = useState(false);
  const { relationship } = props;
  const name = isGUID(relationship.name) ? 
    `${relationship.fromTable}[${relationship.fromColumn}] - ${relationship.toTable}[${relationship.toColumn}]` 
    : relationship.name;

  function isGUID(str: string) {
    var match = str.match(/^[0-9A-F]{8}-[0-9A-F]{4}-[0-9A-F]{4}-[0-9A-F]{4}-[0-9A-F]{12}$/);
    return match !== null;
  }

  function Hover(bool: boolean) {
    useHover(bool);
  }
  
  return (
    <div
      className="column"
      onMouseOver={() => Hover(true)}
      onMouseLeave={() => Hover(false)}
    >
      <div className="column-icon">
        <i className={'fal fa-arrows-h'} />
      </div>
      <div title={name} className="column-text">
        {name}
      </div>
      {hover && (
        <div className="column-delete">
          <button
            title="Delete"
            className="delete-button"
            onClick={props.onDeleteClicked}
          >
            <i className="fal fa-trash-alt" />
          </button>
        </div>
      )}
    </div>
  );
}
