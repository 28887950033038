import {
  ManagedListItemContext,
  ManagedViewContext,
  ODataResponse,
  useCurrentSiteEndpoint,
  useViewContext,
  useViewContextListItemArray,
} from '@rapid/sdk';
import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { IDashboard } from '../dashboardsList/dashboardsList';
import { models } from 'powerbi-client';
import AddDashboardCardForm from './forms/addDashboardCardForm';
import { IDashboardParams } from '../../components/dataTypes';
import { feedbackWrapper } from '../../utils/feedbackWrapper';
import {
  CommandBar,
  CommandBarCenter,
  CommandBarLeft,
  CommandBarRight,
} from '../../components/commandbar/commandBar';
import { Breadcrumbs } from '../../sdk/breadcrumbs/breadcrumbs';
import { useBreadcrumbText } from '../../utils/breadcrumbText';
import { EmbedPBIReport } from '../../components/powerBIReport/embedPBIReport';
import { ManagedViewContextTable } from '../../components/dataTable/ManagedViewContextTable';
import { DashboardBarCard } from './cards/dashboardBarCard';

import './dashboard.Bar.scss';
import { AnyListItem } from '@rapid/data-model';
import { ManagedAdaptiveViewTable } from '../../components/dataTable/ManagedAdaptiveViewTable';

export interface IDashboardSchema {
  items: IDashboardItem[];
}

export interface IDashboardItem {
  id: string;
  title: string;
  reportId?: string;
  list?: string;
  view?: string;
  adaptiveView?: string;
  adaptiveViewId?: string;
}

export interface IDashboardCard extends IDashboardItem {
  info?: JSX.Element;
}

interface currentItem {
  id: string;
  index: number;
}

export function Dashboard() {
  const ep = useCurrentSiteEndpoint();
  const [view, updateView] = useViewContext();
  const { dashboardId } = useParams<IDashboardParams>();
  const dashboard = view.items[dashboardId] as IDashboard;
  const [itemDeleted, setItemDeleted] = useState<boolean>(false);
  const [itemAdded, setItemAdded] = useState<string>();
  const [currentItem, setCurrentItem] = useState<currentItem>({
    id: '-1',
    index: -1
  });
  const [addDashboardCardVisible, setAddDashboardCardVisible] =
    useState<boolean>(false);

  const [adaptiveViews, setAdaptiveViews] = useState<AnyListItem[]>([]);

  async function getAdaptiveViews() {
    try {
      const data: ODataResponse<AnyListItem[]> = await ep.Lists[
        'Adaptive Views'
      ]['All$'].Items.getJson();

      setAdaptiveViews(data.value);
    } catch (err) {}
  }
  

  function deleteItem(index: number) {
    updateView(d => {
      d.items[dashboardId].schema?.items?.splice(index, 1);
    });
    setItemDeleted(true);
  }

  async function onSave() {
    feedbackWrapper(
      () =>
        ep.Lists['Adaptive Dashboards'][''].Items[dashboardId].putJson(
          {},
          view.items[dashboardId],
        ),
      `Saving ${dashboard.title}...`,
      `${dashboard.title} saved!`,
      `Unable to save ${dashboard.title}.`,
    );
  }

  async function onSubmit(item: IDashboardItem) {
    setAddDashboardCardVisible(false);
    updateView(d => {
      d.items[dashboardId].schema?.items?.push(item);
    });
    setItemAdded(item.id);
  }

  useEffect(
    function itemDeletedEffect() {
      if (itemDeleted) {
        if (dashboard.schema?.items?.length) {
          const index = dashboard.schema.items.findIndex(i => i.id === currentItem.id);
          if (index === -1) {
            setCurrentItem({
              id: dashboard.schema.items[0].id,
              index: 0
            });
          }
        } else {
          setCurrentItem({ 
            id: '-1',
            index: -1
          });
        }
        setItemDeleted(false);
      }
    },
    [itemDeleted, dashboard],
  );

  useEffect(
    function itemAddedEffect() {
      if (itemAdded && dashboard.schema?.items?.length) {
        const index = dashboard.schema.items.length - 1;
        if (index >= 0) {
          setCurrentItem({
            id: dashboard.schema.items[index].id,
            index: index
          });
        } else {
          setCurrentItem({
            id: '-1',
            index: -1
          });
        }
        setItemAdded(undefined);
      }
    },
    [itemAdded, dashboard]
  );

  useEffect(
    function onDashboardUpdatedEffect() {
      if (
        currentItem.index === -1 &&
        dashboard &&
        dashboard.schema &&
        dashboard.schema?.items &&
        dashboard.schema?.items?.length > 0
      )
        setCurrentItem({
          id: dashboard.schema.items[0].id,
          index: 0
        });
    },
    [dashboard],
  );

  useEffect(function onMountEffect() {
    getAdaptiveViews();
  }, []);

  return (
    <>
      <CommandBar>
        <CommandBarLeft>
          <Breadcrumbs breadcrumbText={useBreadcrumbText()} />
        </CommandBarLeft>
        <CommandBarCenter></CommandBarCenter>
        <CommandBarRight>
          <>
            <button
              title="Add Dashboard"
              className="button default margin-horizontal-small"
              onClick={() => setAddDashboardCardVisible(true)}
            >
              <i className="fal fa-plus" />
            </button>
            <button
              title="Save"
              className="button default margin-horizontal-small"
              onClick={() => onSave()}
            >
              <i className="fal fa-save" />
            </button>
          </>
        </CommandBarRight>
      </CommandBar>
      <div className="content">
        <div className="rp-dashboard-bar">
          {!!dashboard &&
            dashboard.schema?.items?.map((item, index) => (
              <DashboardBarCard
                item={item}
                index={index}
                onSelected={() => setCurrentItem({
                  id: item.id,
                  index: index
                })}
                onDelete={() => deleteItem(index)}
              />
            ))}
        </div>
        {currentItem.index === -1 ? (
          <p>
            &ensp;Dashboard configuration is empty, please
            <a onClick={() => setAddDashboardCardVisible(true)}>
              {' add an item'}
            </a>
            .
          </p>
        ) : !!dashboard.schema?.items[currentItem.index]?.reportId ? (
          <EmbedPBIReport
            viewMode={models.ViewMode.View}
            reportId={dashboard.schema?.items[currentItem.index].reportId}
          />
        ) : !!dashboard.schema?.items[currentItem.index]?.list ? (
          <ManagedViewContext
            list={dashboard.schema?.items[currentItem.index].list!}
            dataSource={dashboard.schema?.items[currentItem.index].view ?? 'All'}
          >
            <ManagedViewContextTable />
          </ManagedViewContext>
        ) : !!dashboard.schema?.items[currentItem.index]?.adaptiveViewId ? (
          <ManagedListItemContext id={dashboard.schema.items[currentItem.index].adaptiveViewId} validate list={'Adaptive Views'}>
            <ManagedAdaptiveViewTable />
          </ManagedListItemContext>
        ) :(
          <></>
        )}
      </div>
      <AddDashboardCardForm
        adaptiveViews={adaptiveViews}
        visible={addDashboardCardVisible}
        onSubmit={card => onSubmit(card)}
        onCancel={() => setAddDashboardCardVisible(false)}
      />
    </>
  );
}
