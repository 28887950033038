import { FormElementInputProps } from '@rapid/adaptive-forms';
import { useComposedClassName } from '@rapid/sdk';
import { Select } from 'antd';
import { useMemo } from 'react';
import {
  useQueryBuilderContext,
  FieldAlias,
  IQueryBuilderType,
} from '../QueryBuilder';

interface IFieldSelectAttribtes {
  multiple: boolean;
}

export function FieldSelect({
  disabled,
  id,
  value,
  label,
  onChange,
  multiple,
}: FormElementInputProps<
  string | string[],
  IFieldSelectAttribtes
>) {
  const { Option } = Select;

  const [context] = useQueryBuilderContext();

  const fieldSelectClass = useComposedClassName(
    function* () {
      yield 'Label';

      multiple ? yield 'FieldSelectMultiple' : yield 'FieldSelect';

      if (disabled) yield 'disabled';
    },
    [disabled],
  );

  const Options = useMemo(() => {
    if (context.type === IQueryBuilderType.Basic) {
      return (context.fieldChoices as string[])
        ?.filter(choice => multiple ? !choice.includes('.id') : 1)
        ?.map(choice => (
          <Option key={choice} value={choice}>
            {choice}
          </Option>
        ));
    }

    return (context.fieldChoices as FieldAlias[])
      ?.filter(choice => multiple ? !choice.column.includes('.id') : 1)
      ?.map(choice => (
        <Option
          key={`${choice.id}.${choice.column}`}   
          value={`${choice.id}.${choice.column}`}
        >
          {`${choice.tableAlias || choice.table}.${choice.column}`}
        </Option>
      ));
  }, [multiple, context.fieldChoices, context.type]);

  return (
    <label className={fieldSelectClass} id={id}>
      {!!label && <span className="Name">{label}</span>}
      <Select
        mode={multiple ? 'multiple' : undefined}
        allowClear
        showSearch
        placeholder="Select Fields"
        value={value}
        onChange={(value) => multiple ? onChange?.(value as string[]): onChange?.(value as string)}
        size="small"
      >
        {Options}
      </Select>
    </label>
  );
}
