import { FormElementInputProps, removeField } from '@rapid/adaptive-forms';
import { useComposedClassName } from '@rapid/sdk';
import { useMemo } from 'react';
import { useQueryBuilderContext } from '../QueryBuilder';
import { FormElementId, getFormElementId, removeData } from '../utils/helpers';

interface IRemoveItemProps {
  itemId: string;
}

export function RemoveItem({
  disabled,
  id,
  label,
  itemId,
}: FormElementInputProps<string, IRemoveItemProps>) {
  const [context, updateContext] = useQueryBuilderContext();

  const removeItemClass = useComposedClassName(
    function* () {
      yield 'Label RemoveItem';

      if (disabled) yield 'disabled';
    },
    [disabled],
  );

  const selectId = useMemo(() => {
    if (context.form && context.form.$children) {
      return context.form.$children.find(c => c.id.includes('Select'))?.id;
    }
  }, [context.form]);

  function removeSelectedFields(elementId: FormElementId, selectId: string): string[] {
    const select = context.data[context.form.id][selectId] as string[];
    return select.filter(s => !s.includes(`Join:~:${elementId.uuid}`));
  }

  const onClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();

    updateContext(d => {
      removeField(itemId, d.form);
      removeData(itemId, d.data);

      const elementId = getFormElementId(itemId);
      if (elementId.type === 'JoinRule' 
        && !!selectId 
        && context.data[context.form.id][selectId]
      ) {
        d.data[context.form.id][selectId] = removeSelectedFields(elementId, selectId);
      }
    });
  };

  return (
    <label className={removeItemClass} id={id}>
      {!!label && <span className="Name">{label}</span>}
      <button
        title="Delete"
        className="delete-button"
        onClick={onClick}
      >
        <i className="fal fa-s fa-trash-alt" />
      </button>
    </label>
  );
}
