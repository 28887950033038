/// <reference types="@microsoft/microsoft-graph-types" />
import React, { useContext, useEffect } from 'react';
import { useImmer } from '../use-immer';
import { useGraphEndpoint } from '../use-graph-endpoint';
import { isRequestError } from '../proxy-rest-client';
const Context = React.createContext([]);
/**
 * Creates a file context that provides access to Microsoft 365 files for
 * the specified folder and path.
 * @category RAPID Applications
 */
export function ManagedFileContext({ driveId, folderId, path, children, }) {
    const graphEndpoint = useGraphEndpoint('Files.ReadWrite');
    const [contextValue, updateContext] = useImmer({
        driveItem: undefined,
    });
    async function fetchFolder() {
        let ep = graphEndpoint.drives[driveId];
        if (folderId) {
            ep = ep.items[folderId + ':'];
        }
        else {
            ep = ep.root + ':';
        }
        ep = ep[path !== null && path !== void 0 ? path : '/'];
        let data;
        let notFound = false;
        try {
            data = await ep.getJson({
                $expand: 'children',
            });
        }
        catch (e) {
            if (isRequestError(e)) {
                notFound = (e === null || e === void 0 ? void 0 : e.statusCode) === 404;
            }
        }
        updateContext(draft => {
            draft.driveItem = data;
            draft.notFound = notFound;
        });
    }
    useEffect(function fetchItemsEffect() {
        fetchFolder();
    }, [folderId, driveId, path]);
    return (React.createElement(Context.Provider, { value: [contextValue, updateContext] }, children));
}
/**
 * Uses the Microsoft 365 file context from the nearest {@link ManagedFileContext}
 *
 * @hook
 * @category RAPID Application
 */
export function useFileContext() {
    const [fileContext, updateFileContext] = useContext(Context);
    return [fileContext, updateFileContext];
}
