import { useState } from 'react';
import { usePBIDatasetContext } from '../datasetContext';
import { Button, Card } from 'antd';
import { TableCardColumn } from './tableCard.Column';
import { Xmla } from '@rapid/data-model';
import AddColumnForm from '../forms/addColumnForm';
import { useSiteViews } from '../../../utils/siteViews';

import './cards.scss';
import { ICustomView } from '../../../components/dataTypes';
import { PropsWithoutRef } from 'react-router/node_modules/@types/react';

interface pbiDatasetTablesProps {
  views: ICustomView[];
}

export function PBIDatasetTables({
  views,
}: React.PropsWithChildren<pbiDatasetTablesProps>) {
  const [pbiDatasetContext, updatePBIDatasetContext] = usePBIDatasetContext();
  const [addColumnVisible, setAddColumnVisible] = useState<boolean>(false);
  const [addColumnTable, setAddColumnTable] = useState<Xmla.IXmlaTable>();

  function onSubmit(column: Xmla.IXmlaColumn) {
    if (!!addColumnTable) {
      addColumn(addColumnTable.name, column);
      setAddColumnVisible(false);
    }
  }

  function addColumn(tableName: string, column: Xmla.IXmlaColumn) {
    updatePBIDatasetContext(d => {
      const tableIndex = d.dataset.tables.findIndex(
        table => table.name === tableName,
      );
      d.dataset.tables[tableIndex].columns.push(column);
    });
  }

  function removeColumn(tableName: string, columnName: string) {
    updatePBIDatasetContext(d => {
      const tableIndex = d.dataset.tables.findIndex(
        table => table.name === tableName,
      );
      const columnIndex = d.dataset.tables[tableIndex!].columns?.findIndex(
        c => c.name === columnName,
      );

      let relationshipsIndex: number[] = [];
      const relationships = d.dataset.relationships;
      for (let i = 0; i < d.dataset.relationships.length; i++) {
        if (
          (relationships[i].fromColumn === columnName &&
            relationships[i].fromTable === tableName) ||
          (relationships[i].toColumn === columnName &&
            relationships[i].toTable === tableName)
        )
          relationshipsIndex.push(i);
      }

      for (const index of relationshipsIndex) {
        d.dataset.relationships.splice(index, 1);
      }

      d.dataset.tables[tableIndex!].columns?.splice(columnIndex!, 1);
    });
  }

  function removeTable(tableName: string) {
    updatePBIDatasetContext(d => {
      const tableIndex = d.dataset.tables.findIndex(
        table => table.name === tableName,
      );

      let relationshipsIndex: number[] = [];
      const relationships = d.dataset.relationships;
      for (let i = 0; i < relationships.length; i++) {
        if (
          relationships[i].fromTable === tableName ||
          relationships[i].toTable === tableName
        )
          relationshipsIndex.push(i);
      }

      for (const i of relationshipsIndex) {
        d.dataset.relationships.splice(i, 1);
      }

      d.dataset.tables.splice(tableIndex!, 1);
    });
  }

  if (!pbiDatasetContext?.dataset.tables) {
    return <></>;
  }

  return (
    <>
      {pbiDatasetContext?.dataset.tables.length >= 1 &&
        pbiDatasetContext?.dataset.tables.map(table => (
          <Card
            key={table.name}
            title={table.name}
            hoverable
            extra={
              <button
                title="Delete"
                className="delete-button"
                onClick={() => removeTable(table.name)}
              >
                <i className="fal fa-trash-alt" />
              </button>
            }
          >
            <div className="table-body">
              <div className="table-columns">
                {table?.columns?.map(column => (
                  <TableCardColumn
                    key={column.name}
                    name={column.name}
                    source={column.source ?? ''}
                    expression={column.expression ?? ''}
                    dataType={column.dataType}
                    onDeleteClicked={() =>
                      removeColumn(table.name, column.name)
                    }
                  />
                ))}
              </div>
              <Button
                className="add-column-button"
                onClick={() => {
                  setAddColumnTable(table);
                  setAddColumnVisible(true);
                }}
              >
                Add Column
              </Button>
            </div>
          </Card>
        ))}
      <AddColumnForm
        visible={addColumnVisible}
        table={addColumnTable}
        views={views}
        onSubmit={column => onSubmit(column)}
        onCancel={() => setAddColumnVisible(false)}
      />
    </>
  );
}
