import { DefaultFields, IField, IList } from "@rapid/data-model";

export const allFields = (list: IList): IField[] => [
  {
    ColumnName: 'id',
    FieldType: 'Number',
    Title: 'Id',
  },
  ...DefaultFields(list),
  ...list.Fields,
];