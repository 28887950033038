import React from "react";
/**
 * Component: MainBreadcrumbs
 *
 * Contains the breadcrumb content of the layout
 */
export function MainBreadcrumbs({ children }) {
    return React.createElement("div", { className: "rp-main-breadcrumbs" }, children);
}
/**
 * Component: MainBody
 *
 * Contains the body content of the layout
 */
export function MainBody({ children }) {
    return React.createElement("div", { className: "rp-main-body" }, children);
}
/**
 * Component: MainMenu
 *
 * Contains the main menu content of the layout
 */
export function MainMenu({ children }) {
    return React.createElement("div", { className: "rp-main-menu" }, children);
}
/**
 * Component: MainSidebar
 *
 * Contains the main sidebar content of the layout
 */
export function MainSidebar({ children }) {
    return React.createElement("div", { className: "rp-main-sidebar" }, children);
}
/**
 * Component: SiteMain
 *
 * The RAPID Platform site-wide main body area.  Contains four sub-components:
 *
 * - MainBreadcrumbs,
 * - MainBody,
 * - MainMenu,
 * - MainSidebar
 */
export function SiteMain({ children }) {
    return React.createElement("div", { className: "rp-site-main" }, children);
}
