import { useMemo } from 'react';
import { useRapidApplication } from './rapid-application/index';
export default function useCurrentConnections() {
    const [app] = useRapidApplication();
    const { tenant, site, env } = app;
    const connections = useMemo(() => {
        var _a, _b;
        return (_b = (_a = app.connections) === null || _a === void 0 ? void 0 : _a.filter(i => {
            var _a, _b, _c;
            return ((_a = i.tenant) === null || _a === void 0 ? void 0 : _a.localeCompare(tenant)) === 0 &&
                ((_b = i.site) === null || _b === void 0 ? void 0 : _b.localeCompare(site)) === 0 &&
                ((_c = i.env) === null || _c === void 0 ? void 0 : _c.localeCompare(env)) === 0;
        })) !== null && _b !== void 0 ? _b : [];
    }, [tenant, site, env]);
    return connections;
}
