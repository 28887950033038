import React from 'react';
import { descriptionTag, fieldAttributesSchemaTag, iconTag, LookupField, titleTag, } from '../index';
export function UserField(props) {
    return React.createElement(LookupField, { ...props });
}
UserField[titleTag] = 'User';
UserField[descriptionTag] = 'RAPID Platform user field';
UserField[iconTag] = 'fa-users';
UserField[fieldAttributesSchemaTag] = [
    {
        $type: 'Input.Text',
        id: 'label',
        label: 'Label',
    },
    {
        $type: 'Input.Text',
        id: 'placeholder',
        label: 'Placeholder',
    },
    {
        $type: 'Input.List',
        id: '$.attributes.lookupList',
        label: 'Lookup List',
        attributes: {
            includeSystemLists: false,
        },
    },
    {
        $type: 'Input.Column',
        id: '$.attributes.lookupField',
        label: 'Lookup Field',
        attributes: {
            targetElementRef: '$.attributes.lookupList',
        },
    },
    {
        $type: 'Input.Boolean',
        id: 'required',
        label: 'Required',
    },
];
