import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { IEnvTenantSiteParams } from '@rapid/sdk';

import './sidebar.scss';

export function Sidebar(props: React.PropsWithChildren<{}>) {
  return <div className="sidebar">{props.children}</div>;
}

export function SidebarHeader(props: React.PropsWithChildren<{}>) {
  return <section className="header">{props.children}</section>;
}

export function SidebarMain(props: React.PropsWithChildren<{}>) {
  return <section className="main">{props.children}</section>;
}

export function SidebarFooter(props: React.PropsWithChildren<{}>) {
  return <section className="footer">{props.children}</section>;
}

export function SidebarHeaderItem(props: React.PropsWithChildren<{}>) {
  const params = useParams<IEnvTenantSiteParams>();
  const baseUrl = `/_${params.env}/${params.tenant}/${params.site}`;

  return <Link to={baseUrl}>{props.children}</Link>;
}
export interface SidebarMenuItemProps {
  name: string;
  icon?: JSX.Element;
  route: string;
}

export function SidebarMenuItem(
  props: React.PropsWithChildren<SidebarMenuItemProps>,
) {
  const params = useParams<IEnvTenantSiteParams>();
  const baseUrl = `/_${params.env}/${params.tenant}/${params.site}`;

  return (
    <Link to={baseUrl + props.route}>
      <div className="menu_item">
        <div className="menu_item_button">
          <div className="menu_item_icon">
            {!!props.icon ? props.icon : <i className="fal fa-circle" />}
          </div>
          <span className="menu_item_label">{props.name}</span>
        </div>
      </div>
    </Link>
  );
}
