import { IEnvTenantSiteParams } from '@rapid/sdk';
import { useHistory, useParams } from 'react-router-dom';

export function useNavigation() {
  const params: IEnvTenantSiteParams = useParams<IEnvTenantSiteParams>();
  const history = useHistory();

  function BaseNavigate(path: string) {
    const url: string = `/_${params.env}/${params.tenant}/${params.site}`;
    history.push(url + (path[0] === '/' ? path : '/' + path));
  }

  function Navigate(path: string) {
    let url = history.location.pathname;
    url = url[url.length - 1] === '/' ? url.substring(0, url.length - 2) : url;
    history.push(url + (path[0] === '/' ? path : '/' + path));
  }

  return { Navigate, BaseNavigate };
}
