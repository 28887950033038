import { IChoice, IField } from '@rapid/data-model';
import { IEnvTenantSiteParams, useViewContext } from '@rapid/sdk';
import { Table, Tag } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import Paragraph from 'antd/lib/typography/Paragraph';
import { ReactNode, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useListFields } from '../../utils/ListFields';
import { useTitleField } from '../../utils/TitleField';
import SkeletonTable from './SkeletonTable';

export interface IManagedViewContextTableProps {
  /** Custom Link to to use on tables title field.  */
  customTitleRender?(): ReactNode;
}

export function ManagedViewContextTable({
  customTitleRender,
}: React.PropsWithoutRef<IManagedViewContextTableProps>) {
  const params = useParams<IEnvTenantSiteParams>();
  const [view] = useViewContext();
  const { getListFields } = useListFields();
  const { getTitleField } = useTitleField();
  const [columns, setColumns] = useState<ColumnsType<{}>>([]);

  function getItemLink(record: any): string | undefined {
    let url: string;

    if (params.env === 'prod') {
      url = 'app.rapidplatform.com';
    } else {
      url = 'test.rapidplatform.com';
    }

    return `https://${url}/${params.tenant}/${params.site}/explorer/${view.list.ListName}/${view.list.ListNameSingular}:${view.list.ListName}:${record.id}`;
  }

  function renderColumn(field: IField): ReactNode {
    if (field.ColumnName === getTitleField(view.list)) {
      return customTitleRender
        ? customTitleRender()
        : (text: string, record: any) => {
            const link = getItemLink(record);
            return link ? (
              <a href={link} target="_blank">
                {text}
              </a>
            ) : !!text ? (
              `${text}`
            ) : null;
          };
    }

    const isLookup = field.FieldType === 'Lookup';
    const textColumn = field.ColumnName.replace('_id', '');

    const fieldType =
      field.FieldType === 'Lookup'
        ? getListFields(field.Settings?.LookupList ?? '')?.find(
            f => f.ColumnName === field.Settings?.LookupField?.replace('_id', ''),
          )?.FieldType
        : field.Settings?.DisplayAs || field.FieldType;

    switch (fieldType) {
      case 'DateTime':
        return (value: string, record: any) => {
          const val = isLookup ? record[textColumn] as string : value;
          if (!val) return null;
          return new Date(val).toLocaleDateString();
        };
      case 'Choice':
        return (value: string, record: any) => {
          const val = isLookup ? record[textColumn] as string : value;
          const choice = field.Settings?.Choices?.find(
            choice => (choice as IChoice).Text === val,
          ) as IChoice;
          return !!choice?.AccentColour ? (
            <Tag color={choice?.AccentColour}>{choice.Text}</Tag>
          ) : (
            isLookup ? record[textColumn] : value
          );
        };
      case 'Boolean':
        return (value: boolean, record: any) => {
          const val = isLookup ? record[textColumn] as boolean : value;
          if (val === true) {
            return (
              <Tag icon={<i className="fal fa-check" />} color="success">
                &ensp;Yes
              </Tag>
            );
          }

          if (val === false) {
            return (
              <Tag icon={<i className="fal fa-times" />} color="error">
                &ensp;No
              </Tag>
            );
          }

          return null;
        };
      case 'JSON':
        return (value: JSON, record: any) => {
          const val = isLookup ? record[textColumn] as JSON : value;
          if (!val) return null;
          const json = JSON.stringify(val);

          return <Paragraph copyable={{
            text: json,
            icon: [<i className='fal fa-copy' key='copy-icon' />, <i className='fal fa-check' key='copied-icon' />]
          }}>
            {json.length > 150 ? `${json.slice(0,140)}...` : json}
          </Paragraph>;
        }
      case 'Percentage':
        return (value: string, record: any) => {
          const val = isLookup ? record[textColumn] as string : value;
          return !!val ? `${val}%` : null;
        } 
      case 'Currency':
        return (value: string, record: any) => {
          const val = isLookup ? record[textColumn] as string : value;
          return !!val ? `${field.Settings?.CurrencyType} ${val}` : null;
        }
      default:
        return (value: string, record: any) => {
          const val = isLookup ? record[textColumn] as string : value;
          return !!val ? `${val}` : null;
        }
    }
  }

  useEffect(
    function setColumnsFromView() {
      if (!!view) {
        const columns: any[] = [];
        const fields = getListFields(view.list)!;
        for (const field of fields) {
          const column = view.dataSource.Columns.find(c => c.ColumnName === field.ColumnName);
          if (!!column) {
            const render = renderColumn(field);
            columns.push({
              key: field.ColumnName,
              title: field.Title,
              dataIndex: field.ColumnName,
              render: render,
              width: column.MaxWidth ?? 150,
              fixed: field.ColumnName === getTitleField(view.list) ? 'left' : null,
            });
          }
        }
        setColumns(columns);
      }
    },
    [view],
  );

  return view.loading ? (
    <SkeletonTable columns={columns} />
  ) : (
    <Table
      columns={columns}
      dataSource={Array.from(view.items)}
      pagination={false}
      bordered={true}
      scroll={{ 
        x: 'max-content' 
      }}
      size="small"
      sticky
      rowKey="uid"
    />
  );
}
