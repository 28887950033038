/**
 * Encodes an object's keys as a URL query string.
 *
 * @example
 * ```ts
 *  const o = {
 *    test: 'yes',
 *    value: 3
 *  };
 *
 *  encodeQueryString(o); // returns "test=yes&value=3"
 * ```
 *
 * @returns an encoded query-string
 * @category Utilities
 */
export function encodeQueryString(o) {
    return Object.entries(o)
        .map(([k, v]) => !!v && `${k}=${v}`)
        .filter(i => !!i)
        .join('&');
}
export function decodeQueryString(o) {
    return o.replace(/^\?*/, '').split('&').reduce((prev, curr) => {
        const [key, val] = curr.split("=").map(decodeURIComponent);
        prev[key] = val;
        return prev;
    }, {});
}
