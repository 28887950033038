import React, { useState } from 'react';
import { Modal, Form, Input, Tabs, Select } from 'antd';
import { IDashboardItem } from '../dashboard';
import { ODataResponse, useCurrentSite, usePowerBIReports } from '@rapid/sdk';
import { AnyListItem, IList } from '@rapid/data-model';
import { v4 as uuidv4 } from 'uuid';

interface AddDashboardCardFormProps {
  adaptiveViews: AnyListItem[];
  visible: boolean;
  onSubmit: (card: IDashboardItem) => void;
  onCancel: () => void;
}

enum tabKeys {
  Report = '1',
  Views = '2',
  AdaptiveView = '3',
}

const AddDashboardCardForm: React.FC<AddDashboardCardFormProps> = ({
  adaptiveViews,
  visible,
  onSubmit,
  onCancel,
}) => {
  const [form] = Form.useForm();
  const { TabPane } = Tabs;
  const { Option } = Select;
  const [site] = useCurrentSite();
  const [reports] = usePowerBIReports();
  const [activeTabKey, setActiveTabKey] = useState<string>('1');
  const [list, setList] = useState<IList<any>>();

  return (
    <Modal
      visible={visible}
      title="Add Dashboard Card"
      okText="Add"
      cancelText="Cancel"
      onCancel={onCancel}
      onOk={() => {
        form
          .validateFields()
          .then(values => {
            let card: IDashboardItem;
            if (activeTabKey === tabKeys.Report) {
              card = {
                id: uuidv4(),
                title: values.title,
                reportId: values.reportId,
              }
            } else if (activeTabKey === tabKeys.Views) {
              card = {
                id: uuidv4(),
                title: values.title,
                list: values.list,
                view: values.view,
              }
            } else {
              card = {
                id: uuidv4(),
                title: values.title,
                adaptiveViewId: values.adaptiveViewId,
                adaptiveView: adaptiveViews?.find(v => v.id === values.adaptiveViewId)?.title
              }
            }
            setList(undefined);
            setActiveTabKey(tabKeys.Report);
            form.resetFields();     
            onSubmit(card);
          })
          .catch();
      }}
    >
      <Form
        form={form}
        layout="vertical"
        name="form-in-modal"
      >
        <Form.Item
          name="title"
          label="Title"
          rules={[{ required: true, message: 'Please enter a title.' }]}
        >
          <Input />
        </Form.Item>
        <Tabs
          activeKey={activeTabKey}
          onChange={activeKey => setActiveTabKey(activeKey)}
        >
          <TabPane tab="PBI Report" key={tabKeys.Report}>
              <Form.Item
                name="reportId"
                label="Select a Report"
                rules={[
                  {
                    required: activeTabKey === tabKeys.Report,
                    message: 'Please select a report.',
                  },
                ]}
              >
                <Select
                  showSearch
                  placeholder="Select a report"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option?.value.toLowerCase().indexOf(input.toLowerCase()) >=
                    0
                  }
                >
                  {Array.from(reports)?.map(report => (
                    <Option key={report.id} value={report.id}>
                      {`${report.name} (${report.reportType})`}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
          </TabPane>
          <TabPane tab="Views" key={tabKeys.Views}>
          <Form.Item
                name="list"
                label="Select a List"
                rules={[
                  {
                    required: activeTabKey === tabKeys.Views,
                    message: 'Please select a list.',
                  },
                ]}
              >
                <Select
                  showSearch
                  style={{ width: 200 }}
                  placeholder="Select a list"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option?.value.toLowerCase().indexOf(input.toLowerCase()) >=
                    0
                  }
                  onChange={(ev) => setList(site?.Lists?.find(list => list.ListName === ev))}
                >
                  {site?.Lists?.map(list => (
                    <Option key={list.ListName} value={list.ListName}>
                      {list.ListName}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              {!!list && !!site && <Form.Item
                name="view"
                label="Select a View"
                rules={[
                  {
                    required: activeTabKey === tabKeys.Views,
                    message: 'Please select a view.',
                  },
                ]}
              >
                <Select
                  showSearch
                  style={{ width: 200 }}
                  placeholder="Select a view"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option?.value.toLowerCase().indexOf(input.toLowerCase()) >=
                    0
                  }
                >
                  {site.DataSources![list.Table].map(datasource => (
                    <Option key={datasource.Title} value={datasource.Title}>
                      {datasource.Title}
                    </Option>
                  ))}
                </Select>
              </Form.Item>}
          </TabPane>
          <TabPane tab="Adaptive Views" key={tabKeys.AdaptiveView}>
              <Form.Item
                name="adaptiveViewId"
                label="Select an Adaptive View"
                rules={[
                  {
                    required: activeTabKey === tabKeys.AdaptiveView,
                    message: 'Please select an adaptive view.',
                  },
                ]}
              >
                <Select
                  showSearch
                  placeholder="Select an Adaptive View"
                >
                  {adaptiveViews.map(view => (
                    <Option key={view.id} value={view.id!}>
                      {view.title}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
          </TabPane>
        </Tabs>
      </Form>
    </Modal>
  );
};

export default AddDashboardCardForm;