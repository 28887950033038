import React from 'react';
import { CommandBar, CommandBarLeft } from '../../components/commandbar/commandBar';
import { Breadcrumbs } from '../../sdk/breadcrumbs/breadcrumbs';
import { EmbedNewPBIReport } from '../../components/powerBIReport/embedCreatePBIReport';
import { useBreadcrumbText } from '../../utils/breadcrumbText';

import './report.scss';

export interface INewPBIReportProps {
  datasetId?: string;
}

export function NewPBIReport(props: React.PropsWithoutRef<INewPBIReportProps>) {
  return (
    <>
      <CommandBar>
        <CommandBarLeft>
          <Breadcrumbs breadcrumbText={useBreadcrumbText()} />
        </CommandBarLeft>
      </CommandBar>
      <div className='content embedded-report'>
        <EmbedNewPBIReport datasetId={props.datasetId} />
      </div>
    </>
  );
}