import { ManagedViewContext, useComposedClassName, useCurrentSite, } from '@rapid/sdk';
import inflection from 'inflection';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { descriptionTag, fieldAttributesSchemaTag, FilteredSelectDropdown, iconTag, LookupSelect, titleTag, useCascadingLookups, } from '../../index';
export function LookupField({ lookupList, lookupField, label, id, disabled, selectedValue, onChange, value, columnName, }) {
    var _a, _b, _c, _d;
    const [site] = useCurrentSite();
    const [dataSource, setDataSource] = useState('All');
    const [searchTerm, setSearchTerm] = useState('');
    const [linkedTo, view] = useCascadingLookups(id);
    const multiLookupFieldClass = useComposedClassName(function* () {
        yield 'Email Label flex column';
        if (disabled)
            yield 'disabled';
    }, [disabled]);
    const valueKey = useComposedClassName(function* () {
        yield 'id';
    }, [lookupField]);
    const filters = (_d = (_c = (_a = site === null || site === void 0 ? void 0 : site.DataSources) === null || _a === void 0 ? void 0 : _a[inflection.singularize(inflection.dasherize((_b = lookupList === null || lookupList === void 0 ? void 0 : lookupList.toLocaleLowerCase()) !== null && _b !== void 0 ? _b : ''))]) === null || _c === void 0 ? void 0 : _c.map(d => d.Title)) !== null && _d !== void 0 ? _d : [];
    if (!!view) {
        filters.unshift(view);
    }
    useEffect(function () {
        if (!!view) {
            setDataSource(view);
        }
        else {
            setDataSource('All');
        }
    }, [view]);
    const onChangeInner = (option) => {
        if (!option) {
            onChange === null || onChange === void 0 ? void 0 : onChange({ value: null, label: '' });
            return;
        }
        onChange === null || onChange === void 0 ? void 0 : onChange({
            value: +option.value,
            columnName: columnName,
            label: option.label,
        });
    };
    return (React.createElement("label", { className: multiLookupFieldClass, id: id },
        !!label && React.createElement("span", { className: "Name" }, label),
        React.createElement(ManagedViewContext, { disableAutoFetch: true, list: lookupList !== null && lookupList !== void 0 ? lookupList : '', dataSource: dataSource === view ? 'All' : dataSource, searchTerm: searchTerm, linkedTo: linkedTo },
            React.createElement(LookupSelect, { id: id, dropdownRender: menu => (React.createElement(FilteredSelectDropdown, { menu: menu, setFilter: setDataSource, filter: dataSource, filters: filters, filtersTitle: "Views" })), onChange: onChangeInner, labelKey: lookupField !== null && lookupField !== void 0 ? lookupField : 'title', valueKey: valueKey, selectedValue: selectedValue, setSearchTerm: setSearchTerm, searchTerm: searchTerm, setFilter: setDataSource, value: value }))));
}
LookupField[titleTag] = 'Lookup';
LookupField[descriptionTag] = 'RAPID Platform lookup field';
LookupField[iconTag] = 'fa-search';
LookupField[fieldAttributesSchemaTag] = [
    {
        $type: 'Input.Text',
        id: 'label',
        label: 'Label',
    },
    {
        $type: 'Input.Text',
        id: 'placeholder',
        label: 'Placeholder',
    },
    {
        $type: 'Input.List',
        id: '$.attributes.lookupList',
        label: 'Lookup List',
        attributes: {
            includeSystemLists: false,
        },
    },
    {
        $type: 'Input.Column',
        id: '$.attributes.lookupField',
        label: 'Lookup Field',
        attributes: {
            targetElementRef: '$.attributes.lookupList',
        },
    },
    {
        $type: 'Input.Boolean',
        id: 'required',
        label: 'Required',
    },
];
