import {
  useCurrentSite,
  useCurrentSiteEndpoint,
  usePowerBIReport,
} from '@rapid/sdk';
import { models, IReportEmbedConfiguration, Embed } from 'powerbi-client';
import { PowerBIEmbed } from 'powerbi-client-react';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { IReportParams } from '../dataTypes';
import { PowerBI } from '@rapid/data-model';

export interface IEmbedPBIReportProps {
  viewMode: models.ViewMode;
  reportId?: string;
}

export function EmbedPBIReport(props: React.PropsWithoutRef<IEmbedPBIReportProps>) {
  const [site] = useCurrentSite();
  const ep = useCurrentSiteEndpoint();
  const [embedConfig, updateEmbedConfig] = useState<IReportEmbedConfiguration>({});
  const params = useParams<IReportParams>();
  const [report] = usePowerBIReport(props.reportId ?? params.reportId);
  const [embedded, setEmbedded] = useState<Embed>();

  async function fetchEmbedToken(): Promise<PowerBI.EmbedToken> {
    const embedToken: PowerBI.EmbedToken = await ep.Reports.Embed.getJson({
      reportId: report?.id,
      workspaceId: site?.PowerBIWorkspaceID,
      viewMode: props.viewMode === models.ViewMode.Edit ? 'Edit' : 'View',
      allowSaveAs: report?.reportType
        ? report.reportType.toLowerCase() !== 'paginatedreport'
        : true,
    });

    return embedToken;
  }

  async function embedConfiguration() {
    fetchEmbedToken().then(embedToken => {
      const embedConfig: IReportEmbedConfiguration = {
        type: 'report',
        id: report.id,
        embedUrl: report.embedUrl,
        tokenType: models.TokenType.Embed,
        accessToken: embedToken.token,
        permissions: models.Permissions.All,
        viewMode: props.viewMode,
      };
      updateEmbedConfig(embedConfig);
    });
  }

  useEffect(function getEmbedToken() {
    if (!!report) {
      embedConfiguration();
    }
  }, [report, props]);

  useEffect(function changeEmbedViewMode() {
    if (!!embedded) {
      embedded.config = embedConfig;
      embedded.configChanged(true);
      embedded.reload();
    }
  }, [embedConfig, embedded]);

  if (!embedConfig.accessToken) return <></>;

  return (
      <PowerBIEmbed embedConfig={embedConfig} getEmbeddedComponent={(e) => setEmbedded(e)} cssClassName='embedded-report' />
  );
}
