import { Button, Divider } from 'antd';
import React from 'react';
export function FilteredSelectDropdown({ menu, setFilter, filter, filters, filtersTitle, }) {
    const onFilterClick = (filter) => () => {
        setFilter(filter);
    };
    return (React.createElement("div", { className: "FilteredSelectDropdown" },
        React.createElement("div", { className: "Menu" }, menu),
        React.createElement("div", { className: "Filters" },
            React.createElement("h5", { className: "Title" }, filtersTitle !== null && filtersTitle !== void 0 ? filtersTitle : 'Filters'),
            React.createElement(Divider, { className: "Divider" }),
            React.createElement("div", { className: "FiltersScroll" }, filters.map(f => (React.createElement(Button, { type: f === filter ? 'primary' : 'default', className: "Filter", key: f, onClick: onFilterClick(f), size: "small" }, f)))))));
}
