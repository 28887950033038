import React from 'react';
import { Modal, Form, Input, notification } from 'antd';
import { IDashboard } from './dashboardsList';
import { kebabCase } from '@rapid/sdk';

interface AddDashboardFormProps {
  visible: boolean;
  onSubmit: (values: any) => void;
  onCancel: () => void;
}

const AddDashboardForm: React.FC<AddDashboardFormProps> = ({
  visible,
  onSubmit,
  onCancel,
}) => {
  const [form] = Form.useForm();
  const { TextArea } = Input;

  return (
    <Modal
      visible={visible}
      title="Add Dashboard"
      okText="Add"
      cancelText="Cancel"
      onCancel={onCancel}
      onOk={() => {
        form
          .validateFields()
          .then(values => {
            const schema: any = {
              items: []
            };
            const dashboard: IDashboard = {
              title: values.title,
              slug: kebabCase(values.title),
              description: values.description,
              schema: schema,
              __metadata: {
                type: 'Adaptive Dashboards'
              }
            }
            form.resetFields();
            onSubmit(dashboard);
          })
          .catch();
      }}
    >
      <Form
        form={form}
        layout="vertical"
        name="form-in-modal"
      >
        <Form.Item
          name="title"
          label="Title"
          rules={[{ required: true, message: 'Please enter a title.' }, { max: 50, message: 'Title is too long.' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="description"
          label="Description"
        >
          <TextArea rows={4} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddDashboardForm;