import React, { useEffect, useState } from 'react';
import { Modal, Form, Select, Input, notification } from 'antd';
import { Xmla } from '@rapid/data-model';

interface AddRelationshipFormProps {
  visible: boolean;
  tables: Xmla.IXmlaTable[];
  onSubmit: (values: Xmla.IXmlaRelationship) => void;
  onCancel: () => void;
}

const AddRelationshipForm: React.FC<AddRelationshipFormProps> = ({
  visible,
  tables,
  onSubmit,
  onCancel,
}) => {
  const [form] = Form.useForm();
  const { Option } = Select;

  const [fromTable, setFromTable] = useState<string>();
  const [toTable, setToTable] = useState<string>();
  const [fromColumn, setFromColumn] = useState<string>();
  const [toColumn, setToColumn] = useState<string>();
  const [fromTableOptions, setFromTableOptions] = useState<string[]>([]);
  const [fromColumnOptions, setFromColumnOptions] = useState<Xmla.IXmlaColumn[]>([]);
  const [toTableOptions, setToTableOptions] = useState<string[]>([]);
  const [toColumnOptions, setToColumnOptions] = useState<Xmla.IXmlaColumn[]>([]);

  useEffect(
    function setFromTableOptionsOnChange() {
      setFromTableOptions(tables.map(table => table.name));
    },
    [tables],
  );

  useEffect(
    function setFromColumnsAndToTable() {
      if (!!fromTable) {
        setFromColumnOptions(
          tables
            .find(table => table.name === fromTable)
            ?.columns ?? [],
        );
        setToTableOptions(
          tables.map(table => table.name).filter(table => table !== fromTable),
        );
      }
    },
    [fromTable, tables],
  );

  useEffect(
    function setToColumns() {
      if (!!toTable) {
        setToColumnOptions(
          tables
            ?.find(table => table.name === toTable)
            ?.columns ?? [],
        );
      }
    },
    [toTable, tables],
  );

  return (
    <Modal
      visible={visible}
      title="Add Relationship"
      okText="Add"
      cancelText="Cancel"
      onCancel={onCancel}
      onOk={() => {
        form
          .validateFields()
          .then(values => {
            const relationship: Xmla.IXmlaRelationship = {
              name: values.name,
              type: Xmla.XmlaRelationshipType.SingleColumn,
              fromColumn: values.fromColumn,
              fromTable: values.fromTable,
              fromCardinality:
                values.fromColumn === 'id'
                  ? Xmla.XmlaRelationshipCardinality.One
                  : Xmla.XmlaRelationshipCardinality.Many,
              toColumn: values.toColumn,
              toTable: values.toTable,
              toCardinality:
                values.fromColumn === 'id'
                  ? Xmla.XmlaRelationshipCardinality.One
                  : Xmla.XmlaRelationshipCardinality.Many,
              joinOnDateBehavior: values.joinOnDateBehavior ?? Xmla.XmlaRelatoinshipDateTimeRelationshipBehavior.DateAndTime,
              crossFilteringBehavior: values.crossFilteringBehavior,
            };
            form.resetFields();
            onSubmit(relationship);
          })
          .catch(info => {
            notification.error({ message: 'Validate Failed:', description: info, duration: 5});
          });
      }}
    >
      <Form form={form} layout="vertical" name="form-in-modal">
        <Form.Item
          name="name"
          label="Relationship Name"
          rules={[
            { required: true, message: 'Please enter a relationship name.' },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="fromTable"
          label="From Table"
          rules={[{ required: true, message: 'Please select a table.' }]}
        >
          <Select
            showSearch
            style={{ width: '100%' }}
            placeholder="Select From Table"
            optionFilterProp="children"
            onChange={ev => setFromTable(ev?.toString())}
            filterOption={(input, option) =>
              option?.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {fromTableOptions?.map(option => (
              <Option key={option} value={option}>
                {option}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="fromColumn"
          label="From Column"
          rules={[{ required: true, message: 'Please select a column.' }]}
        >
          <Select
            showSearch
            style={{ width: '100%' }}
            placeholder="Select From Column"
            optionFilterProp="children"
            onChange={ev => setFromColumn(ev?.toString())}
            filterOption={(input, option) =>
              option?.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {fromColumnOptions?.map(option => (
              <Option key={option.name} value={option.name}>
                {option.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="toTable"
          label="To Table"
          rules={[{ required: true, message: 'Please select a table.' }]}
        >
          <Select
            showSearch
            style={{ width: '100%' }}
            placeholder="Select To Table"
            optionFilterProp="children"
            onChange={ev => setToTable(ev?.toString())}
            filterOption={(input, option) =>
              option?.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {toTableOptions?.map(option => (
              <Option key={option} value={option}>
                {option}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="toColumn"
          label="To Column"
          rules={[{ required: true, message: 'Please select a column.' }]}
        >
          <Select
            showSearch
            style={{ width: '100%' }}
            placeholder="Select To Column"
            optionFilterProp="children"
            onChange={ev => setToColumn(ev?.toString())}
            filterOption={(input, option) =>
              option?.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {toColumnOptions?.map(option => (
              <Option key={option.name} value={option.name}>
                {option.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        {!!fromColumnOptions.find(c => c.name === fromColumn)?.dataType.match(/DateTime/i) &&
        !!toColumnOptions.find(c => c.name === toColumn)?.dataType.match(/DateTime/i) &&
        <Form.Item
          name="joinOnDateBehavior"
          label="Join On Date Behavior"
          rules={[
            { required: true, message: 'Please select a date behavior.' },
          ]}
        >
          <Select
            showSearch
            style={{ width: '100%' }}
            placeholder="Select To Date Behavior"
            optionFilterProp="children"
            filterOption={(input, option) =>
              option?.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            <Option
              key={
                Xmla.XmlaRelatoinshipDateTimeRelationshipBehavior.DateAndTime
              }
              value={
                Xmla.XmlaRelatoinshipDateTimeRelationshipBehavior
                  .DateAndTime as number
              }
            >
              Date And Time
            </Option>
            <Option
              key={
                Xmla.XmlaRelatoinshipDateTimeRelationshipBehavior.DatePartOnly
              }
              value={
                Xmla.XmlaRelatoinshipDateTimeRelationshipBehavior
                  .DatePartOnly as number
              }
            >
              Date Part Only
            </Option>
          </Select>
        </Form.Item>}
        <Form.Item
          name="crossFilteringBehavior"
          label="Cross Filtering Behavior"
          rules={[
            {
              required: true,
              message: 'Please select a cross filtering behavior.',
            },
          ]}
        >
          <Select
            showSearch
            style={{ width: '100%' }}
            placeholder="Select To Cross Filtering Behavior"
            optionFilterProp="children"
            filterOption={(input, option) =>
              option?.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            <Option
              key={Xmla.XmlaRelationshipCrossFilteringBehavior.OneDirection}
              value={
                Xmla.XmlaRelationshipCrossFilteringBehavior
                  .OneDirection as number
              }
            >
              One Direction
            </Option>
            <Option
              key={Xmla.XmlaRelationshipCrossFilteringBehavior.BothDirections}
              value={
                Xmla.XmlaRelationshipCrossFilteringBehavior
                  .BothDirections as number
              }
            >
              Both Direction
            </Option>
            <Option
              key={Xmla.XmlaRelationshipCrossFilteringBehavior.Automatic}
              value={
                Xmla.XmlaRelationshipCrossFilteringBehavior.Automatic as number
              }
            >
              Automatic
            </Option>
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddRelationshipForm;
