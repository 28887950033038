import { useCurrentSite, useCurrentSiteEndpoint } from '@rapid/sdk';
import { usePowerBIContext } from '@rapid/sdk/lib/rapid-application/managed-powerbi-context';
import { Card, Dropdown, Menu, message, Popconfirm, Upload } from 'antd';
import { RcFile } from 'antd/lib/upload';
import { UploadFile } from 'antd/lib/upload/interface';
import { UploadRequestOption } from 'rc-upload/lib/interface';
import { useEffect, useState } from 'react';
import { CommandBar, CommandBarCenter, CommandBarLeft, CommandBarRight } from '../../components/commandbar/commandBar';
import { Breadcrumbs } from '../../sdk/breadcrumbs/breadcrumbs';
import { useBreadcrumbText } from '../../utils/breadcrumbText';
import ExponentialCallback from '../../utils/exponentialCallback';
import {feedbackWrapper} from '../../utils/feedbackWrapper';
import { useNavigation } from '../../utils/Navigation';
import './reportsList.scss';

export function PBIReportsList() {
  const ep = useCurrentSiteEndpoint();
  const [site] = useCurrentSite();
  const [powerBIContext] = usePowerBIContext();
  const { reports, datasets, fetchReports, deleteReport } = powerBIContext;
  const { Navigate } = useNavigation();
  const callWithRetry = ExponentialCallback();
  const breadcrumbText = useBreadcrumbText();
  const [reportsMenu, setReportsMenu] = useState<JSX.Element>();
  const [fileList, setFileList] = useState<UploadFile[]>([]);

  function getReportType(type: string): string {
    switch (type.toLowerCase()) {
      case 'paginatedreport':
        return 'Paginated Report';
      case 'powerbireport':
      default:
        return 'PowerBI Report';
    }
  }

  async function uploadReport(options: UploadRequestOption<any>) {
    const file = options.file as RcFile;

    const filenameMatch = file.name.match(/\.(pbix|rdl)+$/i);
    const reportType = filenameMatch ? filenameMatch[1].toLowerCase() : '';
    const reportName = file.name.replace(/.(pbix|rdl)/i, '');

    message.loading({ content: `Uploading ${reportName}...`, key: 'report', duration: 0 });
    try {
      await ep.Reports.Workspace[site?.PowerBIWorkspaceID!]['File'].postBlob(
        { name: reportName, type: reportType },
        file,
      );
      message.success({
        content: `${reportName} saved!`,
        key: 'report',
        duration: 2,
      });
      fileList[fileList.findIndex(_file => _file.uid === file.uid)].status =
        'done';
      setFileList(fileList);
    } catch (err) {
      fileList[fileList.findIndex(_file => _file.uid === file.uid)].status =
        'error';
      setFileList(fileList);
      message.error({
        content: `${reportName} upload failed.`,
        key: 'report',
        duration: 2,
      });
    }

    if (reportType === 'rdl') {
      const dataSource = (await file.text()).match(
        /<DataProvider>([a-z]*)<\/DataProvider>/i,
      );
      const dataSourceType = !!dataSource ? dataSource[1].toLowerCase() : '';

      if (dataSourceType?.toLowerCase() !== 'sqlazure') {
        return await fetchReports();
      }
    }

    await feedbackWrapper(
      () => callWithRetry(
        async () =>
          await ep.Reports.Workspace[site?.PowerBIWorkspaceID!][
            reportName
          ].Dataset.put({}, { reportType }),
      ),
      `Updating ${reportName} dataset...`,
      `${reportName} dataset updated!`,
      `Unable to update ${reportName} dataset.`,
    );

    await fetchReports();
  }

  useEffect(function updateReportsMenu() {
    if (!!datasets) {
      setReportsMenu(
        <Menu>
          {Array.from(datasets).map(dataset => (
            <Menu.Item key={dataset.id}>
              <a onClick={() => Navigate(`/newreport:${dataset.id}`)}>
                {dataset.name}
              </a>
            </Menu.Item>
          ))}
        </Menu>,
      );
    }
  }, [datasets]);

  useEffect(function componentDidMount() {
    fetchReports();
  }, []);

  return (
    <>
      <CommandBar>
        <CommandBarLeft>
          <Breadcrumbs breadcrumbText={breadcrumbText} />
        </CommandBarLeft>
        <CommandBarCenter></CommandBarCenter>
        <CommandBarRight>
          {!!reportsMenu && (
            <Dropdown overlay={reportsMenu} placement="bottomCenter" arrow>
              <button
                title="Create new report from dataset"
                className="button default margin-horizontal-small"
              >
                <i className="fal fa-plus" />
                {" New From Dataset"}
              </button>
            </Dropdown>
          )}
          <Upload
            accept=".rdl,.pbix"
            name="file"
            customRequest={options => uploadReport(options)}
            onChange={info => {
              setFileList(info.fileList);
            }}
            fileList={fileList}
            itemRender={() => <></>}
          >
            <button
              title="Upload PBI Report"
              className="button default margin-horizontal-small"
            >
              <i className="fal fa-upload" />
              {" Upload"}
            </button>
          </Upload>
        </CommandBarRight>
      </CommandBar>
      <div className="content card-list">
        {Array.from(reports).map(report => (
          <Card
            key={report.name}
            cover={<i className="fak fa-powerbi fa-7x" />}
            hoverable
            extra={
              <Popconfirm
                title={`Delete ${report.name}`}
                onConfirm={(e) => {e?.stopPropagation(); deleteReport(report.id)}}
                onCancel={(e) => e?.stopPropagation()}
                okText="Delete"
                okButtonProps={{ danger: true }}
                cancelText="No"
              >
                <button
                  title="Delete"
                  className="delete-button"
                  onClick={(ev) => ev.stopPropagation()}
                >
                  <i className="fal fa-trash-alt" />
                </button>
              </Popconfirm>
            }
            actions={[
              <a title='Web Url' onClick={ev => ev.stopPropagation()} href={report.webUrl} target='_blank'>
                <i className="fal fa-globe" />
              </a>,
              <a title='Embed Url' onClick={ev => ev.stopPropagation()} href={report.embedUrl} target='_blank'>
                <i className="fal fa-code" />
              </a>,
            ]}
            onClick={ev => {
              ev.stopPropagation();
              Navigate(`/pbireport:${report.id}`);
            }}
          >
            <Card.Meta title={report.name} />
            <div className="report-type">
              {getReportType(report.reportType)}
            </div>
          </Card>
        ))}
      </div>
    </>
  );
}
