import { IFormElement } from '@rapid/adaptive-forms';
import { IQueryBuilderSQL } from '@rapid/data-model/lib/query-builder';
import { StringGradients } from 'antd/lib/progress/progress';
import { v4 as uuidv4 } from 'uuid';
import { QueryGroupRemovable, QueryGroup, QueryRule } from '../layouts/base-layout';
import {
  JoinGroup,
  JoinGroupWithoutQuery,
} from '../layouts/sql-join-layout';
import { BasicQuery } from '../layouts/sql-layout';
import { getFormElementId } from './helpers';

export class SQLQueryParser {
  protected rootId: string;
  protected query: IQueryBuilderSQL;
  protected data: Record<string, any>;
  protected form: IFormElement;
  protected uuid?: string;
  protected joinUuid?: string;
  protected whereUuid?: string;

  constructor(query: IQueryBuilderSQL, rootId: string = 'root') {
    this.query = query;
    this.rootId = rootId;
    this.data = query.data;

    for (let key of Object.keys(this.data[this.rootId])) {
      const elementId = getFormElementId(key);
      if (!elementId) break;
      switch (elementId.type) {
        case 'Select':
          this.uuid = elementId.uuid;
          break;
        case 'JoinsGroup':
          this.joinUuid = elementId.uuid;
          break;
        case 'QueryGroup':
          this.whereUuid = elementId.uuid;
          break;
      }
    }

    if (!this.uuid) this.uuid = uuidv4();
    if (!this.joinUuid) this.joinUuid = uuidv4();
    if (!this.whereUuid) this.whereUuid = uuidv4();

    this.form = BasicQuery(this.uuid);

    this.addJoins();
    this.addWhere();
    this.addOrderBy();
  }

  getFormData(): [form: IFormElement, data: Record<string, any>] {
    return [this.form, this.data];
  }

  protected addJoins() {
    const joinGroup = JoinGroup(this.joinUuid);

    if (!!this.query.joins) {
      const data = this.data[this.rootId][`JoinsGroup:~:${this.joinUuid}`];
      if (!!data) {
        for (let key of Object.keys(data)) {
          const elementId = getFormElementId(key);
          if (!!elementId && elementId.type === 'JoinRule')
            joinGroup.$children?.push(this.parseJoinRuleGroup(elementId.uuid, data[key]));
        }
      }
    }

    this.form.$children?.push(joinGroup);
  }

  protected addWhere() {
    this.form.$children?.push({
      $type: 'Layout.Heading' as any,
      id: `Heading:~:${this.uuid}`,
      attributes: {
        label: 'Where',
        type: 'h5',
      },
    });

    if (!this.query.where) {
      this.form.$children?.push(QueryGroup(this.uuid));
      return;
    }

    const whereForm = this.parseRuleGroup(this.whereUuid!, this.data[this.rootId][`QueryGroup:~:${this.whereUuid}`]);
    this.form.$children?.push(whereForm);
  }

  protected addOrderBy() {
    this.form.$children?.push(
      {
        $type: 'Input.OrderBy',
        id: `OrderBy:~:${this.uuid}`,
        label: 'Order By',
      });
  }
  
  protected parseRuleGroup(
    uuid: string,
    data: Record<string, any>,
    depth: number = 0,
  ): IFormElement {
    const form = depth === 0 ? QueryGroup(uuid) : QueryGroupRemovable(uuid);

    for (let key of Object.keys(data)) {
      if (typeof data[key] === 'object') {
        const elementId = getFormElementId(key);
        if (!elementId) break;
        switch(elementId.type) {
          case 'QueryGroup':
            form.$children?.push(this.parseRuleGroup(elementId.uuid, data[key], depth + 1));
            break;
          case 'QueryRule':
            form.$children?.push(QueryRule(elementId.uuid));
        }
      }
    }

    return form;
  }
  
  protected parseJoinRuleGroup(
    uuid: string,
    data: Record<string, any>
  ): IFormElement {
    const form = JoinGroupWithoutQuery(uuid);

    for (let key of Object.keys(data)) {
      if (typeof data[key] === 'object') {
        const elementId = getFormElementId(key);
        if (elementId?.type === 'QueryGroup')
          form.$children?.push(this.parseRuleGroup(elementId.uuid, data[key]));
      }
    }
 
    return form;
  }
}