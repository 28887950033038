import React, { useMemo, useState } from 'react';
import { Modal, Form, Select, Tabs, Input } from 'antd';
import { Xmla } from '@rapid/data-model';
import { IXmlaColumn } from '@rapid/data-model/lib/xmla-models';
import { ICustomView } from '../../../components/dataTypes';

interface AddColumnFormProps {
  visible: boolean;
  views: ICustomView[];
  table?: Xmla.IXmlaTable;
  onSubmit: (column: Xmla.IXmlaColumn) => void;
  onCancel: () => void;
}

const AddColumnForm: React.FC<AddColumnFormProps> = ({
  visible,
  views,
  table,
  onSubmit,
  onCancel,
}) => {
  const [currentTab, setCurrnetTab] = useState<string>('1');
  const [form] = Form.useForm();
  const { Option } = Select;
  const { TabPane } = Tabs;
  let columns: Xmla.IXmlaColumn[];

  const currentView = useMemo(() => {
    if (!table) return undefined;
    const mPartitions = table.partitions.filter(p => 
      (p.source as Xmla.IXmlaMPartitionSource).expression !== undefined);

    const tableOrViews = mPartitions.map(p => 
      (p.source as Xmla.IXmlaMPartitionSource).expression
        .match(/(dbo|adaptive_views).\[([A-Z\s\(\)]*)\]/i)?.[2]);

    return views.find(view => view.Name === table?.name || tableOrViews.includes(view.Name));
  }, [table]);

  const sourceFields = useMemo(() => {
    if (!table || !currentView) return undefined;
    columns = table.columns ?? ([] as IXmlaColumn[]);
    return currentView.Fields?.filter(
      field =>
        !columns.find(column => column.source === field.ColumnName) &&
        !field.FieldType.match(/(multilookup|multichoice)/i),
    );
  }, [table, currentView]);

  const onTabChanged = (tab: string) => {
    setCurrnetTab(tab);
  };

  const dataChoices = [
    {
      text: 'Single line of text',
      value: 'text'
    },
    {
      text: 'Email',
      value: 'email'
    },
    {
      text: 'Multi-lines of text',
      value: 'multiple lines of text'
    },
    {
      text: 'Date',
      value: 'date'
    },
    {
      text: 'Binary',
      value: 'boolean'
    },
    {
      text: 'Number',
      value: 'number'
    },
    {
      text: 'Percentage',
      value: 'percentage'
    },
    {
      text: 'Currency',
      value: 'currency'
    },
    {
      text: 'User',
      value: 'user'
    },
    {
      text: 'Whole Numer',
      value: 'integer'
    }
  ];

  const dataOptions = dataChoices.map(choice => <Option key={choice.value} value={choice.value}>
    {choice.text}
  </Option>);

  const columnHeader = (
    <Tabs defaultActiveKey={currentTab} onChange={onTabChanged}>
      <TabPane tab="Add Column" key="1" />
      <TabPane tab="Custom Column" key="2" />
    </Tabs>
  );

  return (
    <Modal
      className="add-column-modal"
      visible={visible}
      title={columnHeader}
      okText="Add"
      cancelText="Cancel"
      onCancel={() => {
        form.resetFields();
        onCancel();
      }}
      onOk={() => {
        try {
          form.validateFields().then(values => {
            const sf = sourceFields?.find(
              field => field.ColumnName === values.source,
            );

            const column: Xmla.IXmlaColumn = {
              name: values.name ?? sf?.Title,
              source: values.source,
              expression: values.expression,
              type: Xmla.XmlaColumnType.DataColumn,
              dataType: values.dataType ?? sf?.FieldType,
            };
            form.resetFields();
            onSubmit(column);
          });
        } catch (e) {}
      }}
    >
      <Form form={form} layout={'vertical'} name="form-in-modal">
        {currentTab === '1' && (
          <>
            {!!sourceFields && sourceFields.length > 0 ? (
              <Form.Item
                name="source"
                label="Select a Column"
                rules={[
                  {
                    required: !!sourceFields && sourceFields.length > 0,
                    message: 'Please select a column.',
                  },
                ]}
              >
                <Select
                  showSearch
                  placeholder="Select a column"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option?.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {sourceFields?.map(field => (
                    <Option key={field.ColumnName} value={field.ColumnName}>
                      {field.Title}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              ) : (
                <>
                <Form.Item
                name="name"
                label="Column Name"
                rules={[
                  {
                    required: true,
                    message: 'Please specify a name',
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="source"
                label="Column Source"
                rules={[
                  {
                    required: true,
                    message: 'Please specify a source column.',
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="dataType"
                label="Data Type"
                rules={[
                  {
                    required: true,
                    message: 'Please specify a data type.',
                  },
                ]}
              >
                <Select
                  showSearch
                  placeholder="Select a data type"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option?.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {dataOptions}
                </Select>
              </Form.Item>
              </>
              )
            }
          </>
        )}
        {currentTab === '2' && (
          <>
            <Form.Item
              name="name"
              label="Column Name"
              rules={[
                {
                  required: true,
                  message: 'Please specify a name',
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="expression"
              label="SQL Expression"
              rules={[
                {
                  required: true,
                  message: 'Please specify an expression.',
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="dataType"
              label="Data Type"
              rules={[
                {
                  required: true,
                  message: 'Please specify a data type.',
                },
              ]}
            >
              <Select
                showSearch
                placeholder="Select a data type"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option?.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {dataOptions}
              </Select>
            </Form.Item>
          </>
        )}
      </Form>
    </Modal>
  );
};

export default AddColumnForm;
