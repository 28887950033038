import React from 'react';
/**
 * An animated spinner that is useful for showing user feedback when they are
 * required to wait for something to complete.
 */
export function Spinner({ hidden, size }) {
    if (hidden) {
        return null;
    }
    let iconSize = 'fa-2x';
    switch (size) {
        case 1:
            iconSize = '';
            break;
        case 3:
            iconSize = 'fa-3x';
        case 5:
            iconSize = 'fa-5x';
            break;
        case 7:
            iconSize = 'fa-7x';
            break;
        case 10:
            iconSize = 'fa-10x';
            break;
        case 'XS':
            iconSize = 'fa-xs';
            break;
        case 'SM':
            iconSize = 'fa-sm';
            break;
        case 'LG':
            iconSize = 'fa-lg';
        case 2:
        default:
            iconSize = 'fa-2x';
            break;
    }
    return React.createElement("i", { className: `fal ${iconSize} fa-spin fa-fan` });
}
