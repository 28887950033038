import { useEffect, useState } from "react";
/**
 *
 * React's useState, but persisted in local storage by the specified key.
 *
 * @typeparam T T is any JSON-serializable type
 *
 * @IMPORTANT state must be JSON serializable.
 *
 * @example
 * ```typescript
 * const [accountId, setAccountId] = useLocalStorageState("my::namespace::account_key");
 *
 * useEffect(function componentDidMountEffect() {
 *  signIn().then(accountId => {
 *    // accountId gets persisted in local storage under the "my::namespace::account_key"
 *    // key name.
 *    setAccountId(accountId);
 *  });
 * }, []);
 * ```
 *
 * @returns A tuple of the state, and an action for modifying the state.
 * @category General Hooks
 */
export function useLocalStorageState(key) {
    const [state, setState] = useState(JSON.parse(localStorage.getItem(key)));
    return [state, function localStorageWrapper(value) {
            localStorage.setItem(key, JSON.stringify(value));
            setState(value);
        }];
}
export function useLocalStorage(key, value, deps) {
    useEffect(function localStorageChangedEffect() {
        localStorage.setItem(key, JSON.stringify(value));
    }, deps);
}
