import { useCurrentSite } from './use-current-site';
import { useList } from './use-list';
/**
 * Uses a list of data sources properly sourced from the site file.
 *
 * @hook
 */
export function useDataSources(list) {
    var _a, _b, _c;
    const [site, updateCurrentSite] = useCurrentSite();
    const [innerList] = useList(list);
    return [
        [
            ...((_a = innerList === null || innerList === void 0 ? void 0 : innerList.DataSources) !== null && _a !== void 0 ? _a : []),
            ...((_c = (_b = site === null || site === void 0 ? void 0 : site.DataSources) === null || _b === void 0 ? void 0 : _b[innerList.Table]) !== null && _c !== void 0 ? _c : []),
        ],
        function updateDataSources(d) {
            updateCurrentSite(draft => {
                var _a;
                const ds = (_a = draft.DataSources) === null || _a === void 0 ? void 0 : _a[innerList.Table];
                if (!ds) {
                    draft.DataSources[innerList.Table] = [];
                }
                d(ds);
            });
        },
    ];
}
export function useDataSource(list, dataSource) {
    const [dataSources, updateDataSources] = useDataSources(list);
    if (!dataSource) {
        throw new Error(`useDataSource: Expected string or IDatasource for dataSource parameter, got ${typeof dataSource}`);
    }
    return [
        dataSources.find(i => i.Title.localeCompare(typeof dataSource === 'string' ? dataSource : dataSource.Title) === 0),
        function updateDataSource(d) {
            updateDataSources(draft => {
                const ds = draft.find(i => i.Title.localeCompare(typeof dataSource === 'string' ? dataSource : dataSource.Title) === 0);
                d(ds);
            });
        },
    ];
}
