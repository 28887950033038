import {
  ManagedListItemContext,
  useCurrentSiteEndpoint,
  useListItemContext,
} from '@rapid/sdk';
import { useParams } from 'react-router-dom';
import {
  CommandBar,
  CommandBarCenter,
  CommandBarLeft,
  CommandBarRight,
} from '../../components/commandbar/commandBar';
import { IAdaptiveViewParams } from '../../components/dataTypes';
import { Result, Button, Popconfirm } from 'antd';
import { useNavigation } from '../../utils/Navigation';
import { useBreadcrumbText } from '../../utils/breadcrumbText';
import { Breadcrumbs } from '../../sdk/breadcrumbs/breadcrumbs';
import { IAdaptiveView } from '../AdaptiveViewsList/adaptiveViewsList';
import { feedbackWrapper } from '../../utils/feedbackWrapper';
import { useEffect, useMemo, useState } from 'react';
import { AnyListItem } from '@rapid/data-model';
import {
  AdaptiveViewTable,
  IAdaptiveViewTableColumn,
} from '../../components/dataTable/AdaptiveViewTable';
import { EditAdaptiveViewForm } from './editAdaptiveViewForm';
import { IQueryBuilderSQL } from '@rapid/data-model/lib/query-builder';

export function AdaptiveViewContext({ children }: React.PropsWithChildren<{}>) {
  const { viewId } = useParams<IAdaptiveViewParams>();

  return (
    <ManagedListItemContext id={viewId} validate list={'Adaptive Views'}>
      {children}
    </ManagedListItemContext>
  );
}

export function AdaptiveView() {
  const ep = useCurrentSiteEndpoint();
  const { BaseNavigate } = useNavigation();
  const breadcrumbText = useBreadcrumbText();
  const [context, updateContext] = useListItemContext();
  const [items, setItems] = useState<AnyListItem[]>();
  const [viewExists, setViewExists] = useState<boolean>(true);
  const [editAdaptiveViewVisible, setEditCustomViewVisible] =
    useState<boolean>(false);

  const table = (tableOrAlias: string, query: IQueryBuilderSQL): string => {
    if (tableOrAlias === query.from.alias || tableOrAlias === query.from.table)
      return query.from.table;

    return (
      query.joins?.find(j => j.alias === tableOrAlias)?.table || tableOrAlias
    );
  };

  const columns: IAdaptiveViewTableColumn[] | undefined = useMemo(() => { 
    if (!!context.item) {
      const schema = (context.item as IAdaptiveView).schema;
      return schema.select.map(select => {
        const [tableOrAlias, column] = select.split('.');
        return {
          columnName: column,
          tableName: table(tableOrAlias, schema),
          dataIndex: select.replace('.', '$'),
        };
      });
    }
  }, [context.item]);

  async function updateAdaptiveViewItems(viewName: string) {
    try {
      const data = await ep.Views[viewName].getJson();
      setItems(data.value as AnyListItem[]);
    } catch (err) {
      setViewExists(false);
    }
  }

  function updateAdaptiveView(item: IAdaptiveView) {
    if (!!item.id) {
      feedbackWrapper(
        async () => {
          await ep.Lists[context.list.ListName][''].Items[item.id!].putJson(
            undefined,
            item,
          );

          updateContext(d => {
            d.item = item;
          });

          await ep.Views[item.view_name].putJson({}, item.schema);
          await updateAdaptiveViewItems(item.view_name);
        },
        `Updating ${item.title}...`,
        `${item.title} view updated!`,
        `Unable to update ${item.title}.`,
      );
    }
  }

  async function createAdaptiveView(item: IAdaptiveView) {
    await feedbackWrapper(
      () =>
        ep.Views[item.view_name]
          .postJson({}, item.schema),
      `Creating ${item.title}...`,
      `${item.title} view created!`,
      `Unable to create ${item.title}.`,
    );
  }

  async function deleteAdaptiveView(item: IAdaptiveView) {
    await feedbackWrapper(
      () =>
        ep.Lists[context.list.ListName]['All'].Items.delete({}, [item.id!]).then(() => 
          ep.Views[item.view_name].delete()
        ),
      `Deleting ${item.title}...`,
      `${item.title} view deleted!`,
      `Unable to delete ${item.title}.`,
    );
    BaseNavigate('/adaptiveviews');
  }


  useEffect(
    function onItemUpdated() {
      if (!!context.item) {
        const item = context.item as IAdaptiveView;
        updateAdaptiveViewItems(item.view_name);
      }
    },
    [context.item],
  );

  return !!context.item && !!columns ? (
    <>
      <CommandBar>
        <CommandBarLeft>
          <Breadcrumbs breadcrumbText={breadcrumbText} />
        </CommandBarLeft>
        <CommandBarCenter>
          {(context.item as IAdaptiveView).title}
        </CommandBarCenter>
        <CommandBarRight>
          <button
            title="Edit Adaptive View"
            className="button default margin-horizontal-small"
            onClick={() => setEditCustomViewVisible(true)}
          >
            <i className="fal fa-pencil" />
          </button>
          &ensp;
          <Popconfirm
              title={`Delete ${(context.item as IAdaptiveView).title}`}
              onConfirm={e => {
                e?.stopPropagation();
                deleteAdaptiveView(context.item as IAdaptiveView);
              }}
              onCancel={e => e?.stopPropagation()}
              okText="Delete"
              okButtonProps={{ danger: true }}
              cancelText="No"
              placement="bottomRight"
            >
              <button
                title="Delete"
                className="button delete margin-horizontal-small"
                onClick={ev => ev.stopPropagation()}
              >
                <i className="fal fa-trash-alt" />
              </button>
            </Popconfirm>
        </CommandBarRight>
      </CommandBar>
      <div className="content">
        {!items && !viewExists ? (
          <Result
            status="404"
            title="404"
            subTitle="Sorry, this view does not exist in the database yet. Would you like to create it?"
            extra={[
              <Button type="primary" onClick={() => createAdaptiveView(context.item as IAdaptiveView)}>
                Create View
              </Button>,
              <Button onClick={() => BaseNavigate('/adaptiveviews')}>
                Back to Adaptive Views
              </Button>,
            ]}
          />
        ): ( 
        <AdaptiveViewTable columns={columns} data={items} />
      )}
      </div>
      <EditAdaptiveViewForm
        initialData={context.item as IAdaptiveView}
        visible={editAdaptiveViewVisible}
        onSubmit={(item: IAdaptiveView) => {
          setEditCustomViewVisible(false);
          updateAdaptiveView(item);
        }}
        onCancel={() => setEditCustomViewVisible(false)}
      />
    </>
  ) : !context.loading ? (
    <>
    <CommandBar>
      <CommandBarLeft>
        <Breadcrumbs breadcrumbText={breadcrumbText} />
      </CommandBarLeft>
    </CommandBar>
    <div className="content">
    <Result
      status="404"
      title="404"
      subTitle="Sorry, this view does not exist."
      extra={[
        <Button onClick={() => BaseNavigate('/adaptiveviews')}>
          Back to Adaptive Views
        </Button>,
      ]}
    />
  </div>
  </>
  ) : (
    <>
    <CommandBar>
      <CommandBarLeft>
        <Breadcrumbs breadcrumbText={breadcrumbText} />
      </CommandBarLeft>
    </CommandBar>
    <div className="content">
  </div>
  </>
  );
}
