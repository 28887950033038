import React, { useState } from 'react';
import { Modal } from 'antd';
import {
  IQueryBuilderType,
  QueryBuilder,
} from '../../../components/QueryBuilder/QueryBuilder';
import { kebabCase, useCurrentSite, useCurrentSiteEndpoint } from '@rapid/sdk';
import { IAdaptiveView } from '../adaptiveViewsList';
import { SQLQueryConverter } from '../../../components/QueryBuilder/utils/sql-query-converter';
import { IQueryBuilderSQL } from '@rapid/data-model/lib/query-builder';

interface AdaptiveViewFormProps {
  initialData?: IQueryBuilderSQL;
  visible: boolean;
  onSubmit: (item: IAdaptiveView) => void;
  onCancel: () => void;
}

export const AdaptiveViewForm: React.FC<AdaptiveViewFormProps> = ({
  initialData,    
  visible,
  onSubmit,
  onCancel,
}) => {
  const ep = useCurrentSiteEndpoint();
  const [currentSite] = useCurrentSite();
  const type = IQueryBuilderType.SQL;
  const [data, setData] = useState<Record<string, any>>();

  return (
    <Modal
      width="700px"
      visible={visible}
      title="Add Adaptive View"
      okText="Add"
      cancelText="Cancel"
      onCancel={() => {
        onCancel();
        setData(undefined);
      }}
      onOk={() => {
        if (!!data) {
          const sql = new SQLQueryConverter(data).getQuery();
          if (!!sql.name) {
            const item: IAdaptiveView = {
              title: sql.name,
              slug: kebabCase(sql.name),
              description: '',
              schema: sql,
              view_name: sql.name,
              __metadata: {
                type: 'Adaptive Views'
              }
            };
            onSubmit(item);
            setData(undefined);
          }
        }
      }}
    >
      {!!currentSite?.Lists && (
        <QueryBuilder
          type={type}
          lists={currentSite.Lists}
          data={initialData}
          onChange={(data) => {
            setData(data);
          }}
        />
      )}
    </Modal>
  );
};
