import { useCallback, useState } from 'react';
/**
 *
 * @hook
 * @category General Hooks
 */
export function useInputState(initialValue) {
    const [state, setState] = useState(initialValue);
    return [
        state,
        useCallback((ev) => {
            // @ts-expect-error
            setState(ev.target.value);
        }, []),
        setState
    ];
}
