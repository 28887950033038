import React, { createContext, useContext, useEffect, useMemo } from 'react';
import { useGraphAuthenticatedFetch } from '../authentication';
import { useCurrentSite } from '../use-current-site';
import { useGraphEndpoint } from '../use-graph-endpoint';
import { useImmer } from '../use-immer';
import { getFileIcon } from '../utils/get-file-icon';
import { UploadSession } from '../utils/upload-session';
import { blobUrlTag, blobTag, isUploadingTag, nameTag, percentTag, driveItemTag, webUrlTag, loadingTag, downloadUrlTag, iconTag, } from './symbols';
const attachmentsContext = createContext({});
/**
 * Creates a React context that handles the items attachments.
 * Provides access to the {@link Attachments} hooks
 *
 * @component
 * @category RAPID Application
 */
export function AttachmentsContext({ children, item, onAttachmentsChanged, }) {
    const graphEndpoint = useGraphEndpoint('Files.ReadWrite.All');
    const [attachments, updateAttachments] = useImmer(item.Attachments);
    useEffect(function attachmentsChangedEffect() {
        if (item.Attachments) {
            updateAttachments(() => item.Attachments);
        }
    }, 
    // [item.Attachments],
    []);
    useEffect(function fetchAttachmentsOnMount() {
        (async function asyncFetchAttachmentsOnMount() {
            for (const attachment of attachments !== null && attachments !== void 0 ? attachments : []) {
                updateAttachments(d => {
                    const localAtt = d.find(a => a.drive_id === attachment.drive_id &&
                        a.drive_item_id === attachment.drive_item_id);
                    localAtt[loadingTag] = true;
                });
                try {
                    const graphAtt = await graphEndpoint.drives[attachment.drive_id].items[attachment.drive_item_id].getJson();
                    updateAttachments(d => {
                        const localAtt = d.find(a => a.drive_id === attachment.drive_id &&
                            a.drive_item_id === attachment.drive_item_id);
                        localAtt[driveItemTag] = graphAtt;
                        localAtt[webUrlTag] = graphAtt.webUrl;
                        localAtt[nameTag] = graphAtt.name;
                        localAtt[downloadUrlTag] =
                            graphAtt['@microsoft.graph.downloadUrl'];
                        localAtt[iconTag] = getFileIcon(graphAtt.file.mimeType);
                    });
                }
                finally {
                    updateAttachments(d => {
                        const localAtt = d.find(a => a.drive_id === attachment.drive_id &&
                            a.drive_item_id === attachment.drive_item_id);
                        localAtt[loadingTag] = false;
                    });
                }
            }
        })();
    }, [item.Attachments]);
    useEffect(function localAttachmentsChangedEffect() {
        onAttachmentsChanged === null || onAttachmentsChanged === void 0 ? void 0 : onAttachmentsChanged(item, attachments);
    }, [attachments]);
    const [site] = useCurrentSite();
    const disabled = useMemo(function checkEntityStorageOnMount() {
        var _a, _b;
        if (!((_a = site.Drives[item.__metadata.type]) === null || _a === void 0 ? void 0 : _a.DriveId) ||
            !((_b = site.Drives[item.__metadata.type]) === null || _b === void 0 ? void 0 : _b.DriveItemId)) {
            return true;
        }
    }, [item.id]);
    return (React.createElement(attachmentsContext.Provider, { value: {
            attachments,
            updateAttachments,
            item,
            disabled,
        } }, children));
}
/**
 * Hook that uses the attachments array from the nearest {@link AttachmentsContext}.
 *
 * @hook
 * @category RAPID Application
 */
export function useAttachmentContext() {
    const { attachments, updateAttachments, item, disabled } = useContext(attachmentsContext);
    return { attachments, updateAttachments, item, disabled };
}
export function useAttachments() {
    const graphClient = useGraphAuthenticatedFetch('Files.ReadWrite');
    const graphEndpoint = useGraphEndpoint('Files.ReadWrite');
    const { attachments, updateAttachments, item } = useContext(attachmentsContext);
    const [site] = useCurrentSite();
    let fileFolder = 'Attachments';
    let driveId = item.drive_id;
    let folderId = item.folder_id;
    if (!driveId || !folderId) {
        const driveDetails = site.Drives[item.__metadata.type];
        // TODO The temp_attachments should be in the
        // root of the document storage. Not in the entity folder.
        // You need to find the root document storage folder and drive id;
        driveId = driveDetails === null || driveDetails === void 0 ? void 0 : driveDetails.DriveId;
        folderId = driveDetails === null || driveDetails === void 0 ? void 0 : driveDetails.DriveItemId;
        fileFolder = 'temp_attachments';
    }
    async function uploadAttachments(files) {
        var _a, _b;
        for (const file of Array.from(files)) {
            const blobUrl = file.type.includes('image') && URL.createObjectURL(file);
            updateAttachments(d => {
                d.push({
                    [blobTag]: file,
                    [blobUrlTag]: blobUrl,
                    [isUploadingTag]: true,
                    [percentTag]: null,
                    [nameTag]: file.name,
                    [iconTag]: getFileIcon(file.type),
                });
            });
        }
        for (const file of Array.from(files)) {
            let fileName = (_b = (_a = file.name.match(/(?<name>.*)\..*/)) === null || _a === void 0 ? void 0 : _a.groups) === null || _b === void 0 ? void 0 : _b.name;
            await new UploadSession(graphClient, driveId, folderId, `${fileFolder}/${fileName !== null && fileName !== void 0 ? fileName : file.name}`, file)
                .on('start', () => {
                updateAttachments(d => {
                    const attachment = d.find(i => i[blobTag] === file);
                    attachment[percentTag] = 0;
                });
            })
                .on('progress', percent => {
                updateAttachments(d => {
                    const attachment = d.find(i => i[blobTag] === file);
                    attachment[percentTag] = percent;
                });
            })
                .on('end', (driveItem) => {
                updateAttachments(d => {
                    const attachment = d.find(i => i[blobTag] === file);
                    attachment.drive_id = driveItem.parentReference.driveId;
                    attachment.drive_item_id = driveItem.id;
                    delete attachment[percentTag];
                    URL.revokeObjectURL(attachment[blobUrlTag]);
                });
                try {
                    (async function () {
                        var _a;
                        const thumbnails = await graphEndpoint.drives[driveItem.parentReference.driveId].items[driveItem.id].thumbnails.getJson();
                        if (!!((_a = thumbnails.value) === null || _a === void 0 ? void 0 : _a.length)) {
                            updateAttachments(d => {
                                const attachment = d.find(i => i[blobTag] === file);
                                attachment[blobUrlTag] = thumbnails.value[0].small.url;
                            });
                        }
                    })();
                }
                catch (e) {
                    console.log(e);
                }
            })
                .sendAllChunks();
        }
    }
    async function removeAttachment(attachment) {
        const delAtt = await graphEndpoint.drives[attachment.drive_id].items[attachment.drive_item_id].delete();
        updateAttachments(d => {
            const ind = d.findIndex(i => i[blobTag] === attachment[blobTag]);
            if (ind !== -1) {
                d.splice(ind, 1);
            }
        });
    }
    return [attachments, uploadAttachments, removeAttachment];
}
