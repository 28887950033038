import { usePowerBIReport } from "@rapid/sdk";
import React, { useState } from "react";
import { useParams } from "react-router";
import { models } from 'powerbi-client';
import { IReportParams } from "../../components/dataTypes";
import { CommandBar, CommandBarCenter, CommandBarLeft, CommandBarRight } from "../../components/commandbar/commandBar";
import { Breadcrumbs } from "../../sdk/breadcrumbs/breadcrumbs";
import { useBreadcrumbText } from "../../utils/breadcrumbText";
import { EditPreview } from "../../sdk/editPreview/editPreview";
import { EmbedPBIReport } from "../../components/powerBIReport/embedPBIReport";

import './report.scss';

export interface IPBIReportProps {
  reportId?: string;
}

export function PBIReport(props: React.PropsWithoutRef<IPBIReportProps>) {
  const { reportId } = useParams<IReportParams>();
  const [report] = usePowerBIReport(props.reportId ?? reportId);
  const [viewMode, setViewMode] = useState<models.ViewMode>(models.ViewMode.View);

  return (
    <>
      <CommandBar>
        <CommandBarLeft>
          <Breadcrumbs breadcrumbText={useBreadcrumbText()} />
        </CommandBarLeft>
        {report?.reportType.toLowerCase() === 'powerbireport' &&
        <CommandBarCenter>
          <EditPreview 
            onEditClicked={() => setViewMode(models.ViewMode.Edit)}
            onPreviewClicked={() => setViewMode(models.ViewMode.View)}
          />
        </CommandBarCenter>
        }
        <CommandBarRight>
        </CommandBarRight>
      </CommandBar>
      <div className='content embedded-report'>
        <EmbedPBIReport reportId={props.reportId} viewMode={viewMode} />
      </div>
    </>
  );
}