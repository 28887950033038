import { returnField, useAdaptiveData, useAdaptiveFormContext, } from '../adaptive-form-context';
export function useCascadingLookups(elementRef) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l;
    const [context] = useAdaptiveFormContext();
    const formElement = returnField(elementRef, context.formSchema);
    const data = useAdaptiveData();
    /* FIXME this is currently just using the first binding */
    const lookupBinding = (_b = (_a = formElement.attributes) === null || _a === void 0 ? void 0 : _a.lookupBindings) === null || _b === void 0 ? void 0 : _b[0];
    let linkedTo;
    let view;
    if (!!lookupBinding && !!(data === null || data === void 0 ? void 0 : data[lookupBinding === null || lookupBinding === void 0 ? void 0 : lookupBinding.LookupValueSource])) {
        const targetElement = returnField(lookupBinding.LookupValueSource, context.formSchema);
        linkedTo = `${(_c = targetElement.attributes) === null || _c === void 0 ? void 0 : _c.lookupList}/${(_e = (_d = data[lookupBinding.LookupValueSource]) === null || _d === void 0 ? void 0 : _d.value) !== null && _e !== void 0 ? _e : data[lookupBinding.LookupValueSource]}`;
        const listName = (_h = (_g = (_f = targetElement.attributes) === null || _f === void 0 ? void 0 : _f.columnName.match(/(?<list>.*)_id/)) === null || _g === void 0 ? void 0 : _g.groups) === null || _h === void 0 ? void 0 : _h.list;
        view = !!listName
            ? (_k = (_j = data[lookupBinding.LookupValueSource]) === null || _j === void 0 ? void 0 : _j.label) !== null && _k !== void 0 ? _k : data[`${lookupBinding.LookupValueSource}_label`] + ' - ' + listName
            : data[lookupBinding.LookupValueSource];
    }
    if (!!((_l = lookupBinding === null || lookupBinding === void 0 ? void 0 : lookupBinding.Fields) === null || _l === void 0 ? void 0 : _l.length)) {
        lookupBinding.Fields.length === 2
            ? linkedTo + `${lookupBinding.Fields[0]}=${lookupBinding.Fields[1]}`
            : linkedTo + lookupBinding.Fields[0];
    }
    if (!!linkedTo) {
        return [linkedTo, view];
    }
    return [];
}
