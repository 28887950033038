import { ColumnType } from './column-type';
import { ModeFlags } from './mode-flags';
import { NotificationType } from './notification-type';
import { WebhookType } from './webhook-type';
import { SubqueryFormat } from './subquery-type';
export var ItemAction;
(function (ItemAction) {
    ItemAction[ItemAction["None"] = 0] = "None";
    ItemAction[ItemAction["Remove"] = 1] = "Remove";
    ItemAction[ItemAction["Add"] = 2] = "Add";
    ItemAction[ItemAction["Update"] = 3] = "Update";
})(ItemAction || (ItemAction = {}));
export var NotesType;
(function (NotesType) {
    NotesType["Comment"] = "Comment";
    NotesType["Mention"] = "Mention";
})(NotesType || (NotesType = {}));
export var DependencyType;
(function (DependencyType) {
    DependencyType[DependencyType["FinishToStart"] = 0] = "FinishToStart";
    DependencyType[DependencyType["StartToStart"] = 1] = "StartToStart";
    DependencyType[DependencyType["FinishToFinish"] = 2] = "FinishToFinish";
    DependencyType[DependencyType["StartToFinish"] = 3] = "StartToFinish";
})(DependencyType || (DependencyType = {}));
/** Describes the different types of timeline events for an item */
export var TimelineEventType;
(function (TimelineEventType) {
    TimelineEventType[TimelineEventType["Email"] = 0] = "Email";
    TimelineEventType[TimelineEventType["Note"] = 1] = "Note";
    TimelineEventType[TimelineEventType["System"] = 2] = "System";
    TimelineEventType[TimelineEventType["ItemUpdate"] = 3] = "ItemUpdate";
    TimelineEventType[TimelineEventType["ItemCreate"] = 4] = "ItemCreate";
    TimelineEventType["DiffOperation"] = "DiffOperation";
})(TimelineEventType || (TimelineEventType = {}));
export * from './field-type';
export * from './default-fields';
export * from './adaptive-form';
export * from './test-plan';
import * as PowerBI_1 from './powerbi-models';
export { PowerBI_1 as PowerBI };
import * as Xmla_1 from './xmla-models';
export { Xmla_1 as Xmla };
import * as QueryBuilder_1 from './query-builder';
export { QueryBuilder_1 as QueryBuilder };
export { ColumnType, WebhookType, SubqueryFormat, ModeFlags, NotificationType };
