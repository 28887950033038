import React from 'react';
import { useComposedClassName } from '../use-composed-class-names';
export function Card({ className, type = 'default', hover, size = 'small', children, }) {
    const cssClass = useComposedClassName(function* () {
        yield 'rp-card';
        yield className;
        yield `rp-card-${type}`;
        yield hover && 'rp-card-hover';
        yield `rp-card-${size}`;
        yield 'rp-branding-boxshadow';
    }, [className, type, hover, size]);
    return React.createElement("div", { className: cssClass }, children);
}
export function CardBody({ children }) {
    return React.createElement("div", { className: "rp-card-body" }, children);
}
export function CardHeader({ children }) {
    return React.createElement("div", { className: "rp-card-header" }, children);
}
export function CardFooter({ children }) {
    return React.createElement("div", { className: "rp-card-footer" }, children);
}
