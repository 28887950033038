import React from 'react';
import { AdaptiveRenderer } from './adaptive-renderer';
import { useAdaptiveFormContext } from '.';
/*
  TODO

  This guy is close.

  We need it to:
  - Be called AdaptiveForm
  - Handle Arrays and Objest as value types
  - Check if conditions work...
*/
export function AdaptiveForm({ onChange, value, $children, dataType, id, direction, }) {
    const [context] = useAdaptiveFormContext();
    const isArray = Array.isArray(value);
    const isValueObject = typeof (value === null || value === void 0 ? void 0 : value[0]) === 'object' &&
        !Array.isArray(value === null || value === void 0 ? void 0 : value[0]) &&
        (value === null || value === void 0 ? void 0 : value[0]) !== null;
    const onChangeInner = (index) => (el, val) => {
        if (isArray && index !== undefined) {
            const newVal = [...value];
            newVal[index] = val;
            onChange(newVal);
            return;
        }
        const newVal = { ...value, [el.id]: val };
        onChange(newVal);
    };
    const onChangeLayout = (index) => (val) => {
        if (isArray) {
            const newVal = [...value];
            newVal.splice(index, 1, val);
            onChange(newVal);
            return;
        }
    };
    if (isArray && !isValueObject) {
        return (React.createElement("form", { className: "AdaptiveForm" }, $children.map((c, ind) => {
            return (React.createElement(AdaptiveRenderer, { key: c.id, form: {
                    $type: 'Layout.Section',
                    id: id,
                    attributes: {
                        direction: direction
                    },
                    $children: [c],
                }, data: { [c.id]: value[ind] }, onChange: onChangeInner(ind), components: context.components }));
        })));
    }
    if (isArray && isValueObject) {
        return (React.createElement(React.Fragment, null, $children.map((c, ind) => (React.createElement(AdaptiveForm, { ...c, key: c.id, onChange: onChangeLayout(ind), value: value[ind], attributes: {
                dataType: dataType,
                direction: direction,
            } })))));
    }
    return (React.createElement(AdaptiveRenderer, { form: {
            $type: 'Layout.Section',
            id: id,
            attributes: {
                direction: direction
            },
            $children,
        }, data: value, onChange: onChangeInner(), components: context.components }));
}
