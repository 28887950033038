import React from "react";
/**
 * Component: SiteFooterLogo
 *
 * Contains the logo content in the SiteFooter
 */
export function SiteFooterLogo({ children }) {
    return React.createElement("div", { className: "rp-site-footer-logo" }, children);
}
/**
 * Component: SiteFooterCopy
 *
 * Contains the copyright content in the SiteFooter
 */
export function SiteFooterCopy({ children }) {
    return React.createElement("div", { className: "rp-site-footer-copy" }, children);
}
/**
 * Component: SiteFooter
 *
 * The RAPID Platform site-wide footer.  Contains two sub-components:
 *
 * - SiteFooterLogo,
 * - SiteFooterCopy
 */
export function SiteFooter({ children }) {
    return React.createElement("div", { className: "rp-site-footer" }, children);
}
