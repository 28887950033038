import {
  useCurrentSite,
  useCurrentSiteEndpoint,
  usePowerBIContext,
  usePowerBIDatasets,
} from '@rapid/sdk';
import { Card, Popconfirm, Spin } from 'antd';
import {
  IXmlaRelationship,
  IXmlaTable,
} from '@rapid/data-model/lib/xmla-models';
import AddDatasetForm from './forms/addDatasetForm';
import { LoadingOutlined } from '@ant-design/icons';
import { useNavigation } from '../../utils/Navigation';
import { useBreadcrumbText } from '../../utils/breadcrumbText';
import { useEffect, useState } from 'react';
import { PowerBI, Xmla } from '@rapid/data-model';
import {feedbackWrapper} from '../../utils/feedbackWrapper';
import {
  CommandBar,
  CommandBarCenter,
  CommandBarLeft,
  CommandBarRight,
} from '../../components/commandbar/commandBar';
import { Breadcrumbs } from '../../sdk/breadcrumbs/breadcrumbs';
import ExponentialCallback from '../../utils/exponentialCallback';

export function PBIDatasetsList() {
  const ep = useCurrentSiteEndpoint();
  const [site] = useCurrentSite();
  const [powerBIContext] = usePowerBIContext();
  const [datasets] = usePowerBIDatasets();
  const { reports, fetchDatasets } = powerBIContext;
  const callWithRetry = ExponentialCallback();
  const { Navigate } = useNavigation();
  const breadcrumbText = useBreadcrumbText();
  const [addDatasetInputVisible, setAddDatasetInputVisible] =
    useState<boolean>(false);
  const [pendingDatasets, setPendingDatasets] = useState<string[]>([]);

  const reportsCount = (datasetId: string): string => {
    const count = Array.from(reports).filter(
      report => report.datasetId === datasetId,
    ).length;
    return count === 1 ? `${count} Report` : `${count} Reports`;
  };

  async function addDataset(name: string) {
    setAddDatasetInputVisible(false);
    const Datasets: Xmla.IXmlaDataset[] = [
      {
        name: name,
        tables: [],
        relationships: [],
        expressions: []
      },
    ];
    await feedbackWrapper(
      () => ep.Xmla.Datasets.postJson({}, { Datasets: Datasets }),
      `Adding ${name}...`,
      `${name} added!`,
      `Unable to add ${name}.`,
    );

    await fetchDatasets().then(() => {
      setPendingDatasets(pd => pd.filter(ds => ds !== name));
    });
  }

  async function deleteDataset(dataset: PowerBI.Dataset) {
    feedbackWrapper(
      () => powerBIContext.deleteDataset([dataset]),
      `Deleting ${dataset.name}...`,
      `${dataset.name} deleted!`,
      `Unable to delete ${dataset.name}.`,
    );
  }

  useEffect(function componentDidMount() {
    fetchDatasets();
  }, []);

  return (
    <>
      <CommandBar>
        <CommandBarLeft>
          <Breadcrumbs breadcrumbText={breadcrumbText} />
        </CommandBarLeft>
        <CommandBarCenter></CommandBarCenter>
        <CommandBarRight>
          <button
            title="Add Dataset"
            className="button default margin-horizontal-small"
            onClick={() => setAddDatasetInputVisible(!addDatasetInputVisible)}
          >
            <i className="fal fa-plus" />
          </button>
        </CommandBarRight>
      </CommandBar>
      <div className="content card-list">
        {Array.from(datasets).map(dataset => (
          <Card
            key={dataset.name}
            cover={<i className="fal fa-database fa-7x" />}
            hoverable
            extra={
              <Popconfirm
                title={`Delete ${dataset.name}`}
                onConfirm={e => {
                  e?.stopPropagation();
                  deleteDataset(dataset);
                }}
                onCancel={e => e?.stopPropagation()}
                okText="Delete"
                okButtonProps={{ danger: true }}
                cancelText="No"
              >
                <button
                  title="Delete"
                  className="delete-button"
                  onClick={ev => ev.stopPropagation()}
                >
                  <i className="fal fa-trash-alt" />
                </button>
              </Popconfirm>
            }
            actions={[
              <a
                className="report-link"
                onClick={ev => {
                  ev.stopPropagation();
                  Navigate(`/newreport:${dataset.id}`);
                }}
              >
                Create New Report
              </a>,
            ]}
            onClick={ev => {
              ev.stopPropagation();
              Navigate(`/pbidataset:${dataset.id}`);
            }}
          >
            <Card.Meta title={dataset.name} />
            <div className="report-type">{reportsCount(dataset.id)}</div>
          </Card>
        ))}
        {pendingDatasets?.map(dataset => (
          <Card
            key={dataset}
            cover={
              <Spin
                indicator={
                  <LoadingOutlined
                    style={{ fontSize: 90, color: 'var(--colour-primary)' }}
                    spin
                  />
                }
              />
            }
            hoverable
          >
            <Card.Meta title={dataset} />
            <div className="report-type">...Loading</div>
          </Card>
        ))}
      </div>
      <AddDatasetForm
        visible={addDatasetInputVisible}
        onSubmit={(name: string) => {
          pendingDatasets.push(name);
          setPendingDatasets(pendingDatasets);
          addDataset(name);
        }}
        onCancel={() => setAddDatasetInputVisible(false)}
      />
    </>
  );
}
