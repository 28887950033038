import React from 'react';
import sanitizeHtmlReal from 'sanitize-html';
export const sanitizeRules = {
    allowedTags: sanitizeHtmlReal.defaults.allowedTags.concat([
        'h1',
        'h2',
        'span',
        'img',
        'table',
        'a',
    ]),
    allowedAttributes: {
        tr: ['class'],
        td: ['class', 'colspan'],
        table: ['class'],
        a: ['class', 'href', 'target', 'data-item-ref'],
        span: [
            'class',
            'data-index',
            'data-id',
            'data-value',
            'data-denotation-char',
        ],
        img: ['src', 'width', 'height'],
    },
    allowedSchemesByTag: {
        img: ['http', 'https'],
        a: ['https', 'mailto'],
    },
};
export function sanitizeHtml(input) {
    return sanitizeHtmlReal(input, sanitizeRules);
}
export function SanitizedDiv({ html, className }) {
    return (React.createElement("div", { className: className, dangerouslySetInnerHTML: { __html: sanitizeHtml(html) } }));
}
