import React, { useContext, useEffect } from 'react';
import { generateEmailSlug, useRapidApplication, } from '../index';
import { useCurrentSiteEndpoint } from '../use-current-site-endpoint';
import { useImmer } from '../use-immer';
import { useList } from '../use-list';
import { uniqueId } from '../utils/unique-id';
import { transformAll } from '@demvsystems/yup-ast';
const Context = React.createContext([]);
/**
 *
 */
export function ManagedListItemContext({ children, id, validate, list, }) {
    /*
     *
     */
    const [parentContext, updateParentContext] = useListItemContext();
    const ep = useCurrentSiteEndpoint();
    const [innerList] = useList(list);
    async function fetchItem() {
        updateContext(draft => {
            draft.loading = true;
        });
        let item;
        try {
            if (!id) {
                updateContext(draft => {
                    draft.item = {
                        __metadata: {
                            type: innerList.ListName,
                        },
                    };
                });
                return;
            }
            item = await ep.Lists[innerList.ListName].Items[id].getJson();
            updateContext(draft => {
                draft.item = item;
            });
        }
        finally {
            updateContext(draft => {
                draft.loading = false;
            });
        }
    }
    const validateItem = (recursive) => {
        if (validate) {
            let errors = [];
            try {
                if (context.validation.length) {
                    const validateFn = transformAll(context.validation[0]);
                    errors = validateFn.validateSync(context.item, { abortEarly: false });
                }
                updateContext(d => {
                    d.validationErrors = [];
                });
                errors = [];
            }
            catch (e) {
                const innerErrors = e.errors.map(error => ({
                    list: context.list.ListName,
                    error,
                }));
                updateContext(d => {
                    d.validationErrors = innerErrors;
                });
                errors = innerErrors;
            }
            finally {
                for (const subContext of Object.values(context.subContexts)) {
                    if (typeof recursive === 'function' ? recursive(subContext) : recursive) {
                        errors = errors.concat(subContext.validateItem(recursive));
                    }
                }
            }
            return errors;
        }
    };
    const saveItem = async (recursive) => {
        try {
            for (const subContext of Object.values(context.subContexts)) {
                if (typeof recursive === 'function' ? recursive(subContext) : recursive) {
                    await subContext.saveItem(recursive);
                }
            }
            if (id) {
                await ep.Lists[innerList.ListName].Items[id].putJson(undefined, context.item);
            }
            else {
                const newItem = await ep.Lists[innerList.ListName].Items.postJson(undefined, context.item);
                updateContext(draft => {
                    Object.assign(draft.item, newItem);
                });
            }
        }
        finally {
            updateContext(draft => {
                draft.loading = false;
            });
        }
    };
    const updateItem = d => {
        updateContext(draft => {
            d(draft.item);
        });
    };
    const fetchTimeline = async (excludeDiffNotes = false) => {
        updateContext(draft => {
            draft.timelineLoading = true;
        });
        try {
            const timeline = await ep.Lists[context.list.ListName].Items[context.id].Timeline.getJson({
                excludeDiffNotes: excludeDiffNotes.toString(),
            });
            updateContext(draft => {
                draft.timeline = timeline;
            });
        }
        finally {
            updateContext(draft => {
                draft.timelineLoading = false;
            });
        }
    };
    const [context, updateContext] = useImmer({
        $id: uniqueId('listItemContext'),
        list: innerList,
        id,
        loading: false,
        item: undefined,
        validation: [],
        validationErrors: [],
        subContexts: {},
    });
    useEffect(function fetchItemEffect() {
        fetchItem();
    }, [list, id]);
    useEffect(function contextDidChangeEffect() {
        if (parentContext) {
            updateParentContext(draft => {
                draft.subContexts[context.$id] = {
                    ...context,
                    fetchTimeline,
                    saveItem,
                    updateItem,
                    validateItem,
                    fetchItem,
                };
            });
        }
        return function contextDidCleanupEffect() {
            if (parentContext) {
                updateParentContext(draft => {
                    delete draft.subContexts[context.$id];
                });
            }
        };
    }, [context]);
    return (React.createElement(Context.Provider, { value: [
            {
                ...context,
                fetchTimeline,
                saveItem,
                updateItem,
                validateItem,
                fetchItem,
            },
            updateContext,
        ] }, children));
}
export function useListItemContext() {
    const [context, updateContext] = useContext(Context);
    return [context, updateContext];
}
export function useSingleListItemTimeline() {
    const [context] = useListItemContext();
    return [context.timeline, context.fetchTimeline, context.timelineLoading];
}
export function useSingleListItemEmails(searchAddress) {
    const [context, updateContext] = useListItemContext();
    const [app] = useRapidApplication();
    const ep = useCurrentSiteEndpoint();
    async function fetchEmails() {
        updateContext(draft => {
            draft.timelineLoading = true;
        });
        try {
            let emailEndpoint = ep.organization.emails[generateEmailSlug(app.tenant, app.site, context.list.ListName, context.item.id)];
            if (searchAddress) {
                emailEndpoint = emailEndpoint[searchAddress];
            }
            const emails = await emailEndpoint.getJson();
            updateContext(draft => {
                draft.emails = emails.value;
            });
        }
        finally {
            updateContext(draft => {
                draft.timelineLoading = false;
            });
        }
    }
    return [context.emails, fetchEmails, context.timelineLoading];
}
