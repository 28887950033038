import { usePBIDatasetContext } from '../datasetContext';
import { Card, Button } from 'antd';
import { PBIRelationship } from './relationshipCard.Column';
import { useState } from 'react';
import { Xmla } from '@rapid/data-model';
import AddRelationshipForm from '../forms/addRelationshipForm';

import './cards.scss';

export function PBIDatasetRelationships() {
  const [pbiDatasetContext, updatePBIDatasetContext] = usePBIDatasetContext();
  const { dataset } = pbiDatasetContext;

  const [relationshipVisible, setRelationshipVisible] =
    useState<boolean>(false);

  function addRelationship(relationship: Xmla.IXmlaRelationship) {
    updatePBIDatasetContext(d => {
      d.dataset.relationships?.push(relationship);
    });
  }

  function removeRelationship(name: string) {
    updatePBIDatasetContext(d => {
      const relationshipIndex = d.dataset.relationships?.findIndex(
        relationship => relationship.name === name,
      );
      d.dataset.relationships?.splice(relationshipIndex!, 1);
    });
  }

  return (
    <>
      <Card key="relationships" title="Relationships" hoverable>
        <div className="table-body">
          <div className="table-columns">
            {dataset.relationships?.map(relationship => (
              <PBIRelationship
                key={relationship.name}
                relationship={relationship}
                onDeleteClicked={() => removeRelationship(relationship.name)}
              />
            ))}
          </div>
          <Button
            className="add-column-button"
            onClick={() => {
              setRelationshipVisible(true);
            }}
          >
            Add Relationship
          </Button>
        </div>
      </Card>
      <AddRelationshipForm
        visible={relationshipVisible}
        tables={dataset.tables ?? [] as Xmla.IXmlaTable[]}
        onSubmit={relationship => {
          addRelationship(relationship);
          setRelationshipVisible(false);
        }}
        onCancel={() => setRelationshipVisible(false)}
      />
    </>
  );
}
