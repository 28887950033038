import React, { useMemo } from 'react';
import { useAdaptiveData, useAdaptiveField } from './index';
import { useComposedClassName } from '@rapid/sdk';
import { transformAll } from '@demvsystems/yup-ast';
export function FormElement({ elementRef, disabled }) {
    const [element, value, helpers, Component, selectedValue] = useAdaptiveField(elementRef);
    const data = useAdaptiveData();
    const schema = useMemo(function transformElementConditionSchema() {
        if (Array.isArray(element.conditions)) {
            const allValidation = element.conditions.reduce((prev, curr) => {
                const next = {
                    [curr.elementRef]: curr.validation,
                    ...prev,
                };
                return next;
            }, {});
            const schema = transformAll([
                ['yup.object'],
                ['yup.shape', allValidation],
            ]);
            return schema;
        }
    }, [element.conditions]);
    const isValid = useMemo(function runValidationSchema() {
        var _a;
        if (!!schema) {
            const isValid = (_a = schema === null || schema === void 0 ? void 0 : schema.isValidSync(data)) !== null && _a !== void 0 ? _a : true;
            return isValid;
        }
        return true;
    }, [data, schema]);
    const fieldClass = useComposedClassName(function* () {
        yield 'Field';
        yield isValid ? '' : 'hidden';
    }, [isValid, element === null || element === void 0 ? void 0 : element.conditions]);
    if (!!Component) {
        return (React.createElement("div", { className: fieldClass, id: element.id },
            React.createElement(Component, { "$type": element.$type, id: element.id, value: value !== null && value !== void 0 ? value : element.defaultValue, onChange: helpers.onChange, onBlur: helpers.onBlur, label: element.label, placeholder: element.placeholder, selectedValue: selectedValue, disabled: disabled, ...element.attributes, "$children": element.$type.includes('AdaptiveForm')
                    ? element.$children
                    : undefined }, Array.isArray(element.$children) &&
                !element.$type.includes('AdaptiveForm') &&
                element.$children.map(child => (React.createElement(FormElement, { disabled: disabled, key: child.id, elementRef: child.id }))))));
    }
    else {
        return React.createElement("div", null,
            elementRef,
            " does not exist");
    }
}
