import { Skeleton, SkeletonProps, Card } from 'antd';
import SkeletonButton from 'antd/lib/skeleton/Button';

import './cards.scss';

type SkeletonTableProps = SkeletonProps & {
  rowCount?: number;
};

export default function SkeletonCard({
  active = true,
  rowCount = 5,
  className,
}: SkeletonTableProps): JSX.Element {
  const rows = Array.from(Array(rowCount).keys());

  return (
    <Card
      title={
        <Skeleton
        title={{width:150}}
          active={active}
          paragraph={false}
          className={className}
        />
      }
      hoverable
    >
      <div className="table-body">
        <div className="table-columns">
          {rows.map(r => (
            <Skeleton
              title={{width:Math.floor((Math.random() * 100) + 100)}}
              active={active}
              paragraph={false}
              className={className}
            />
          ))}
        </div>
         <SkeletonButton
          className="add-column-button"
          active
          //size="large"
        />
      </div>
    </Card>
  );
}
