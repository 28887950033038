import { useState } from 'react';

export interface ITableCardColumnProps {
  name: string;
  source: string;
  expression: string;
  dataType: string;
  onDeleteClicked(): void;
}

export function TableCardColumn(
  props: React.PropsWithChildren<ITableCardColumnProps>,
) {
  const [hover, useHover] = useState(false);

  function Hover(bool: boolean) {
    useHover(bool);
  }

  function getColumnIcon(dataType: string): string {
    switch (dataType) {
      case 'String':
        return 'fa-text';
      case 'DateTime':
        return 'fa-calendar';
      case 'Int64':
      case 'Decimal':
      case 'Double':
        return 'fa-sigma';
      case 'Boolean':
        return 'fa-toggle-on';
      default:
        return 'fa-circle';
    }
  }
  return (
    <div
      className="column"
      onMouseOver={() => Hover(true)}
      onMouseLeave={() => Hover(false)}
    >
      <div className="column-icon">
          <i className={"fal " + getColumnIcon(props.dataType)} />
      </div>
      <div title={props.name} className="column-text">
        {props.name}
      </div>
      {hover && (
        <div className="column-delete">
          <button
              title="Delete"
              className="delete-button"
              onClick={props.onDeleteClicked}
            >
              <i className="fal fa-trash-alt" />
            </button>
        </div>
      )}
    </div>
  );
}
