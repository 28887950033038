import { useComposedClassName } from '@rapid/sdk';
import React from 'react';
import { descriptionTag, fieldAttributesSchemaTag, iconTag, titleTag, } from '..';
export function HeadingLayout({ label, type, underline, }) {
    const headingClass = useComposedClassName(function* () {
        yield 'Layout Heading';
        yield type;
        if (underline)
            yield 'underline';
    }, [type, underline]);
    return React.createElement(type !== null && type !== void 0 ? type : 'h1', { className: headingClass }, label);
}
HeadingLayout[titleTag] = 'Heading';
HeadingLayout[descriptionTag] =
    'A heading element for adding sectioning off forms';
HeadingLayout[iconTag] = 'fa-heading';
HeadingLayout[fieldAttributesSchemaTag] = [
    {
        $type: 'Input.Text',
        id: 'label',
        label: 'Label',
    },
    {
        $type: 'Input.Choice',
        id: '$.attributes.type',
        label: 'Heading type',
        defaultValue: 'h1',
        attributes: {
            choices: [
                { Text: 'h1' },
                { Text: 'h2' },
                { Text: 'h3' },
                { Text: 'h4' },
                { Text: 'h5' },
                { Text: 'h6' },
            ],
        },
    },
    {
        $type: 'Input.Boolean',
        id: '$.attributes.underline',
        label: 'Show underline',
    },
];
