export const iconNamesObj = {
    500: "fa-500px",
    abacus: "fa-abacus",
    accessibleIcon: "fa-accessible-icon",
    accusoft: "fa-accusoft",
    acorn: "fa-acorn",
    acquisitionsIncorporated: "fa-acquisitions-incorporated",
    ad: "fa-ad",
    addressBook: "fa-address-book",
    addressCard: "fa-address-card",
    adjust: "fa-adjust",
    adn: "fa-adn",
    adobe: "fa-adobe",
    adversal: "fa-adversal",
    affiliatetheme: "fa-affiliatetheme",
    airConditioner: "fa-air-conditioner",
    airFreshener: "fa-air-freshener",
    airbnb: "fa-airbnb",
    alarmClock: "fa-alarm-clock",
    alarmExclamation: "fa-alarm-exclamation",
    alarmPlus: "fa-alarm-plus",
    alarmSnooze: "fa-alarm-snooze",
    album: "fa-album",
    albumCollection: "fa-album-collection",
    algolia: "fa-algolia",
    alicorn: "fa-alicorn",
    alien: "fa-alien",
    alienMonster: "fa-alien-monster",
    alignCenter: "fa-align-center",
    alignJustify: "fa-align-justify",
    alignLeft: "fa-align-left",
    alignRight: "fa-align-right",
    alignSlash: "fa-align-slash",
    alipay: "fa-alipay",
    allergies: "fa-allergies",
    amazon: "fa-amazon",
    amazonPay: "fa-amazon-pay",
    ambulance: "fa-ambulance",
    americanSignLanguageInterpreting: "fa-american-sign-language-interpreting",
    amilia: "fa-amilia",
    ampGuitar: "fa-amp-guitar",
    analytics: "fa-analytics",
    anchor: "fa-anchor",
    android: "fa-android",
    angel: "fa-angel",
    angellist: "fa-angellist",
    angleDoubleDown: "fa-angle-double-down",
    angleDoubleLeft: "fa-angle-double-left",
    angleDoubleRight: "fa-angle-double-right",
    angleDoubleUp: "fa-angle-double-up",
    angleDown: "fa-angle-down",
    angleLeft: "fa-angle-left",
    angleRight: "fa-angle-right",
    angleUp: "fa-angle-up",
    angry: "fa-angry",
    angrycreative: "fa-angrycreative",
    angular: "fa-angular",
    ankh: "fa-ankh",
    appStore: "fa-app-store",
    appStoreIos: "fa-app-store-ios",
    apper: "fa-apper",
    apple: "fa-apple",
    appleAlt: "fa-apple-alt",
    appleCrate: "fa-apple-crate",
    applePay: "fa-apple-pay",
    archive: "fa-archive",
    archway: "fa-archway",
    arrowAltCircleDown: "fa-arrow-alt-circle-down",
    arrowAltCircleLeft: "fa-arrow-alt-circle-left",
    arrowAltCircleRight: "fa-arrow-alt-circle-right",
    arrowAltCircleUp: "fa-arrow-alt-circle-up",
    arrowAltDown: "fa-arrow-alt-down",
    arrowAltFromBottom: "fa-arrow-alt-from-bottom",
    arrowAltFromLeft: "fa-arrow-alt-from-left",
    arrowAltFromRight: "fa-arrow-alt-from-right",
    arrowAltFromTop: "fa-arrow-alt-from-top",
    arrowAltLeft: "fa-arrow-alt-left",
    arrowAltRight: "fa-arrow-alt-right",
    arrowAltSquareDown: "fa-arrow-alt-square-down",
    arrowAltSquareLeft: "fa-arrow-alt-square-left",
    arrowAltSquareRight: "fa-arrow-alt-square-right",
    arrowAltSquareUp: "fa-arrow-alt-square-up",
    arrowAltToBottom: "fa-arrow-alt-to-bottom",
    arrowAltToLeft: "fa-arrow-alt-to-left",
    arrowAltToRight: "fa-arrow-alt-to-right",
    arrowAltToTop: "fa-arrow-alt-to-top",
    arrowAltUp: "fa-arrow-alt-up",
    arrowCircleDown: "fa-arrow-circle-down",
    arrowCircleLeft: "fa-arrow-circle-left",
    arrowCircleRight: "fa-arrow-circle-right",
    arrowCircleUp: "fa-arrow-circle-up",
    arrowDown: "fa-arrow-down",
    arrowFromBottom: "fa-arrow-from-bottom",
    arrowFromLeft: "fa-arrow-from-left",
    arrowFromRight: "fa-arrow-from-right",
    arrowFromTop: "fa-arrow-from-top",
    arrowLeft: "fa-arrow-left",
    arrowRight: "fa-arrow-right",
    arrowSquareDown: "fa-arrow-square-down",
    arrowSquareLeft: "fa-arrow-square-left",
    arrowSquareRight: "fa-arrow-square-right",
    arrowSquareUp: "fa-arrow-square-up",
    arrowToBottom: "fa-arrow-to-bottom",
    arrowToLeft: "fa-arrow-to-left",
    arrowToRight: "fa-arrow-to-right",
    arrowToTop: "fa-arrow-to-top",
    arrowUp: "fa-arrow-up",
    arrows: "fa-arrows",
    arrowsAlt: "fa-arrows-alt",
    arrowsAltH: "fa-arrows-alt-h",
    arrowsAltV: "fa-arrows-alt-v",
    arrowsH: "fa-arrows-h",
    arrowsV: "fa-arrows-v",
    artstation: "fa-artstation",
    assistiveListeningSystems: "fa-assistive-listening-systems",
    asterisk: "fa-asterisk",
    asymmetrik: "fa-asymmetrik",
    at: "fa-at",
    atlas: "fa-atlas",
    atlassian: "fa-atlassian",
    atom: "fa-atom",
    atomAlt: "fa-atom-alt",
    audible: "fa-audible",
    audioDescription: "fa-audio-description",
    autoprefixer: "fa-autoprefixer",
    avianex: "fa-avianex",
    aviato: "fa-aviato",
    award: "fa-award",
    aws: "fa-aws",
    axe: "fa-axe",
    axeBattle: "fa-axe-battle",
    baby: "fa-baby",
    babyCarriage: "fa-baby-carriage",
    backpack: "fa-backpack",
    backspace: "fa-backspace",
    backward: "fa-backward",
    bacon: "fa-bacon",
    bacteria: "fa-bacteria",
    bacterium: "fa-bacterium",
    badge: "fa-badge",
    badgeCheck: "fa-badge-check",
    badgeDollar: "fa-badge-dollar",
    badgePercent: "fa-badge-percent",
    badgeSheriff: "fa-badge-sheriff",
    badgerHoney: "fa-badger-honey",
    bagsShopping: "fa-bags-shopping",
    bahai: "fa-bahai",
    balanceScale: "fa-balance-scale",
    balanceScaleLeft: "fa-balance-scale-left",
    balanceScaleRight: "fa-balance-scale-right",
    ballPile: "fa-ball-pile",
    ballot: "fa-ballot",
    ballotCheck: "fa-ballot-check",
    ban: "fa-ban",
    bandAid: "fa-band-aid",
    bandcamp: "fa-bandcamp",
    banjo: "fa-banjo",
    barcode: "fa-barcode",
    barcodeAlt: "fa-barcode-alt",
    barcodeRead: "fa-barcode-read",
    barcodeScan: "fa-barcode-scan",
    bars: "fa-bars",
    baseball: "fa-baseball",
    baseballBall: "fa-baseball-ball",
    basketballBall: "fa-basketball-ball",
    basketballHoop: "fa-basketball-hoop",
    bat: "fa-bat",
    bath: "fa-bath",
    batteryBolt: "fa-battery-bolt",
    batteryEmpty: "fa-battery-empty",
    batteryFull: "fa-battery-full",
    batteryHalf: "fa-battery-half",
    batteryQuarter: "fa-battery-quarter",
    batterySlash: "fa-battery-slash",
    batteryThreeQuarters: "fa-battery-three-quarters",
    battleNet: "fa-battle-net",
    bed: "fa-bed",
    bedAlt: "fa-bed-alt",
    bedBunk: "fa-bed-bunk",
    bedEmpty: "fa-bed-empty",
    beer: "fa-beer",
    behance: "fa-behance",
    behanceSquare: "fa-behance-square",
    bell: "fa-bell",
    bellExclamation: "fa-bell-exclamation",
    bellOn: "fa-bell-on",
    bellPlus: "fa-bell-plus",
    bellSchool: "fa-bell-school",
    bellSchoolSlash: "fa-bell-school-slash",
    bellSlash: "fa-bell-slash",
    bells: "fa-bells",
    betamax: "fa-betamax",
    bezierCurve: "fa-bezier-curve",
    bible: "fa-bible",
    bicycle: "fa-bicycle",
    biking: "fa-biking",
    bikingMountain: "fa-biking-mountain",
    bimobject: "fa-bimobject",
    binoculars: "fa-binoculars",
    biohazard: "fa-biohazard",
    birthdayCake: "fa-birthday-cake",
    bitbucket: "fa-bitbucket",
    bitcoin: "fa-bitcoin",
    bity: "fa-bity",
    blackTie: "fa-black-tie",
    blackberry: "fa-blackberry",
    blanket: "fa-blanket",
    blender: "fa-blender",
    blenderPhone: "fa-blender-phone",
    blind: "fa-blind",
    blinds: "fa-blinds",
    blindsOpen: "fa-blinds-open",
    blindsRaised: "fa-blinds-raised",
    blog: "fa-blog",
    blogger: "fa-blogger",
    bloggerB: "fa-blogger-b",
    bluetooth: "fa-bluetooth",
    bluetoothB: "fa-bluetooth-b",
    bold: "fa-bold",
    bolt: "fa-bolt",
    bomb: "fa-bomb",
    bone: "fa-bone",
    boneBreak: "fa-bone-break",
    bong: "fa-bong",
    book: "fa-book",
    bookAlt: "fa-book-alt",
    bookDead: "fa-book-dead",
    bookHeart: "fa-book-heart",
    bookMedical: "fa-book-medical",
    bookOpen: "fa-book-open",
    bookReader: "fa-book-reader",
    bookSpells: "fa-book-spells",
    bookUser: "fa-book-user",
    bookmark: "fa-bookmark",
    books: "fa-books",
    booksMedical: "fa-books-medical",
    boombox: "fa-boombox",
    boot: "fa-boot",
    boothCurtain: "fa-booth-curtain",
    bootstrap: "fa-bootstrap",
    borderAll: "fa-border-all",
    borderBottom: "fa-border-bottom",
    borderCenterH: "fa-border-center-h",
    borderCenterV: "fa-border-center-v",
    borderInner: "fa-border-inner",
    borderLeft: "fa-border-left",
    borderNone: "fa-border-none",
    borderOuter: "fa-border-outer",
    borderRight: "fa-border-right",
    borderStyle: "fa-border-style",
    borderStyleAlt: "fa-border-style-alt",
    borderTop: "fa-border-top",
    bowArrow: "fa-bow-arrow",
    bowlingBall: "fa-bowling-ball",
    bowlingPins: "fa-bowling-pins",
    box: "fa-box",
    boxAlt: "fa-box-alt",
    boxBallot: "fa-box-ballot",
    boxCheck: "fa-box-check",
    boxFragile: "fa-box-fragile",
    boxFull: "fa-box-full",
    boxHeart: "fa-box-heart",
    boxOpen: "fa-box-open",
    boxTissue: "fa-box-tissue",
    boxUp: "fa-box-up",
    boxUsd: "fa-box-usd",
    boxes: "fa-boxes",
    boxesAlt: "fa-boxes-alt",
    boxingGlove: "fa-boxing-glove",
    brackets: "fa-brackets",
    bracketsCurly: "fa-brackets-curly",
    braille: "fa-braille",
    brain: "fa-brain",
    breadLoaf: "fa-bread-loaf",
    breadSlice: "fa-bread-slice",
    briefcase: "fa-briefcase",
    briefcaseMedical: "fa-briefcase-medical",
    bringForward: "fa-bring-forward",
    bringFront: "fa-bring-front",
    broadcastTower: "fa-broadcast-tower",
    broom: "fa-broom",
    browser: "fa-browser",
    brush: "fa-brush",
    btc: "fa-btc",
    buffer: "fa-buffer",
    bug: "fa-bug",
    building: "fa-building",
    bullhorn: "fa-bullhorn",
    bullseye: "fa-bullseye",
    bullseyeArrow: "fa-bullseye-arrow",
    bullseyePointer: "fa-bullseye-pointer",
    burgerSoda: "fa-burger-soda",
    burn: "fa-burn",
    buromobelexperte: "fa-buromobelexperte",
    burrito: "fa-burrito",
    bus: "fa-bus",
    busAlt: "fa-bus-alt",
    busSchool: "fa-bus-school",
    businessTime: "fa-business-time",
    buyNLarge: "fa-buy-n-large",
    buysellads: "fa-buysellads",
    cabinetFiling: "fa-cabinet-filing",
    cactus: "fa-cactus",
    calculator: "fa-calculator",
    calculatorAlt: "fa-calculator-alt",
    calendar: "fa-calendar",
    calendarAlt: "fa-calendar-alt",
    calendarCheck: "fa-calendar-check",
    calendarDay: "fa-calendar-day",
    calendarEdit: "fa-calendar-edit",
    calendarExclamation: "fa-calendar-exclamation",
    calendarMinus: "fa-calendar-minus",
    calendarPlus: "fa-calendar-plus",
    calendarStar: "fa-calendar-star",
    calendarTimes: "fa-calendar-times",
    calendarWeek: "fa-calendar-week",
    camcorder: "fa-camcorder",
    camera: "fa-camera",
    cameraAlt: "fa-camera-alt",
    cameraHome: "fa-camera-home",
    cameraMovie: "fa-camera-movie",
    cameraPolaroid: "fa-camera-polaroid",
    cameraRetro: "fa-camera-retro",
    campfire: "fa-campfire",
    campground: "fa-campground",
    canadianMapleLeaf: "fa-canadian-maple-leaf",
    candleHolder: "fa-candle-holder",
    candyCane: "fa-candy-cane",
    candyCorn: "fa-candy-corn",
    cannabis: "fa-cannabis",
    capsules: "fa-capsules",
    car: "fa-car",
    carAlt: "fa-car-alt",
    carBattery: "fa-car-battery",
    carBuilding: "fa-car-building",
    carBump: "fa-car-bump",
    carBus: "fa-car-bus",
    carCrash: "fa-car-crash",
    carGarage: "fa-car-garage",
    carMechanic: "fa-car-mechanic",
    carSide: "fa-car-side",
    carTilt: "fa-car-tilt",
    carWash: "fa-car-wash",
    caravan: "fa-caravan",
    caravanAlt: "fa-caravan-alt",
    caretCircleDown: "fa-caret-circle-down",
    caretCircleLeft: "fa-caret-circle-left",
    caretCircleRight: "fa-caret-circle-right",
    caretCircleUp: "fa-caret-circle-up",
    caretDown: "fa-caret-down",
    caretLeft: "fa-caret-left",
    caretRight: "fa-caret-right",
    caretSquareDown: "fa-caret-square-down",
    caretSquareLeft: "fa-caret-square-left",
    caretSquareRight: "fa-caret-square-right",
    caretSquareUp: "fa-caret-square-up",
    caretUp: "fa-caret-up",
    carrot: "fa-carrot",
    cars: "fa-cars",
    cartArrowDown: "fa-cart-arrow-down",
    cartPlus: "fa-cart-plus",
    cashRegister: "fa-cash-register",
    cassetteTape: "fa-cassette-tape",
    cat: "fa-cat",
    catSpace: "fa-cat-space",
    cauldron: "fa-cauldron",
    ccAmazonPay: "fa-cc-amazon-pay",
    ccAmex: "fa-cc-amex",
    ccApplePay: "fa-cc-apple-pay",
    ccDinersClub: "fa-cc-diners-club",
    ccDiscover: "fa-cc-discover",
    ccJcb: "fa-cc-jcb",
    ccMastercard: "fa-cc-mastercard",
    ccPaypal: "fa-cc-paypal",
    ccStripe: "fa-cc-stripe",
    ccVisa: "fa-cc-visa",
    cctv: "fa-cctv",
    centercode: "fa-centercode",
    centos: "fa-centos",
    certificate: "fa-certificate",
    chair: "fa-chair",
    chairOffice: "fa-chair-office",
    chalkboard: "fa-chalkboard",
    chalkboardTeacher: "fa-chalkboard-teacher",
    chargingStation: "fa-charging-station",
    chartArea: "fa-chart-area",
    chartBar: "fa-chart-bar",
    chartLine: "fa-chart-line",
    chartLineDown: "fa-chart-line-down",
    chartNetwork: "fa-chart-network",
    chartPie: "fa-chart-pie",
    chartPieAlt: "fa-chart-pie-alt",
    chartScatter: "fa-chart-scatter",
    check: "fa-check",
    checkCircle: "fa-check-circle",
    checkDouble: "fa-check-double",
    checkSquare: "fa-check-square",
    cheese: "fa-cheese",
    cheeseSwiss: "fa-cheese-swiss",
    cheeseburger: "fa-cheeseburger",
    chess: "fa-chess",
    chessBishop: "fa-chess-bishop",
    chessBishopAlt: "fa-chess-bishop-alt",
    chessBoard: "fa-chess-board",
    chessClock: "fa-chess-clock",
    chessClockAlt: "fa-chess-clock-alt",
    chessKing: "fa-chess-king",
    chessKingAlt: "fa-chess-king-alt",
    chessKnight: "fa-chess-knight",
    chessKnightAlt: "fa-chess-knight-alt",
    chessPawn: "fa-chess-pawn",
    chessPawnAlt: "fa-chess-pawn-alt",
    chessQueen: "fa-chess-queen",
    chessQueenAlt: "fa-chess-queen-alt",
    chessRook: "fa-chess-rook",
    chessRookAlt: "fa-chess-rook-alt",
    chevronCircleDown: "fa-chevron-circle-down",
    chevronCircleLeft: "fa-chevron-circle-left",
    chevronCircleRight: "fa-chevron-circle-right",
    chevronCircleUp: "fa-chevron-circle-up",
    chevronDoubleDown: "fa-chevron-double-down",
    chevronDoubleLeft: "fa-chevron-double-left",
    chevronDoubleRight: "fa-chevron-double-right",
    chevronDoubleUp: "fa-chevron-double-up",
    chevronDown: "fa-chevron-down",
    chevronLeft: "fa-chevron-left",
    chevronRight: "fa-chevron-right",
    chevronSquareDown: "fa-chevron-square-down",
    chevronSquareLeft: "fa-chevron-square-left",
    chevronSquareRight: "fa-chevron-square-right",
    chevronSquareUp: "fa-chevron-square-up",
    chevronUp: "fa-chevron-up",
    child: "fa-child",
    chimney: "fa-chimney",
    chrome: "fa-chrome",
    chromecast: "fa-chromecast",
    church: "fa-church",
    circle: "fa-circle",
    circleNotch: "fa-circle-notch",
    city: "fa-city",
    clarinet: "fa-clarinet",
    clawMarks: "fa-claw-marks",
    clinicMedical: "fa-clinic-medical",
    clipboard: "fa-clipboard",
    clipboardCheck: "fa-clipboard-check",
    clipboardList: "fa-clipboard-list",
    clipboardListCheck: "fa-clipboard-list-check",
    clipboardPrescription: "fa-clipboard-prescription",
    clipboardUser: "fa-clipboard-user",
    clock: "fa-clock",
    clone: "fa-clone",
    closedCaptioning: "fa-closed-captioning",
    cloud: "fa-cloud",
    cloudDownload: "fa-cloud-download",
    cloudDownloadAlt: "fa-cloud-download-alt",
    cloudDrizzle: "fa-cloud-drizzle",
    cloudHail: "fa-cloud-hail",
    cloudHailMixed: "fa-cloud-hail-mixed",
    cloudMeatball: "fa-cloud-meatball",
    cloudMoon: "fa-cloud-moon",
    cloudMoonRain: "fa-cloud-moon-rain",
    cloudMusic: "fa-cloud-music",
    cloudRain: "fa-cloud-rain",
    cloudRainbow: "fa-cloud-rainbow",
    cloudShowers: "fa-cloud-showers",
    cloudShowersHeavy: "fa-cloud-showers-heavy",
    cloudSleet: "fa-cloud-sleet",
    cloudSnow: "fa-cloud-snow",
    cloudSun: "fa-cloud-sun",
    cloudSunRain: "fa-cloud-sun-rain",
    cloudUpload: "fa-cloud-upload",
    cloudUploadAlt: "fa-cloud-upload-alt",
    clouds: "fa-clouds",
    cloudsMoon: "fa-clouds-moon",
    cloudsSun: "fa-clouds-sun",
    cloudscale: "fa-cloudscale",
    cloudsmith: "fa-cloudsmith",
    cloudversify: "fa-cloudversify",
    club: "fa-club",
    cocktail: "fa-cocktail",
    code: "fa-code",
    codeBranch: "fa-code-branch",
    codeCommit: "fa-code-commit",
    codeMerge: "fa-code-merge",
    codepen: "fa-codepen",
    codiepie: "fa-codiepie",
    coffee: "fa-coffee",
    coffeePot: "fa-coffee-pot",
    coffeeTogo: "fa-coffee-togo",
    coffin: "fa-coffin",
    coffinCross: "fa-coffin-cross",
    cog: "fa-cog",
    cogs: "fa-cogs",
    coin: "fa-coin",
    coins: "fa-coins",
    columns: "fa-columns",
    comet: "fa-comet",
    comment: "fa-comment",
    commentAlt: "fa-comment-alt",
    commentAltCheck: "fa-comment-alt-check",
    commentAltDollar: "fa-comment-alt-dollar",
    commentAltDots: "fa-comment-alt-dots",
    commentAltEdit: "fa-comment-alt-edit",
    commentAltExclamation: "fa-comment-alt-exclamation",
    commentAltLines: "fa-comment-alt-lines",
    commentAltMedical: "fa-comment-alt-medical",
    commentAltMinus: "fa-comment-alt-minus",
    commentAltMusic: "fa-comment-alt-music",
    commentAltPlus: "fa-comment-alt-plus",
    commentAltSlash: "fa-comment-alt-slash",
    commentAltSmile: "fa-comment-alt-smile",
    commentAltTimes: "fa-comment-alt-times",
    commentCheck: "fa-comment-check",
    commentDollar: "fa-comment-dollar",
    commentDots: "fa-comment-dots",
    commentEdit: "fa-comment-edit",
    commentExclamation: "fa-comment-exclamation",
    commentLines: "fa-comment-lines",
    commentMedical: "fa-comment-medical",
    commentMinus: "fa-comment-minus",
    commentMusic: "fa-comment-music",
    commentPlus: "fa-comment-plus",
    commentSlash: "fa-comment-slash",
    commentSmile: "fa-comment-smile",
    commentTimes: "fa-comment-times",
    comments: "fa-comments",
    commentsAlt: "fa-comments-alt",
    commentsAltDollar: "fa-comments-alt-dollar",
    commentsDollar: "fa-comments-dollar",
    compactDisc: "fa-compact-disc",
    compass: "fa-compass",
    compassSlash: "fa-compass-slash",
    compress: "fa-compress",
    compressAlt: "fa-compress-alt",
    compressArrowsAlt: "fa-compress-arrows-alt",
    compressWide: "fa-compress-wide",
    computerClassic: "fa-computer-classic",
    computerSpeaker: "fa-computer-speaker",
    conciergeBell: "fa-concierge-bell",
    confluence: "fa-confluence",
    connectdevelop: "fa-connectdevelop",
    construction: "fa-construction",
    containerStorage: "fa-container-storage",
    contao: "fa-contao",
    conveyorBelt: "fa-conveyor-belt",
    conveyorBeltAlt: "fa-conveyor-belt-alt",
    cookie: "fa-cookie",
    cookieBite: "fa-cookie-bite",
    copy: "fa-copy",
    copyright: "fa-copyright",
    corn: "fa-corn",
    cottonBureau: "fa-cotton-bureau",
    couch: "fa-couch",
    cow: "fa-cow",
    cowbell: "fa-cowbell",
    cowbellMore: "fa-cowbell-more",
    cpanel: "fa-cpanel",
    creativeCommons: "fa-creative-commons",
    creativeCommonsBy: "fa-creative-commons-by",
    creativeCommonsNc: "fa-creative-commons-nc",
    creativeCommonsNcEu: "fa-creative-commons-nc-eu",
    creativeCommonsNcJp: "fa-creative-commons-nc-jp",
    creativeCommonsNd: "fa-creative-commons-nd",
    creativeCommonsPd: "fa-creative-commons-pd",
    creativeCommonsPdAlt: "fa-creative-commons-pd-alt",
    creativeCommonsRemix: "fa-creative-commons-remix",
    creativeCommonsSa: "fa-creative-commons-sa",
    creativeCommonsSampling: "fa-creative-commons-sampling",
    creativeCommonsSamplingPlus: "fa-creative-commons-sampling-plus",
    creativeCommonsShare: "fa-creative-commons-share",
    creativeCommonsZero: "fa-creative-commons-zero",
    creditCard: "fa-credit-card",
    creditCardBlank: "fa-credit-card-blank",
    creditCardFront: "fa-credit-card-front",
    cricket: "fa-cricket",
    criticalRole: "fa-critical-role",
    croissant: "fa-croissant",
    crop: "fa-crop",
    cropAlt: "fa-crop-alt",
    cross: "fa-cross",
    crosshairs: "fa-crosshairs",
    crow: "fa-crow",
    crown: "fa-crown",
    crutch: "fa-crutch",
    crutches: "fa-crutches",
    css3: "fa-css3",
    css3Alt: "fa-css3-alt",
    cube: "fa-cube",
    cubes: "fa-cubes",
    curling: "fa-curling",
    cut: "fa-cut",
    cuttlefish: "fa-cuttlefish",
    dAndD: "fa-d-and-d",
    dAndDBeyond: "fa-d-and-d-beyond",
    dagger: "fa-dagger",
    dailymotion: "fa-dailymotion",
    dashcube: "fa-dashcube",
    database: "fa-database",
    deaf: "fa-deaf",
    debug: "fa-debug",
    deer: "fa-deer",
    deerRudolph: "fa-deer-rudolph",
    delicious: "fa-delicious",
    democrat: "fa-democrat",
    deploydog: "fa-deploydog",
    deskpro: "fa-deskpro",
    desktop: "fa-desktop",
    desktopAlt: "fa-desktop-alt",
    dev: "fa-dev",
    deviantart: "fa-deviantart",
    dewpoint: "fa-dewpoint",
    dharmachakra: "fa-dharmachakra",
    dhl: "fa-dhl",
    diagnoses: "fa-diagnoses",
    diamond: "fa-diamond",
    diaspora: "fa-diaspora",
    dice: "fa-dice",
    diceD10: "fa-dice-d10",
    diceD12: "fa-dice-d12",
    diceD20: "fa-dice-d20",
    diceD4: "fa-dice-d4",
    diceD6: "fa-dice-d6",
    diceD8: "fa-dice-d8",
    diceFive: "fa-dice-five",
    diceFour: "fa-dice-four",
    diceOne: "fa-dice-one",
    diceSix: "fa-dice-six",
    diceThree: "fa-dice-three",
    diceTwo: "fa-dice-two",
    digg: "fa-digg",
    digging: "fa-digging",
    digitalOcean: "fa-digital-ocean",
    digitalTachograph: "fa-digital-tachograph",
    diploma: "fa-diploma",
    directions: "fa-directions",
    discDrive: "fa-disc-drive",
    discord: "fa-discord",
    discourse: "fa-discourse",
    disease: "fa-disease",
    divide: "fa-divide",
    dizzy: "fa-dizzy",
    dna: "fa-dna",
    doNotEnter: "fa-do-not-enter",
    dochub: "fa-dochub",
    docker: "fa-docker",
    dog: "fa-dog",
    dogLeashed: "fa-dog-leashed",
    dollarSign: "fa-dollar-sign",
    dolly: "fa-dolly",
    dollyEmpty: "fa-dolly-empty",
    dollyFlatbed: "fa-dolly-flatbed",
    dollyFlatbedAlt: "fa-dolly-flatbed-alt",
    dollyFlatbedEmpty: "fa-dolly-flatbed-empty",
    donate: "fa-donate",
    doorClosed: "fa-door-closed",
    doorOpen: "fa-door-open",
    dotCircle: "fa-dot-circle",
    dove: "fa-dove",
    download: "fa-download",
    draft2digital: "fa-draft2digital",
    draftingCompass: "fa-drafting-compass",
    dragon: "fa-dragon",
    drawCircle: "fa-draw-circle",
    drawPolygon: "fa-draw-polygon",
    drawSquare: "fa-draw-square",
    dreidel: "fa-dreidel",
    dribbble: "fa-dribbble",
    dribbbleSquare: "fa-dribbble-square",
    drone: "fa-drone",
    droneAlt: "fa-drone-alt",
    dropbox: "fa-dropbox",
    drum: "fa-drum",
    drumSteelpan: "fa-drum-steelpan",
    drumstick: "fa-drumstick",
    drumstickBite: "fa-drumstick-bite",
    drupal: "fa-drupal",
    dryer: "fa-dryer",
    dryerAlt: "fa-dryer-alt",
    duck: "fa-duck",
    dumbbell: "fa-dumbbell",
    dumpster: "fa-dumpster",
    dumpsterFire: "fa-dumpster-fire",
    dungeon: "fa-dungeon",
    dyalog: "fa-dyalog",
    ear: "fa-ear",
    earMuffs: "fa-ear-muffs",
    earlybirds: "fa-earlybirds",
    ebay: "fa-ebay",
    eclipse: "fa-eclipse",
    eclipseAlt: "fa-eclipse-alt",
    edge: "fa-edge",
    edit: "fa-edit",
    egg: "fa-egg",
    eggFried: "fa-egg-fried",
    eject: "fa-eject",
    elementor: "fa-elementor",
    elephant: "fa-elephant",
    ellipsisH: "fa-ellipsis-h",
    ellipsisHAlt: "fa-ellipsis-h-alt",
    ellipsisV: "fa-ellipsis-v",
    ellipsisVAlt: "fa-ellipsis-v-alt",
    ello: "fa-ello",
    ember: "fa-ember",
    empire: "fa-empire",
    emptySet: "fa-empty-set",
    engineWarning: "fa-engine-warning",
    envelope: "fa-envelope",
    envelopeOpen: "fa-envelope-open",
    envelopeOpenDollar: "fa-envelope-open-dollar",
    envelopeOpenText: "fa-envelope-open-text",
    envelopeSquare: "fa-envelope-square",
    envira: "fa-envira",
    equals: "fa-equals",
    eraser: "fa-eraser",
    erlang: "fa-erlang",
    ethereum: "fa-ethereum",
    ethernet: "fa-ethernet",
    etsy: "fa-etsy",
    euroSign: "fa-euro-sign",
    evernote: "fa-evernote",
    exchange: "fa-exchange",
    exchangeAlt: "fa-exchange-alt",
    exclamation: "fa-exclamation",
    exclamationCircle: "fa-exclamation-circle",
    exclamationSquare: "fa-exclamation-square",
    exclamationTriangle: "fa-exclamation-triangle",
    expand: "fa-expand",
    expandAlt: "fa-expand-alt",
    expandArrows: "fa-expand-arrows",
    expandArrowsAlt: "fa-expand-arrows-alt",
    expandWide: "fa-expand-wide",
    expeditedssl: "fa-expeditedssl",
    externalLink: "fa-external-link",
    externalLinkAlt: "fa-external-link-alt",
    externalLinkSquare: "fa-external-link-square",
    externalLinkSquareAlt: "fa-external-link-square-alt",
    eye: "fa-eye",
    eyeDropper: "fa-eye-dropper",
    eyeEvil: "fa-eye-evil",
    eyeSlash: "fa-eye-slash",
    facebook: "fa-facebook",
    facebookF: "fa-facebook-f",
    facebookMessenger: "fa-facebook-messenger",
    facebookSquare: "fa-facebook-square",
    fan: "fa-fan",
    fanTable: "fa-fan-table",
    fantasyFlightGames: "fa-fantasy-flight-games",
    farm: "fa-farm",
    fastBackward: "fa-fast-backward",
    fastForward: "fa-fast-forward",
    faucet: "fa-faucet",
    faucetDrip: "fa-faucet-drip",
    fax: "fa-fax",
    feather: "fa-feather",
    featherAlt: "fa-feather-alt",
    fedex: "fa-fedex",
    fedora: "fa-fedora",
    female: "fa-female",
    fieldHockey: "fa-field-hockey",
    fighterJet: "fa-fighter-jet",
    figma: "fa-figma",
    file: "fa-file",
    fileAlt: "fa-file-alt",
    fileArchive: "fa-file-archive",
    fileAudio: "fa-file-audio",
    fileCertificate: "fa-file-certificate",
    fileChartLine: "fa-file-chart-line",
    fileChartPie: "fa-file-chart-pie",
    fileCheck: "fa-file-check",
    fileCode: "fa-file-code",
    fileContract: "fa-file-contract",
    fileCsv: "fa-file-csv",
    fileDownload: "fa-file-download",
    fileEdit: "fa-file-edit",
    fileExcel: "fa-file-excel",
    fileExclamation: "fa-file-exclamation",
    fileExport: "fa-file-export",
    fileImage: "fa-file-image",
    fileImport: "fa-file-import",
    fileInvoice: "fa-file-invoice",
    fileInvoiceDollar: "fa-file-invoice-dollar",
    fileMedical: "fa-file-medical",
    fileMedicalAlt: "fa-file-medical-alt",
    fileMinus: "fa-file-minus",
    fileMusic: "fa-file-music",
    filePdf: "fa-file-pdf",
    filePlus: "fa-file-plus",
    filePowerpoint: "fa-file-powerpoint",
    filePrescription: "fa-file-prescription",
    fileSearch: "fa-file-search",
    fileSignature: "fa-file-signature",
    fileSpreadsheet: "fa-file-spreadsheet",
    fileTimes: "fa-file-times",
    fileUpload: "fa-file-upload",
    fileUser: "fa-file-user",
    fileVideo: "fa-file-video",
    fileWord: "fa-file-word",
    filesMedical: "fa-files-medical",
    fill: "fa-fill",
    fillDrip: "fa-fill-drip",
    film: "fa-film",
    filmAlt: "fa-film-alt",
    filmCanister: "fa-film-canister",
    filter: "fa-filter",
    fingerprint: "fa-fingerprint",
    fire: "fa-fire",
    fireAlt: "fa-fire-alt",
    fireExtinguisher: "fa-fire-extinguisher",
    fireSmoke: "fa-fire-smoke",
    firefox: "fa-firefox",
    firefoxBrowser: "fa-firefox-browser",
    fireplace: "fa-fireplace",
    firstAid: "fa-first-aid",
    firstOrder: "fa-first-order",
    firstOrderAlt: "fa-first-order-alt",
    firstdraft: "fa-firstdraft",
    fish: "fa-fish",
    fishCooked: "fa-fish-cooked",
    fistRaised: "fa-fist-raised",
    flag: "fa-flag",
    flagAlt: "fa-flag-alt",
    flagCheckered: "fa-flag-checkered",
    flagUsa: "fa-flag-usa",
    flame: "fa-flame",
    flashlight: "fa-flashlight",
    flask: "fa-flask",
    flaskPoison: "fa-flask-poison",
    flaskPotion: "fa-flask-potion",
    flickr: "fa-flickr",
    flipboard: "fa-flipboard",
    flower: "fa-flower",
    flowerDaffodil: "fa-flower-daffodil",
    flowerTulip: "fa-flower-tulip",
    flushed: "fa-flushed",
    flute: "fa-flute",
    fluxCapacitor: "fa-flux-capacitor",
    fly: "fa-fly",
    fog: "fa-fog",
    folder: "fa-folder",
    folderDownload: "fa-folder-download",
    folderMinus: "fa-folder-minus",
    folderOpen: "fa-folder-open",
    folderPlus: "fa-folder-plus",
    folderTimes: "fa-folder-times",
    folderTree: "fa-folder-tree",
    folderUpload: "fa-folder-upload",
    folders: "fa-folders",
    font: "fa-font",
    fontAwesome: "fa-font-awesome",
    fontAwesomeAlt: "fa-font-awesome-alt",
    fontAwesomeFlag: "fa-font-awesome-flag",
    fontAwesomeLogoFull: "fa-font-awesome-logo-full",
    fontCase: "fa-font-case",
    fonticons: "fa-fonticons",
    fonticonsFi: "fa-fonticons-fi",
    footballBall: "fa-football-ball",
    footballHelmet: "fa-football-helmet",
    forklift: "fa-forklift",
    fortAwesome: "fa-fort-awesome",
    fortAwesomeAlt: "fa-fort-awesome-alt",
    forumbee: "fa-forumbee",
    forward: "fa-forward",
    foursquare: "fa-foursquare",
    fragile: "fa-fragile",
    freeCodeCamp: "fa-free-code-camp",
    freebsd: "fa-freebsd",
    frenchFries: "fa-french-fries",
    frog: "fa-frog",
    frostyHead: "fa-frosty-head",
    frown: "fa-frown",
    frownOpen: "fa-frown-open",
    fulcrum: "fa-fulcrum",
    function: "fa-function",
    funnelDollar: "fa-funnel-dollar",
    futbol: "fa-futbol",
    galacticRepublic: "fa-galactic-republic",
    galacticSenate: "fa-galactic-senate",
    galaxy: "fa-galaxy",
    gameBoard: "fa-game-board",
    gameBoardAlt: "fa-game-board-alt",
    gameConsoleHandheld: "fa-game-console-handheld",
    gamepad: "fa-gamepad",
    gamepadAlt: "fa-gamepad-alt",
    garage: "fa-garage",
    garageCar: "fa-garage-car",
    garageOpen: "fa-garage-open",
    gasPump: "fa-gas-pump",
    gasPumpSlash: "fa-gas-pump-slash",
    gavel: "fa-gavel",
    gem: "fa-gem",
    genderless: "fa-genderless",
    getPocket: "fa-get-pocket",
    gg: "fa-gg",
    ggCircle: "fa-gg-circle",
    ghost: "fa-ghost",
    gift: "fa-gift",
    giftCard: "fa-gift-card",
    gifts: "fa-gifts",
    gingerbreadMan: "fa-gingerbread-man",
    git: "fa-git",
    gitAlt: "fa-git-alt",
    gitSquare: "fa-git-square",
    github: "fa-github",
    githubAlt: "fa-github-alt",
    githubSquare: "fa-github-square",
    gitkraken: "fa-gitkraken",
    gitlab: "fa-gitlab",
    gitter: "fa-gitter",
    glass: "fa-glass",
    glassChampagne: "fa-glass-champagne",
    glassCheers: "fa-glass-cheers",
    glassCitrus: "fa-glass-citrus",
    glassMartini: "fa-glass-martini",
    glassMartiniAlt: "fa-glass-martini-alt",
    glassWhiskey: "fa-glass-whiskey",
    glassWhiskeyRocks: "fa-glass-whiskey-rocks",
    glasses: "fa-glasses",
    glassesAlt: "fa-glasses-alt",
    glide: "fa-glide",
    glideG: "fa-glide-g",
    globe: "fa-globe",
    globeAfrica: "fa-globe-africa",
    globeAmericas: "fa-globe-americas",
    globeAsia: "fa-globe-asia",
    globeEurope: "fa-globe-europe",
    globeSnow: "fa-globe-snow",
    globeStand: "fa-globe-stand",
    gofore: "fa-gofore",
    golfBall: "fa-golf-ball",
    golfClub: "fa-golf-club",
    goodreads: "fa-goodreads",
    goodreadsG: "fa-goodreads-g",
    google: "fa-google",
    googleDrive: "fa-google-drive",
    googlePlay: "fa-google-play",
    googlePlus: "fa-google-plus",
    googlePlusG: "fa-google-plus-g",
    googlePlusSquare: "fa-google-plus-square",
    googleWallet: "fa-google-wallet",
    gopuram: "fa-gopuram",
    graduationCap: "fa-graduation-cap",
    gramophone: "fa-gramophone",
    gratipay: "fa-gratipay",
    grav: "fa-grav",
    greaterThan: "fa-greater-than",
    greaterThanEqual: "fa-greater-than-equal",
    grimace: "fa-grimace",
    grin: "fa-grin",
    grinAlt: "fa-grin-alt",
    grinBeam: "fa-grin-beam",
    grinBeamSweat: "fa-grin-beam-sweat",
    grinHearts: "fa-grin-hearts",
    grinSquint: "fa-grin-squint",
    grinSquintTears: "fa-grin-squint-tears",
    grinStars: "fa-grin-stars",
    grinTears: "fa-grin-tears",
    grinTongue: "fa-grin-tongue",
    grinTongueSquint: "fa-grin-tongue-squint",
    grinTongueWink: "fa-grin-tongue-wink",
    grinWink: "fa-grin-wink",
    gripHorizontal: "fa-grip-horizontal",
    gripLines: "fa-grip-lines",
    gripLinesVertical: "fa-grip-lines-vertical",
    gripVertical: "fa-grip-vertical",
    gripfire: "fa-gripfire",
    grunt: "fa-grunt",
    guitar: "fa-guitar",
    guitarElectric: "fa-guitar-electric",
    guitars: "fa-guitars",
    gulp: "fa-gulp",
    hSquare: "fa-h-square",
    h1: "fa-h1",
    h2: "fa-h2",
    h3: "fa-h3",
    h4: "fa-h4",
    hackerNews: "fa-hacker-news",
    hackerNewsSquare: "fa-hacker-news-square",
    hackerrank: "fa-hackerrank",
    hamburger: "fa-hamburger",
    hammer: "fa-hammer",
    hammerWar: "fa-hammer-war",
    hamsa: "fa-hamsa",
    handHeart: "fa-hand-heart",
    handHolding: "fa-hand-holding",
    handHoldingBox: "fa-hand-holding-box",
    handHoldingHeart: "fa-hand-holding-heart",
    handHoldingMagic: "fa-hand-holding-magic",
    handHoldingMedical: "fa-hand-holding-medical",
    handHoldingSeedling: "fa-hand-holding-seedling",
    handHoldingUsd: "fa-hand-holding-usd",
    handHoldingWater: "fa-hand-holding-water",
    handLizard: "fa-hand-lizard",
    handMiddleFinger: "fa-hand-middle-finger",
    handPaper: "fa-hand-paper",
    handPeace: "fa-hand-peace",
    handPointDown: "fa-hand-point-down",
    handPointLeft: "fa-hand-point-left",
    handPointRight: "fa-hand-point-right",
    handPointUp: "fa-hand-point-up",
    handPointer: "fa-hand-pointer",
    handReceiving: "fa-hand-receiving",
    handRock: "fa-hand-rock",
    handScissors: "fa-hand-scissors",
    handSparkles: "fa-hand-sparkles",
    handSpock: "fa-hand-spock",
    hands: "fa-hands",
    handsHeart: "fa-hands-heart",
    handsHelping: "fa-hands-helping",
    handsUsd: "fa-hands-usd",
    handsWash: "fa-hands-wash",
    handshake: "fa-handshake",
    handshakeAlt: "fa-handshake-alt",
    handshakeAltSlash: "fa-handshake-alt-slash",
    handshakeSlash: "fa-handshake-slash",
    hanukiah: "fa-hanukiah",
    hardHat: "fa-hard-hat",
    hashtag: "fa-hashtag",
    hatChef: "fa-hat-chef",
    hatCowboy: "fa-hat-cowboy",
    hatCowboySide: "fa-hat-cowboy-side",
    hatSanta: "fa-hat-santa",
    hatWinter: "fa-hat-winter",
    hatWitch: "fa-hat-witch",
    hatWizard: "fa-hat-wizard",
    hdd: "fa-hdd",
    headSide: "fa-head-side",
    headSideBrain: "fa-head-side-brain",
    headSideCough: "fa-head-side-cough",
    headSideCoughSlash: "fa-head-side-cough-slash",
    headSideHeadphones: "fa-head-side-headphones",
    headSideMask: "fa-head-side-mask",
    headSideMedical: "fa-head-side-medical",
    headSideVirus: "fa-head-side-virus",
    headVr: "fa-head-vr",
    heading: "fa-heading",
    headphones: "fa-headphones",
    headphonesAlt: "fa-headphones-alt",
    headset: "fa-headset",
    heart: "fa-heart",
    heartBroken: "fa-heart-broken",
    heartCircle: "fa-heart-circle",
    heartRate: "fa-heart-rate",
    heartSquare: "fa-heart-square",
    heartbeat: "fa-heartbeat",
    heat: "fa-heat",
    helicopter: "fa-helicopter",
    helmetBattle: "fa-helmet-battle",
    hexagon: "fa-hexagon",
    highlighter: "fa-highlighter",
    hiking: "fa-hiking",
    hippo: "fa-hippo",
    hips: "fa-hips",
    hireAHelper: "fa-hire-a-helper",
    history: "fa-history",
    hockeyMask: "fa-hockey-mask",
    hockeyPuck: "fa-hockey-puck",
    hockeySticks: "fa-hockey-sticks",
    hollyBerry: "fa-holly-berry",
    home: "fa-home",
    homeAlt: "fa-home-alt",
    homeHeart: "fa-home-heart",
    homeLg: "fa-home-lg",
    homeLgAlt: "fa-home-lg-alt",
    hoodCloak: "fa-hood-cloak",
    hooli: "fa-hooli",
    horizontalRule: "fa-horizontal-rule",
    hornbill: "fa-hornbill",
    horse: "fa-horse",
    horseHead: "fa-horse-head",
    horseSaddle: "fa-horse-saddle",
    hospital: "fa-hospital",
    hospitalAlt: "fa-hospital-alt",
    hospitalSymbol: "fa-hospital-symbol",
    hospitalUser: "fa-hospital-user",
    hospitals: "fa-hospitals",
    hotTub: "fa-hot-tub",
    hotdog: "fa-hotdog",
    hotel: "fa-hotel",
    hotjar: "fa-hotjar",
    hourglass: "fa-hourglass",
    hourglassEnd: "fa-hourglass-end",
    hourglassHalf: "fa-hourglass-half",
    hourglassStart: "fa-hourglass-start",
    house: "fa-house",
    houseDamage: "fa-house-damage",
    houseDay: "fa-house-day",
    houseFlood: "fa-house-flood",
    houseLeave: "fa-house-leave",
    houseNight: "fa-house-night",
    houseReturn: "fa-house-return",
    houseSignal: "fa-house-signal",
    houseUser: "fa-house-user",
    houzz: "fa-houzz",
    hryvnia: "fa-hryvnia",
    html5: "fa-html5",
    hubspot: "fa-hubspot",
    humidity: "fa-humidity",
    hurricane: "fa-hurricane",
    iCursor: "fa-i-cursor",
    iceCream: "fa-ice-cream",
    iceSkate: "fa-ice-skate",
    icicles: "fa-icicles",
    icons: "fa-icons",
    iconsAlt: "fa-icons-alt",
    idBadge: "fa-id-badge",
    idCard: "fa-id-card",
    idCardAlt: "fa-id-card-alt",
    ideal: "fa-ideal",
    igloo: "fa-igloo",
    image: "fa-image",
    imagePolaroid: "fa-image-polaroid",
    images: "fa-images",
    imdb: "fa-imdb",
    inbox: "fa-inbox",
    inboxIn: "fa-inbox-in",
    inboxOut: "fa-inbox-out",
    indent: "fa-indent",
    industry: "fa-industry",
    industryAlt: "fa-industry-alt",
    infinity: "fa-infinity",
    info: "fa-info",
    infoCircle: "fa-info-circle",
    infoSquare: "fa-info-square",
    inhaler: "fa-inhaler",
    instagram: "fa-instagram",
    instagramSquare: "fa-instagram-square",
    integral: "fa-integral",
    intercom: "fa-intercom",
    internetExplorer: "fa-internet-explorer",
    intersection: "fa-intersection",
    inventory: "fa-inventory",
    invision: "fa-invision",
    ioxhost: "fa-ioxhost",
    islandTropical: "fa-island-tropical",
    italic: "fa-italic",
    itchIo: "fa-itch-io",
    itunes: "fa-itunes",
    itunesNote: "fa-itunes-note",
    jackOLantern: "fa-jack-o-lantern",
    java: "fa-java",
    jedi: "fa-jedi",
    jediOrder: "fa-jedi-order",
    jenkins: "fa-jenkins",
    jira: "fa-jira",
    joget: "fa-joget",
    joint: "fa-joint",
    joomla: "fa-joomla",
    journalWhills: "fa-journal-whills",
    joystick: "fa-joystick",
    js: "fa-js",
    jsSquare: "fa-js-square",
    jsfiddle: "fa-jsfiddle",
    jug: "fa-jug",
    kaaba: "fa-kaaba",
    kaggle: "fa-kaggle",
    kazoo: "fa-kazoo",
    kerning: "fa-kerning",
    key: "fa-key",
    keySkeleton: "fa-key-skeleton",
    keybase: "fa-keybase",
    keyboard: "fa-keyboard",
    keycdn: "fa-keycdn",
    keynote: "fa-keynote",
    khanda: "fa-khanda",
    kickstarter: "fa-kickstarter",
    kickstarterK: "fa-kickstarter-k",
    kidneys: "fa-kidneys",
    kiss: "fa-kiss",
    kissBeam: "fa-kiss-beam",
    kissWinkHeart: "fa-kiss-wink-heart",
    kite: "fa-kite",
    kiwiBird: "fa-kiwi-bird",
    knifeKitchen: "fa-knife-kitchen",
    korvue: "fa-korvue",
    lambda: "fa-lambda",
    lamp: "fa-lamp",
    lampDesk: "fa-lamp-desk",
    lampFloor: "fa-lamp-floor",
    landmark: "fa-landmark",
    landmarkAlt: "fa-landmark-alt",
    language: "fa-language",
    laptop: "fa-laptop",
    laptopCode: "fa-laptop-code",
    laptopHouse: "fa-laptop-house",
    laptopMedical: "fa-laptop-medical",
    laravel: "fa-laravel",
    lasso: "fa-lasso",
    lastfm: "fa-lastfm",
    lastfmSquare: "fa-lastfm-square",
    laugh: "fa-laugh",
    laughBeam: "fa-laugh-beam",
    laughSquint: "fa-laugh-squint",
    laughWink: "fa-laugh-wink",
    layerGroup: "fa-layer-group",
    layerMinus: "fa-layer-minus",
    layerPlus: "fa-layer-plus",
    leaf: "fa-leaf",
    leafHeart: "fa-leaf-heart",
    leafMaple: "fa-leaf-maple",
    leafOak: "fa-leaf-oak",
    leanpub: "fa-leanpub",
    lemon: "fa-lemon",
    less: "fa-less",
    lessThan: "fa-less-than",
    lessThanEqual: "fa-less-than-equal",
    levelDown: "fa-level-down",
    levelDownAlt: "fa-level-down-alt",
    levelUp: "fa-level-up",
    levelUpAlt: "fa-level-up-alt",
    lifeRing: "fa-life-ring",
    lightCeiling: "fa-light-ceiling",
    lightSwitch: "fa-light-switch",
    lightSwitchOff: "fa-light-switch-off",
    lightSwitchOn: "fa-light-switch-on",
    lightbulb: "fa-lightbulb",
    lightbulbDollar: "fa-lightbulb-dollar",
    lightbulbExclamation: "fa-lightbulb-exclamation",
    lightbulbOn: "fa-lightbulb-on",
    lightbulbSlash: "fa-lightbulb-slash",
    lightsHoliday: "fa-lights-holiday",
    line: "fa-line",
    lineColumns: "fa-line-columns",
    lineHeight: "fa-line-height",
    link: "fa-link",
    linkedin: "fa-linkedin",
    linkedinIn: "fa-linkedin-in",
    linode: "fa-linode",
    linux: "fa-linux",
    lips: "fa-lips",
    liraSign: "fa-lira-sign",
    list: "fa-list",
    listAlt: "fa-list-alt",
    listMusic: "fa-list-music",
    listOl: "fa-list-ol",
    listUl: "fa-list-ul",
    location: "fa-location",
    locationArrow: "fa-location-arrow",
    locationCircle: "fa-location-circle",
    locationSlash: "fa-location-slash",
    lock: "fa-lock",
    lockAlt: "fa-lock-alt",
    lockOpen: "fa-lock-open",
    lockOpenAlt: "fa-lock-open-alt",
    longArrowAltDown: "fa-long-arrow-alt-down",
    longArrowAltLeft: "fa-long-arrow-alt-left",
    longArrowAltRight: "fa-long-arrow-alt-right",
    longArrowAltUp: "fa-long-arrow-alt-up",
    longArrowDown: "fa-long-arrow-down",
    longArrowLeft: "fa-long-arrow-left",
    longArrowRight: "fa-long-arrow-right",
    longArrowUp: "fa-long-arrow-up",
    loveseat: "fa-loveseat",
    lowVision: "fa-low-vision",
    luchador: "fa-luchador",
    luggageCart: "fa-luggage-cart",
    lungs: "fa-lungs",
    lungsVirus: "fa-lungs-virus",
    lyft: "fa-lyft",
    mace: "fa-mace",
    magento: "fa-magento",
    magic: "fa-magic",
    magnet: "fa-magnet",
    mailBulk: "fa-mail-bulk",
    mailbox: "fa-mailbox",
    mailchimp: "fa-mailchimp",
    male: "fa-male",
    mandalorian: "fa-mandalorian",
    mandolin: "fa-mandolin",
    map: "fa-map",
    mapMarked: "fa-map-marked",
    mapMarkedAlt: "fa-map-marked-alt",
    mapMarker: "fa-map-marker",
    mapMarkerAlt: "fa-map-marker-alt",
    mapMarkerAltSlash: "fa-map-marker-alt-slash",
    mapMarkerCheck: "fa-map-marker-check",
    mapMarkerEdit: "fa-map-marker-edit",
    mapMarkerExclamation: "fa-map-marker-exclamation",
    mapMarkerMinus: "fa-map-marker-minus",
    mapMarkerPlus: "fa-map-marker-plus",
    mapMarkerQuestion: "fa-map-marker-question",
    mapMarkerSlash: "fa-map-marker-slash",
    mapMarkerSmile: "fa-map-marker-smile",
    mapMarkerTimes: "fa-map-marker-times",
    mapPin: "fa-map-pin",
    mapSigns: "fa-map-signs",
    markdown: "fa-markdown",
    marker: "fa-marker",
    mars: "fa-mars",
    marsDouble: "fa-mars-double",
    marsStroke: "fa-mars-stroke",
    marsStrokeH: "fa-mars-stroke-h",
    marsStrokeV: "fa-mars-stroke-v",
    mask: "fa-mask",
    mastodon: "fa-mastodon",
    maxcdn: "fa-maxcdn",
    mdb: "fa-mdb",
    meat: "fa-meat",
    medal: "fa-medal",
    medapps: "fa-medapps",
    medium: "fa-medium",
    mediumM: "fa-medium-m",
    medkit: "fa-medkit",
    medrt: "fa-medrt",
    meetup: "fa-meetup",
    megaphone: "fa-megaphone",
    megaport: "fa-megaport",
    meh: "fa-meh",
    mehBlank: "fa-meh-blank",
    mehRollingEyes: "fa-meh-rolling-eyes",
    memory: "fa-memory",
    mendeley: "fa-mendeley",
    menorah: "fa-menorah",
    mercury: "fa-mercury",
    meteor: "fa-meteor",
    microblog: "fa-microblog",
    microchip: "fa-microchip",
    microphone: "fa-microphone",
    microphoneAlt: "fa-microphone-alt",
    microphoneAltSlash: "fa-microphone-alt-slash",
    microphoneSlash: "fa-microphone-slash",
    microphoneStand: "fa-microphone-stand",
    microscope: "fa-microscope",
    microsoft: "fa-microsoft",
    microwave: "fa-microwave",
    mindShare: "fa-mind-share",
    minus: "fa-minus",
    minusCircle: "fa-minus-circle",
    minusHexagon: "fa-minus-hexagon",
    minusOctagon: "fa-minus-octagon",
    minusSquare: "fa-minus-square",
    mistletoe: "fa-mistletoe",
    mitten: "fa-mitten",
    mix: "fa-mix",
    mixcloud: "fa-mixcloud",
    mixer: "fa-mixer",
    mizuni: "fa-mizuni",
    mobile: "fa-mobile",
    mobileAlt: "fa-mobile-alt",
    mobileAndroid: "fa-mobile-android",
    mobileAndroidAlt: "fa-mobile-android-alt",
    modx: "fa-modx",
    monero: "fa-monero",
    moneyBill: "fa-money-bill",
    moneyBillAlt: "fa-money-bill-alt",
    moneyBillWave: "fa-money-bill-wave",
    moneyBillWaveAlt: "fa-money-bill-wave-alt",
    moneyCheck: "fa-money-check",
    moneyCheckAlt: "fa-money-check-alt",
    moneyCheckEdit: "fa-money-check-edit",
    moneyCheckEditAlt: "fa-money-check-edit-alt",
    monitorHeartRate: "fa-monitor-heart-rate",
    monkey: "fa-monkey",
    monument: "fa-monument",
    moon: "fa-moon",
    moonCloud: "fa-moon-cloud",
    moonStars: "fa-moon-stars",
    mortarPestle: "fa-mortar-pestle",
    mosque: "fa-mosque",
    motorcycle: "fa-motorcycle",
    mountain: "fa-mountain",
    mountains: "fa-mountains",
    mouse: "fa-mouse",
    mouseAlt: "fa-mouse-alt",
    mousePointer: "fa-mouse-pointer",
    mp3Player: "fa-mp3-player",
    mug: "fa-mug",
    mugHot: "fa-mug-hot",
    mugMarshmallows: "fa-mug-marshmallows",
    mugTea: "fa-mug-tea",
    music: "fa-music",
    musicAlt: "fa-music-alt",
    musicAltSlash: "fa-music-alt-slash",
    musicSlash: "fa-music-slash",
    napster: "fa-napster",
    narwhal: "fa-narwhal",
    neos: "fa-neos",
    networkWired: "fa-network-wired",
    neuter: "fa-neuter",
    newspaper: "fa-newspaper",
    nimblr: "fa-nimblr",
    node: "fa-node",
    nodeJs: "fa-node-js",
    notEqual: "fa-not-equal",
    notesMedical: "fa-notes-medical",
    npm: "fa-npm",
    ns8: "fa-ns8",
    nutritionix: "fa-nutritionix",
    objectGroup: "fa-object-group",
    objectUngroup: "fa-object-ungroup",
    octagon: "fa-octagon",
    odnoklassniki: "fa-odnoklassniki",
    odnoklassnikiSquare: "fa-odnoklassniki-square",
    oilCan: "fa-oil-can",
    oilTemp: "fa-oil-temp",
    oldRepublic: "fa-old-republic",
    om: "fa-om",
    omega: "fa-omega",
    opencart: "fa-opencart",
    openid: "fa-openid",
    opera: "fa-opera",
    optinMonster: "fa-optin-monster",
    orcid: "fa-orcid",
    ornament: "fa-ornament",
    osi: "fa-osi",
    otter: "fa-otter",
    outdent: "fa-outdent",
    outlet: "fa-outlet",
    oven: "fa-oven",
    overline: "fa-overline",
    pageBreak: "fa-page-break",
    page4: "fa-page4",
    pagelines: "fa-pagelines",
    pager: "fa-pager",
    paintBrush: "fa-paint-brush",
    paintBrushAlt: "fa-paint-brush-alt",
    paintRoller: "fa-paint-roller",
    palette: "fa-palette",
    palfed: "fa-palfed",
    pallet: "fa-pallet",
    palletAlt: "fa-pallet-alt",
    paperPlane: "fa-paper-plane",
    paperclip: "fa-paperclip",
    parachuteBox: "fa-parachute-box",
    paragraph: "fa-paragraph",
    paragraphRtl: "fa-paragraph-rtl",
    parking: "fa-parking",
    parkingCircle: "fa-parking-circle",
    parkingCircleSlash: "fa-parking-circle-slash",
    parkingSlash: "fa-parking-slash",
    passport: "fa-passport",
    pastafarianism: "fa-pastafarianism",
    paste: "fa-paste",
    patreon: "fa-patreon",
    pause: "fa-pause",
    pauseCircle: "fa-pause-circle",
    paw: "fa-paw",
    pawAlt: "fa-paw-alt",
    pawClaws: "fa-paw-claws",
    paypal: "fa-paypal",
    peace: "fa-peace",
    pegasus: "fa-pegasus",
    pen: "fa-pen",
    penAlt: "fa-pen-alt",
    penFancy: "fa-pen-fancy",
    penNib: "fa-pen-nib",
    penSquare: "fa-pen-square",
    pencil: "fa-pencil",
    pencilAlt: "fa-pencil-alt",
    pencilPaintbrush: "fa-pencil-paintbrush",
    pencilRuler: "fa-pencil-ruler",
    pennant: "fa-pennant",
    pennyArcade: "fa-penny-arcade",
    peopleArrows: "fa-people-arrows",
    peopleCarry: "fa-people-carry",
    pepperHot: "fa-pepper-hot",
    percent: "fa-percent",
    percentage: "fa-percentage",
    periscope: "fa-periscope",
    personBooth: "fa-person-booth",
    personCarry: "fa-person-carry",
    personDolly: "fa-person-dolly",
    personDollyEmpty: "fa-person-dolly-empty",
    personSign: "fa-person-sign",
    phabricator: "fa-phabricator",
    phoenixFramework: "fa-phoenix-framework",
    phoenixSquadron: "fa-phoenix-squadron",
    phone: "fa-phone",
    phoneAlt: "fa-phone-alt",
    phoneLaptop: "fa-phone-laptop",
    phoneOffice: "fa-phone-office",
    phonePlus: "fa-phone-plus",
    phoneRotary: "fa-phone-rotary",
    phoneSlash: "fa-phone-slash",
    phoneSquare: "fa-phone-square",
    phoneSquareAlt: "fa-phone-square-alt",
    phoneVolume: "fa-phone-volume",
    photoVideo: "fa-photo-video",
    php: "fa-php",
    pi: "fa-pi",
    piano: "fa-piano",
    pianoKeyboard: "fa-piano-keyboard",
    pie: "fa-pie",
    piedPiper: "fa-pied-piper",
    piedPiperAlt: "fa-pied-piper-alt",
    piedPiperHat: "fa-pied-piper-hat",
    piedPiperPp: "fa-pied-piper-pp",
    piedPiperSquare: "fa-pied-piper-square",
    pig: "fa-pig",
    piggyBank: "fa-piggy-bank",
    pills: "fa-pills",
    pinterest: "fa-pinterest",
    pinterestP: "fa-pinterest-p",
    pinterestSquare: "fa-pinterest-square",
    pizza: "fa-pizza",
    pizzaSlice: "fa-pizza-slice",
    placeOfWorship: "fa-place-of-worship",
    plane: "fa-plane",
    planeAlt: "fa-plane-alt",
    planeArrival: "fa-plane-arrival",
    planeDeparture: "fa-plane-departure",
    planeSlash: "fa-plane-slash",
    planetMoon: "fa-planet-moon",
    planetRinged: "fa-planet-ringed",
    play: "fa-play",
    playCircle: "fa-play-circle",
    playstation: "fa-playstation",
    plug: "fa-plug",
    plus: "fa-plus",
    plusCircle: "fa-plus-circle",
    plusHexagon: "fa-plus-hexagon",
    plusOctagon: "fa-plus-octagon",
    plusSquare: "fa-plus-square",
    podcast: "fa-podcast",
    podium: "fa-podium",
    podiumStar: "fa-podium-star",
    policeBox: "fa-police-box",
    poll: "fa-poll",
    pollH: "fa-poll-h",
    pollPeople: "fa-poll-people",
    poo: "fa-poo",
    pooStorm: "fa-poo-storm",
    poop: "fa-poop",
    popcorn: "fa-popcorn",
    portalEnter: "fa-portal-enter",
    portalExit: "fa-portal-exit",
    portrait: "fa-portrait",
    poundSign: "fa-pound-sign",
    powerOff: "fa-power-off",
    pray: "fa-pray",
    prayingHands: "fa-praying-hands",
    prescription: "fa-prescription",
    prescriptionBottle: "fa-prescription-bottle",
    prescriptionBottleAlt: "fa-prescription-bottle-alt",
    presentation: "fa-presentation",
    print: "fa-print",
    printSearch: "fa-print-search",
    printSlash: "fa-print-slash",
    procedures: "fa-procedures",
    productHunt: "fa-product-hunt",
    projectDiagram: "fa-project-diagram",
    projector: "fa-projector",
    pumpMedical: "fa-pump-medical",
    pumpSoap: "fa-pump-soap",
    pumpkin: "fa-pumpkin",
    pushed: "fa-pushed",
    puzzlePiece: "fa-puzzle-piece",
    python: "fa-python",
    qq: "fa-qq",
    qrcode: "fa-qrcode",
    question: "fa-question",
    questionCircle: "fa-question-circle",
    questionSquare: "fa-question-square",
    quidditch: "fa-quidditch",
    quinscape: "fa-quinscape",
    quora: "fa-quora",
    quoteLeft: "fa-quote-left",
    quoteRight: "fa-quote-right",
    quran: "fa-quran",
    rProject: "fa-r-project",
    rabbit: "fa-rabbit",
    rabbitFast: "fa-rabbit-fast",
    racquet: "fa-racquet",
    radar: "fa-radar",
    radiation: "fa-radiation",
    radiationAlt: "fa-radiation-alt",
    radio: "fa-radio",
    radioAlt: "fa-radio-alt",
    rainbow: "fa-rainbow",
    raindrops: "fa-raindrops",
    ram: "fa-ram",
    rampLoading: "fa-ramp-loading",
    random: "fa-random",
    raspberryPi: "fa-raspberry-pi",
    ravelry: "fa-ravelry",
    raygun: "fa-raygun",
    react: "fa-react",
    reacteurope: "fa-reacteurope",
    readme: "fa-readme",
    rebel: "fa-rebel",
    receipt: "fa-receipt",
    recordVinyl: "fa-record-vinyl",
    rectangleLandscape: "fa-rectangle-landscape",
    rectanglePortrait: "fa-rectangle-portrait",
    rectangleWide: "fa-rectangle-wide",
    recycle: "fa-recycle",
    redRiver: "fa-red-river",
    reddit: "fa-reddit",
    redditAlien: "fa-reddit-alien",
    redditSquare: "fa-reddit-square",
    redhat: "fa-redhat",
    redo: "fa-redo",
    redoAlt: "fa-redo-alt",
    refrigerator: "fa-refrigerator",
    registered: "fa-registered",
    removeFormat: "fa-remove-format",
    renren: "fa-renren",
    repeat: "fa-repeat",
    repeat1: "fa-repeat-1",
    repeat1Alt: "fa-repeat-1-alt",
    repeatAlt: "fa-repeat-alt",
    reply: "fa-reply",
    replyAll: "fa-reply-all",
    replyd: "fa-replyd",
    republican: "fa-republican",
    researchgate: "fa-researchgate",
    resolving: "fa-resolving",
    restroom: "fa-restroom",
    retweet: "fa-retweet",
    retweetAlt: "fa-retweet-alt",
    rev: "fa-rev",
    ribbon: "fa-ribbon",
    ring: "fa-ring",
    ringsWedding: "fa-rings-wedding",
    road: "fa-road",
    robot: "fa-robot",
    rocket: "fa-rocket",
    rocketLaunch: "fa-rocket-launch",
    rocketchat: "fa-rocketchat",
    rockrms: "fa-rockrms",
    route: "fa-route",
    routeHighway: "fa-route-highway",
    routeInterstate: "fa-route-interstate",
    router: "fa-router",
    rss: "fa-rss",
    rssSquare: "fa-rss-square",
    rubleSign: "fa-ruble-sign",
    ruler: "fa-ruler",
    rulerCombined: "fa-ruler-combined",
    rulerHorizontal: "fa-ruler-horizontal",
    rulerTriangle: "fa-ruler-triangle",
    rulerVertical: "fa-ruler-vertical",
    running: "fa-running",
    rupeeSign: "fa-rupee-sign",
    rv: "fa-rv",
    sack: "fa-sack",
    sackDollar: "fa-sack-dollar",
    sadCry: "fa-sad-cry",
    sadTear: "fa-sad-tear",
    safari: "fa-safari",
    salad: "fa-salad",
    salesforce: "fa-salesforce",
    sandwich: "fa-sandwich",
    sass: "fa-sass",
    satellite: "fa-satellite",
    satelliteDish: "fa-satellite-dish",
    sausage: "fa-sausage",
    save: "fa-save",
    saxHot: "fa-sax-hot",
    saxophone: "fa-saxophone",
    scalpel: "fa-scalpel",
    scalpelPath: "fa-scalpel-path",
    scanner: "fa-scanner",
    scannerImage: "fa-scanner-image",
    scannerKeyboard: "fa-scanner-keyboard",
    scannerTouchscreen: "fa-scanner-touchscreen",
    scarecrow: "fa-scarecrow",
    scarf: "fa-scarf",
    schlix: "fa-schlix",
    school: "fa-school",
    screwdriver: "fa-screwdriver",
    scribd: "fa-scribd",
    scroll: "fa-scroll",
    scrollOld: "fa-scroll-old",
    scrubber: "fa-scrubber",
    scythe: "fa-scythe",
    sdCard: "fa-sd-card",
    search: "fa-search",
    searchDollar: "fa-search-dollar",
    searchLocation: "fa-search-location",
    searchMinus: "fa-search-minus",
    searchPlus: "fa-search-plus",
    searchengin: "fa-searchengin",
    seedling: "fa-seedling",
    sellcast: "fa-sellcast",
    sellsy: "fa-sellsy",
    sendBack: "fa-send-back",
    sendBackward: "fa-send-backward",
    sensor: "fa-sensor",
    sensorAlert: "fa-sensor-alert",
    sensorFire: "fa-sensor-fire",
    sensorOn: "fa-sensor-on",
    sensorSmoke: "fa-sensor-smoke",
    server: "fa-server",
    servicestack: "fa-servicestack",
    shapes: "fa-shapes",
    share: "fa-share",
    shareAll: "fa-share-all",
    shareAlt: "fa-share-alt",
    shareAltSquare: "fa-share-alt-square",
    shareSquare: "fa-share-square",
    sheep: "fa-sheep",
    shekelSign: "fa-shekel-sign",
    shield: "fa-shield",
    shieldAlt: "fa-shield-alt",
    shieldCheck: "fa-shield-check",
    shieldCross: "fa-shield-cross",
    shieldVirus: "fa-shield-virus",
    ship: "fa-ship",
    shippingFast: "fa-shipping-fast",
    shippingTimed: "fa-shipping-timed",
    shirtsinbulk: "fa-shirtsinbulk",
    shishKebab: "fa-shish-kebab",
    shoePrints: "fa-shoe-prints",
    shopify: "fa-shopify",
    shoppingBag: "fa-shopping-bag",
    shoppingBasket: "fa-shopping-basket",
    shoppingCart: "fa-shopping-cart",
    shopware: "fa-shopware",
    shovel: "fa-shovel",
    shovelSnow: "fa-shovel-snow",
    shower: "fa-shower",
    shredder: "fa-shredder",
    shuttleVan: "fa-shuttle-van",
    shuttlecock: "fa-shuttlecock",
    sickle: "fa-sickle",
    sigma: "fa-sigma",
    sign: "fa-sign",
    signIn: "fa-sign-in",
    signInAlt: "fa-sign-in-alt",
    signLanguage: "fa-sign-language",
    signOut: "fa-sign-out",
    signOutAlt: "fa-sign-out-alt",
    signal: "fa-signal",
    signal1: "fa-signal-1",
    signal2: "fa-signal-2",
    signal3: "fa-signal-3",
    signal4: "fa-signal-4",
    signalAlt: "fa-signal-alt",
    signalAlt1: "fa-signal-alt-1",
    signalAlt2: "fa-signal-alt-2",
    signalAlt3: "fa-signal-alt-3",
    signalAltSlash: "fa-signal-alt-slash",
    signalSlash: "fa-signal-slash",
    signalStream: "fa-signal-stream",
    signature: "fa-signature",
    simCard: "fa-sim-card",
    simplybuilt: "fa-simplybuilt",
    sink: "fa-sink",
    siren: "fa-siren",
    sirenOn: "fa-siren-on",
    sistrix: "fa-sistrix",
    sitemap: "fa-sitemap",
    sith: "fa-sith",
    skating: "fa-skating",
    skeleton: "fa-skeleton",
    sketch: "fa-sketch",
    skiJump: "fa-ski-jump",
    skiLift: "fa-ski-lift",
    skiing: "fa-skiing",
    skiingNordic: "fa-skiing-nordic",
    skull: "fa-skull",
    skullCow: "fa-skull-cow",
    skullCrossbones: "fa-skull-crossbones",
    skyatlas: "fa-skyatlas",
    skype: "fa-skype",
    slack: "fa-slack",
    slackHash: "fa-slack-hash",
    slash: "fa-slash",
    sledding: "fa-sledding",
    sleigh: "fa-sleigh",
    slidersH: "fa-sliders-h",
    slidersHSquare: "fa-sliders-h-square",
    slidersV: "fa-sliders-v",
    slidersVSquare: "fa-sliders-v-square",
    slideshare: "fa-slideshare",
    smile: "fa-smile",
    smileBeam: "fa-smile-beam",
    smilePlus: "fa-smile-plus",
    smileWink: "fa-smile-wink",
    smog: "fa-smog",
    smoke: "fa-smoke",
    smoking: "fa-smoking",
    smokingBan: "fa-smoking-ban",
    sms: "fa-sms",
    snake: "fa-snake",
    snapchat: "fa-snapchat",
    snapchatGhost: "fa-snapchat-ghost",
    snapchatSquare: "fa-snapchat-square",
    snooze: "fa-snooze",
    snowBlowing: "fa-snow-blowing",
    snowboarding: "fa-snowboarding",
    snowflake: "fa-snowflake",
    snowflakes: "fa-snowflakes",
    snowman: "fa-snowman",
    snowmobile: "fa-snowmobile",
    snowplow: "fa-snowplow",
    soap: "fa-soap",
    socks: "fa-socks",
    solarPanel: "fa-solar-panel",
    solarSystem: "fa-solar-system",
    sort: "fa-sort",
    sortAlphaDown: "fa-sort-alpha-down",
    sortAlphaDownAlt: "fa-sort-alpha-down-alt",
    sortAlphaUp: "fa-sort-alpha-up",
    sortAlphaUpAlt: "fa-sort-alpha-up-alt",
    sortAlt: "fa-sort-alt",
    sortAmountDown: "fa-sort-amount-down",
    sortAmountDownAlt: "fa-sort-amount-down-alt",
    sortAmountUp: "fa-sort-amount-up",
    sortAmountUpAlt: "fa-sort-amount-up-alt",
    sortCircle: "fa-sort-circle",
    sortCircleDown: "fa-sort-circle-down",
    sortCircleUp: "fa-sort-circle-up",
    sortDown: "fa-sort-down",
    sortNumericDown: "fa-sort-numeric-down",
    sortNumericDownAlt: "fa-sort-numeric-down-alt",
    sortNumericUp: "fa-sort-numeric-up",
    sortNumericUpAlt: "fa-sort-numeric-up-alt",
    sortShapesDown: "fa-sort-shapes-down",
    sortShapesDownAlt: "fa-sort-shapes-down-alt",
    sortShapesUp: "fa-sort-shapes-up",
    sortShapesUpAlt: "fa-sort-shapes-up-alt",
    sortSizeDown: "fa-sort-size-down",
    sortSizeDownAlt: "fa-sort-size-down-alt",
    sortSizeUp: "fa-sort-size-up",
    sortSizeUpAlt: "fa-sort-size-up-alt",
    sortUp: "fa-sort-up",
    soundcloud: "fa-soundcloud",
    soup: "fa-soup",
    sourcetree: "fa-sourcetree",
    spa: "fa-spa",
    spaceShuttle: "fa-space-shuttle",
    spaceStationMoon: "fa-space-station-moon",
    spaceStationMoonAlt: "fa-space-station-moon-alt",
    spade: "fa-spade",
    sparkles: "fa-sparkles",
    speakap: "fa-speakap",
    speaker: "fa-speaker",
    speakerDeck: "fa-speaker-deck",
    speakers: "fa-speakers",
    spellCheck: "fa-spell-check",
    spider: "fa-spider",
    spiderBlackWidow: "fa-spider-black-widow",
    spiderWeb: "fa-spider-web",
    spinner: "fa-spinner",
    spinnerThird: "fa-spinner-third",
    splotch: "fa-splotch",
    spotify: "fa-spotify",
    sprayCan: "fa-spray-can",
    sprinkler: "fa-sprinkler",
    square: "fa-square",
    squareFull: "fa-square-full",
    squareRoot: "fa-square-root",
    squareRootAlt: "fa-square-root-alt",
    squarespace: "fa-squarespace",
    squirrel: "fa-squirrel",
    stackExchange: "fa-stack-exchange",
    stackOverflow: "fa-stack-overflow",
    stackpath: "fa-stackpath",
    staff: "fa-staff",
    stamp: "fa-stamp",
    star: "fa-star",
    starAndCrescent: "fa-star-and-crescent",
    starChristmas: "fa-star-christmas",
    starExclamation: "fa-star-exclamation",
    starHalf: "fa-star-half",
    starHalfAlt: "fa-star-half-alt",
    starOfDavid: "fa-star-of-david",
    starOfLife: "fa-star-of-life",
    starShooting: "fa-star-shooting",
    starfighter: "fa-starfighter",
    starfighterAlt: "fa-starfighter-alt",
    stars: "fa-stars",
    starship: "fa-starship",
    starshipFreighter: "fa-starship-freighter",
    staylinked: "fa-staylinked",
    steak: "fa-steak",
    steam: "fa-steam",
    steamSquare: "fa-steam-square",
    steamSymbol: "fa-steam-symbol",
    steeringWheel: "fa-steering-wheel",
    stepBackward: "fa-step-backward",
    stepForward: "fa-step-forward",
    stethoscope: "fa-stethoscope",
    stickerMule: "fa-sticker-mule",
    stickyNote: "fa-sticky-note",
    stocking: "fa-stocking",
    stomach: "fa-stomach",
    stop: "fa-stop",
    stopCircle: "fa-stop-circle",
    stopwatch: "fa-stopwatch",
    stopwatch20: "fa-stopwatch-20",
    store: "fa-store",
    storeAlt: "fa-store-alt",
    storeAltSlash: "fa-store-alt-slash",
    storeSlash: "fa-store-slash",
    strava: "fa-strava",
    stream: "fa-stream",
    streetView: "fa-street-view",
    stretcher: "fa-stretcher",
    strikethrough: "fa-strikethrough",
    stripe: "fa-stripe",
    stripeS: "fa-stripe-s",
    stroopwafel: "fa-stroopwafel",
    studiovinari: "fa-studiovinari",
    stumbleupon: "fa-stumbleupon",
    stumbleuponCircle: "fa-stumbleupon-circle",
    subscript: "fa-subscript",
    subway: "fa-subway",
    suitcase: "fa-suitcase",
    suitcaseRolling: "fa-suitcase-rolling",
    sun: "fa-sun",
    sunCloud: "fa-sun-cloud",
    sunDust: "fa-sun-dust",
    sunHaze: "fa-sun-haze",
    sunglasses: "fa-sunglasses",
    sunrise: "fa-sunrise",
    sunset: "fa-sunset",
    superpowers: "fa-superpowers",
    superscript: "fa-superscript",
    supple: "fa-supple",
    surprise: "fa-surprise",
    suse: "fa-suse",
    swatchbook: "fa-swatchbook",
    swift: "fa-swift",
    swimmer: "fa-swimmer",
    swimmingPool: "fa-swimming-pool",
    sword: "fa-sword",
    swordLaser: "fa-sword-laser",
    swordLaserAlt: "fa-sword-laser-alt",
    swords: "fa-swords",
    swordsLaser: "fa-swords-laser",
    symfony: "fa-symfony",
    synagogue: "fa-synagogue",
    sync: "fa-sync",
    syncAlt: "fa-sync-alt",
    syringe: "fa-syringe",
    table: "fa-table",
    tableTennis: "fa-table-tennis",
    tablet: "fa-tablet",
    tabletAlt: "fa-tablet-alt",
    tabletAndroid: "fa-tablet-android",
    tabletAndroidAlt: "fa-tablet-android-alt",
    tabletRugged: "fa-tablet-rugged",
    tablets: "fa-tablets",
    tachometer: "fa-tachometer",
    tachometerAlt: "fa-tachometer-alt",
    tachometerAltAverage: "fa-tachometer-alt-average",
    tachometerAltFast: "fa-tachometer-alt-fast",
    tachometerAltFastest: "fa-tachometer-alt-fastest",
    tachometerAltSlow: "fa-tachometer-alt-slow",
    tachometerAltSlowest: "fa-tachometer-alt-slowest",
    tachometerAverage: "fa-tachometer-average",
    tachometerFast: "fa-tachometer-fast",
    tachometerFastest: "fa-tachometer-fastest",
    tachometerSlow: "fa-tachometer-slow",
    tachometerSlowest: "fa-tachometer-slowest",
    taco: "fa-taco",
    tag: "fa-tag",
    tags: "fa-tags",
    tally: "fa-tally",
    tanakh: "fa-tanakh",
    tape: "fa-tape",
    tasks: "fa-tasks",
    tasksAlt: "fa-tasks-alt",
    taxi: "fa-taxi",
    teamspeak: "fa-teamspeak",
    teeth: "fa-teeth",
    teethOpen: "fa-teeth-open",
    telegram: "fa-telegram",
    telegramPlane: "fa-telegram-plane",
    telescope: "fa-telescope",
    temperatureDown: "fa-temperature-down",
    temperatureFrigid: "fa-temperature-frigid",
    temperatureHigh: "fa-temperature-high",
    temperatureHot: "fa-temperature-hot",
    temperatureLow: "fa-temperature-low",
    temperatureUp: "fa-temperature-up",
    tencentWeibo: "fa-tencent-weibo",
    tenge: "fa-tenge",
    tennisBall: "fa-tennis-ball",
    terminal: "fa-terminal",
    text: "fa-text",
    textHeight: "fa-text-height",
    textSize: "fa-text-size",
    textWidth: "fa-text-width",
    th: "fa-th",
    thLarge: "fa-th-large",
    thList: "fa-th-list",
    theRedYeti: "fa-the-red-yeti",
    theaterMasks: "fa-theater-masks",
    themeco: "fa-themeco",
    themeisle: "fa-themeisle",
    thermometer: "fa-thermometer",
    thermometerEmpty: "fa-thermometer-empty",
    thermometerFull: "fa-thermometer-full",
    thermometerHalf: "fa-thermometer-half",
    thermometerQuarter: "fa-thermometer-quarter",
    thermometerThreeQuarters: "fa-thermometer-three-quarters",
    theta: "fa-theta",
    thinkPeaks: "fa-think-peaks",
    thumbsDown: "fa-thumbs-down",
    thumbsUp: "fa-thumbs-up",
    thumbtack: "fa-thumbtack",
    thunderstorm: "fa-thunderstorm",
    thunderstormMoon: "fa-thunderstorm-moon",
    thunderstormSun: "fa-thunderstorm-sun",
    ticket: "fa-ticket",
    ticketAlt: "fa-ticket-alt",
    tilde: "fa-tilde",
    times: "fa-times",
    timesCircle: "fa-times-circle",
    timesHexagon: "fa-times-hexagon",
    timesOctagon: "fa-times-octagon",
    timesSquare: "fa-times-square",
    tint: "fa-tint",
    tintSlash: "fa-tint-slash",
    tire: "fa-tire",
    tireFlat: "fa-tire-flat",
    tirePressureWarning: "fa-tire-pressure-warning",
    tireRugged: "fa-tire-rugged",
    tired: "fa-tired",
    toggleOff: "fa-toggle-off",
    toggleOn: "fa-toggle-on",
    toilet: "fa-toilet",
    toiletPaper: "fa-toilet-paper",
    toiletPaperAlt: "fa-toilet-paper-alt",
    toiletPaperSlash: "fa-toilet-paper-slash",
    tombstone: "fa-tombstone",
    tombstoneAlt: "fa-tombstone-alt",
    toolbox: "fa-toolbox",
    tools: "fa-tools",
    tooth: "fa-tooth",
    toothbrush: "fa-toothbrush",
    torah: "fa-torah",
    toriiGate: "fa-torii-gate",
    tornado: "fa-tornado",
    tractor: "fa-tractor",
    tradeFederation: "fa-trade-federation",
    trademark: "fa-trademark",
    trafficCone: "fa-traffic-cone",
    trafficLight: "fa-traffic-light",
    trafficLightGo: "fa-traffic-light-go",
    trafficLightSlow: "fa-traffic-light-slow",
    trafficLightStop: "fa-traffic-light-stop",
    trailer: "fa-trailer",
    train: "fa-train",
    tram: "fa-tram",
    transgender: "fa-transgender",
    transgenderAlt: "fa-transgender-alt",
    transporter: "fa-transporter",
    transporter1: "fa-transporter-1",
    transporter2: "fa-transporter-2",
    transporter3: "fa-transporter-3",
    transporterEmpty: "fa-transporter-empty",
    trash: "fa-trash",
    trashAlt: "fa-trash-alt",
    trashRestore: "fa-trash-restore",
    trashRestoreAlt: "fa-trash-restore-alt",
    trashUndo: "fa-trash-undo",
    trashUndoAlt: "fa-trash-undo-alt",
    treasureChest: "fa-treasure-chest",
    tree: "fa-tree",
    treeAlt: "fa-tree-alt",
    treeChristmas: "fa-tree-christmas",
    treeDecorated: "fa-tree-decorated",
    treeLarge: "fa-tree-large",
    treePalm: "fa-tree-palm",
    trees: "fa-trees",
    trello: "fa-trello",
    triangle: "fa-triangle",
    triangleMusic: "fa-triangle-music",
    tripadvisor: "fa-tripadvisor",
    trophy: "fa-trophy",
    trophyAlt: "fa-trophy-alt",
    truck: "fa-truck",
    truckContainer: "fa-truck-container",
    truckCouch: "fa-truck-couch",
    truckLoading: "fa-truck-loading",
    truckMonster: "fa-truck-monster",
    truckMoving: "fa-truck-moving",
    truckPickup: "fa-truck-pickup",
    truckPlow: "fa-truck-plow",
    truckRamp: "fa-truck-ramp",
    trumpet: "fa-trumpet",
    tshirt: "fa-tshirt",
    tty: "fa-tty",
    tumblr: "fa-tumblr",
    tumblrSquare: "fa-tumblr-square",
    turkey: "fa-turkey",
    turntable: "fa-turntable",
    turtle: "fa-turtle",
    tv: "fa-tv",
    tvAlt: "fa-tv-alt",
    tvMusic: "fa-tv-music",
    tvRetro: "fa-tv-retro",
    twitch: "fa-twitch",
    twitter: "fa-twitter",
    twitterSquare: "fa-twitter-square",
    typewriter: "fa-typewriter",
    typo3: "fa-typo3",
    uber: "fa-uber",
    ubuntu: "fa-ubuntu",
    ufo: "fa-ufo",
    ufoBeam: "fa-ufo-beam",
    uikit: "fa-uikit",
    umbraco: "fa-umbraco",
    umbrella: "fa-umbrella",
    umbrellaBeach: "fa-umbrella-beach",
    underline: "fa-underline",
    undo: "fa-undo",
    undoAlt: "fa-undo-alt",
    unicorn: "fa-unicorn",
    union: "fa-union",
    uniregistry: "fa-uniregistry",
    unity: "fa-unity",
    universalAccess: "fa-universal-access",
    university: "fa-university",
    unlink: "fa-unlink",
    unlock: "fa-unlock",
    unlockAlt: "fa-unlock-alt",
    untappd: "fa-untappd",
    upload: "fa-upload",
    ups: "fa-ups",
    usb: "fa-usb",
    usbDrive: "fa-usb-drive",
    usdCircle: "fa-usd-circle",
    usdSquare: "fa-usd-square",
    user: "fa-user",
    userAlien: "fa-user-alien",
    userAlt: "fa-user-alt",
    userAltSlash: "fa-user-alt-slash",
    userAstronaut: "fa-user-astronaut",
    userChart: "fa-user-chart",
    userCheck: "fa-user-check",
    userCircle: "fa-user-circle",
    userClock: "fa-user-clock",
    userCog: "fa-user-cog",
    userCowboy: "fa-user-cowboy",
    userCrown: "fa-user-crown",
    userEdit: "fa-user-edit",
    userFriends: "fa-user-friends",
    userGraduate: "fa-user-graduate",
    userHardHat: "fa-user-hard-hat",
    userHeadset: "fa-user-headset",
    userInjured: "fa-user-injured",
    userLock: "fa-user-lock",
    userMd: "fa-user-md",
    userMdChat: "fa-user-md-chat",
    userMinus: "fa-user-minus",
    userMusic: "fa-user-music",
    userNinja: "fa-user-ninja",
    userNurse: "fa-user-nurse",
    userPlus: "fa-user-plus",
    userRobot: "fa-user-robot",
    userSecret: "fa-user-secret",
    userShield: "fa-user-shield",
    userSlash: "fa-user-slash",
    userTag: "fa-user-tag",
    userTie: "fa-user-tie",
    userTimes: "fa-user-times",
    userUnlock: "fa-user-unlock",
    userVisor: "fa-user-visor",
    users: "fa-users",
    usersClass: "fa-users-class",
    usersCog: "fa-users-cog",
    usersCrown: "fa-users-crown",
    usersMedical: "fa-users-medical",
    usersSlash: "fa-users-slash",
    usps: "fa-usps",
    ussunnah: "fa-ussunnah",
    utensilFork: "fa-utensil-fork",
    utensilKnife: "fa-utensil-knife",
    utensilSpoon: "fa-utensil-spoon",
    utensils: "fa-utensils",
    utensilsAlt: "fa-utensils-alt",
    vaadin: "fa-vaadin",
    vacuum: "fa-vacuum",
    vacuumRobot: "fa-vacuum-robot",
    valueAbsolute: "fa-value-absolute",
    vectorSquare: "fa-vector-square",
    venus: "fa-venus",
    venusDouble: "fa-venus-double",
    venusMars: "fa-venus-mars",
    vhs: "fa-vhs",
    viacoin: "fa-viacoin",
    viadeo: "fa-viadeo",
    viadeoSquare: "fa-viadeo-square",
    vial: "fa-vial",
    vials: "fa-vials",
    viber: "fa-viber",
    video: "fa-video",
    videoPlus: "fa-video-plus",
    videoSlash: "fa-video-slash",
    vihara: "fa-vihara",
    vimeo: "fa-vimeo",
    vimeoSquare: "fa-vimeo-square",
    vimeoV: "fa-vimeo-v",
    vine: "fa-vine",
    violin: "fa-violin",
    virus: "fa-virus",
    virusSlash: "fa-virus-slash",
    viruses: "fa-viruses",
    vk: "fa-vk",
    vnv: "fa-vnv",
    voicemail: "fa-voicemail",
    volcano: "fa-volcano",
    volleyballBall: "fa-volleyball-ball",
    volume: "fa-volume",
    volumeDown: "fa-volume-down",
    volumeMute: "fa-volume-mute",
    volumeOff: "fa-volume-off",
    volumeSlash: "fa-volume-slash",
    volumeUp: "fa-volume-up",
    voteNay: "fa-vote-nay",
    voteYea: "fa-vote-yea",
    vrCardboard: "fa-vr-cardboard",
    vuejs: "fa-vuejs",
    wagonCovered: "fa-wagon-covered",
    walker: "fa-walker",
    walkieTalkie: "fa-walkie-talkie",
    walking: "fa-walking",
    wallet: "fa-wallet",
    wand: "fa-wand",
    wandMagic: "fa-wand-magic",
    warehouse: "fa-warehouse",
    warehouseAlt: "fa-warehouse-alt",
    washer: "fa-washer",
    watch: "fa-watch",
    watchCalculator: "fa-watch-calculator",
    watchFitness: "fa-watch-fitness",
    water: "fa-water",
    waterLower: "fa-water-lower",
    waterRise: "fa-water-rise",
    waveSine: "fa-wave-sine",
    waveSquare: "fa-wave-square",
    waveTriangle: "fa-wave-triangle",
    waveform: "fa-waveform",
    waveformPath: "fa-waveform-path",
    waze: "fa-waze",
    webcam: "fa-webcam",
    webcamSlash: "fa-webcam-slash",
    weebly: "fa-weebly",
    weibo: "fa-weibo",
    weight: "fa-weight",
    weightHanging: "fa-weight-hanging",
    weixin: "fa-weixin",
    whale: "fa-whale",
    whatsapp: "fa-whatsapp",
    whatsappSquare: "fa-whatsapp-square",
    wheat: "fa-wheat",
    wheelchair: "fa-wheelchair",
    whistle: "fa-whistle",
    whmcs: "fa-whmcs",
    wifi: "fa-wifi",
    wifi1: "fa-wifi-1",
    wifi2: "fa-wifi-2",
    wifiSlash: "fa-wifi-slash",
    wikipediaW: "fa-wikipedia-w",
    wind: "fa-wind",
    windTurbine: "fa-wind-turbine",
    windWarning: "fa-wind-warning",
    window: "fa-window",
    windowAlt: "fa-window-alt",
    windowClose: "fa-window-close",
    windowFrame: "fa-window-frame",
    windowFrameOpen: "fa-window-frame-open",
    windowMaximize: "fa-window-maximize",
    windowMinimize: "fa-window-minimize",
    windowRestore: "fa-window-restore",
    windows: "fa-windows",
    windsock: "fa-windsock",
    wineBottle: "fa-wine-bottle",
    wineGlass: "fa-wine-glass",
    wineGlassAlt: "fa-wine-glass-alt",
    wix: "fa-wix",
    wizardsOfTheCoast: "fa-wizards-of-the-coast",
    wolfPackBattalion: "fa-wolf-pack-battalion",
    wonSign: "fa-won-sign",
    wordpress: "fa-wordpress",
    wordpressSimple: "fa-wordpress-simple",
    wpbeginner: "fa-wpbeginner",
    wpexplorer: "fa-wpexplorer",
    wpforms: "fa-wpforms",
    wpressr: "fa-wpressr",
    wreath: "fa-wreath",
    wrench: "fa-wrench",
    xRay: "fa-x-ray",
    xbox: "fa-xbox",
    xing: "fa-xing",
    xingSquare: "fa-xing-square",
    yCombinator: "fa-y-combinator",
    yahoo: "fa-yahoo",
    yammer: "fa-yammer",
    yandex: "fa-yandex",
    yandexInternational: "fa-yandex-international",
    yarn: "fa-yarn",
    yelp: "fa-yelp",
    yenSign: "fa-yen-sign",
    yinYang: "fa-yin-yang",
    yoast: "fa-yoast",
    youtube: "fa-youtube",
    youtubeSquare: "fa-youtube-square",
    zhihu: "fa-zhihu",
};
