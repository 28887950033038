import { Switch, Route, Redirect, useParams } from 'react-router-dom';
import { IEnvTenantSiteParams, ManagedViewContext } from '@rapid/sdk';
import { AdaptiveView, AdaptiveViewContext } from './features/adaptiveView/adaptiveView';
import { AdaptiveViewsList } from './features/AdaptiveViewsList/adaptiveViewsList';
import { DashboardsList } from './features/dashboardsList/dashboardsList';
import { PBIDataset } from './features/dataset/dataset';
import { PBIDatasetContext } from './features/dataset/datasetContext';
import { PBIDatasetsList } from './features/datasetsList/datasetsList';
import { NewPBIReport } from './features/report/newReport';
import { PBIReport } from './features/report/report';
import { PBIReportsList } from './features/reportsList/reportsList';
import { Dashboard } from './features/dashboard/dashboard';

export function Pages() {
  const params = useParams<IEnvTenantSiteParams>();
  return (
      <Switch>
        <Route
          exact
          path="/_:env/:tenant/:site/*/adaptiveview::viewId/"
        >
          <AdaptiveViewContext>
            <AdaptiveView />
          </AdaptiveViewContext>
        </Route>
        <Route
          exact path="/_:env/:tenant/:site/*/pbireport::reportId"
          component={PBIReport}
        />
        <Route
          exact
          path="/_:env/:tenant/:site/*/newreport::datasetId"
          component={NewPBIReport}
        />
        <Route exact path="/_:env/:tenant/:site/*/pbidataset::datasetId">
          <PBIDatasetContext>
            <PBIDataset />
          </PBIDatasetContext>
        </Route>
        <Route
          path="/_:env/:tenant/:site/pbireports"
          component={PBIReportsList}
        />
        <Route
          path="/_:env/:tenant/:site/pbidatasets"
          component={PBIDatasetsList}
        />     
        <Route exact path="/_:env/:tenant/:site/adaptiveviews">
          <ManagedViewContext
            list="Adaptive Views"
            dataSource="All"
            columns={[
              'id',
              'title',
              'slug',
              'view_name',
              'schema',
              'description',
            ]}
          >
            <AdaptiveViewsList />
          </ManagedViewContext>
        </Route>
        <Route
          exact path="/_:env/:tenant/:site/*/dashboard::dashboardId"
          component={Dashboard}
        />
        <Route
          path="/_:env/:tenant/:site/dashboards"
          component={DashboardsList}
        />
        <Redirect
          from="/_:env/:tenant/:site"
          to={`/_${params.env}/${params.tenant}/${params.site}/dashboards`}
        />
      </Switch>
  );
}
