import { FormElementInputProps } from '@rapid/adaptive-forms';
import { useComposedClassName } from '@rapid/sdk';
import { Radio, RadioChangeEvent } from 'antd';
import { useEffect } from 'react';

export function AndOrToggle({
  disabled,
  id,
  value,
  label,
  onChange,
}: FormElementInputProps<string>) {
  const options = [
    { label: 'And', value: 'and' },
    { label: 'Or', value: 'or' },
  ];

  const andOrToggleClass = useComposedClassName(
    function* () {
      yield 'Label AndOrToggle';

      if (disabled) yield 'disabled';
    },
    [disabled],
  );

  const onChangeInner = (e: RadioChangeEvent) => {
    e.preventDefault();
    e.stopPropagation();

    onChange?.(e.target.value);
  };

  useEffect(
    function setDefaultValueEffect() {
      if (value === undefined) onChange?.(options[0].value);
    },
    [value],
  );

  return (
    <label className={andOrToggleClass} id={id}>
      {!!label && <span className="Name">{label}</span>}
      <Radio.Group
        options={options}
        onChange={onChangeInner}
        value={value}
        optionType="button"
        size="small"
      />
    </label>
  );
}
