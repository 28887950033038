import { IFormElement } from '@rapid/adaptive-forms';
import { v4 as uuidv4 } from 'uuid';
import { QueryGroup } from './base-layout';
import { JoinGroup } from './sql-join-layout';

export function BasicQuery(uuid: string = uuidv4()): IFormElement {
  return {
    $type: 'AdaptiveForm',
    id: 'root',
    $children: [
      {
        $type: 'Input.FieldSelect' as any,
        id: `Select:~:${uuid}`,
        label: 'Select',
        attributes: {
          multiple: true,
        },
      },
      {
        $type: 'Input.TableAlias' as any,
        id: `From:~:${uuid}`,
        label: 'From',
      },
    ],
  } as IFormElement;
}

export function SQLQuery(uuid: string = uuidv4()): IFormElement {
  return {
    $type: 'AdaptiveForm',
    id: 'root',
    $children: [
      {
        $type: 'Input.Text' as any,
        id: `Name:~:${uuid}`,
        label: 'Name',
        size: 'small',
      },
      {
        $type: 'Input.FieldSelect' as any,
        id: `Select:~:${uuid}`,
        label: 'Select',
        attributes: {
          multiple: true,
        },
      },
      {
        $type: 'Input.TableAlias' as any,
        id: `From:~:${uuid}`,
        label: 'From',
      },
      JoinGroup(),
      {
        $type: 'Layout.Heading' as any,
        id: `Heading:~:${uuid}-Where`,
        attributes: {
          label: 'Where',
          type: 'h5',
        },
      },
      QueryGroup(),
      {
        $type: 'Input.OrderBy',
        id: `OrderBy:~:${uuid}`,
        label: 'Order By',
      },
    ],
  } as IFormElement;
}
