import { useLayoutEffect, useMemo } from 'react';
import { useCurrentSite } from './use-current-site';
import { useField, useList } from './use-list';
import { kebabCase } from './utils/kebab-case';
function* allChoiceFields(site) {
    var _a, _b;
    for (const list of site.Lists) {
        for (const field of list.Fields) {
            if (field.FieldType === 'Choice') {
                for (const choice of (_b = (_a = field.Settings) === null || _a === void 0 ? void 0 : _a.Choices) !== null && _b !== void 0 ? _b : []) {
                    yield { list, field, choice };
                }
            }
        }
    }
}
function cssVariableName(list, field, choice) {
    return `${kebabCase(list.ListName)}--${kebabCase(field.ColumnName)}--${kebabCase(typeof choice === 'string' ? choice : choice.Text)}`;
}
/**
 * Used once on page load, Adds CSS class names for all choice fields
 * in the current site to a style tag in the header of the document.
 *
 * Enables access to the {@link useChoiceClassName} hook.
 *
 * @hook
 * @category RAPID Application
 */
export function useSiteCSSVariables() {
    const [site] = useCurrentSite();
    useLayoutEffect(function siteDidChangeEffect() {
        if (!site) {
            return;
        }
        const styleTag = document.createElement('style');
        styleTag.setAttribute('data-rapid-generated-style', 'true');
        const styles = Array.from(allChoiceFields(site))
            .map(({ field, list, choice }) => {
            var _a;
            return `
.${cssVariableName(list, field, choice)} {
  --choice-colour: ${typeof choice !== 'string' ? (_a = choice.AccentColour) !== null && _a !== void 0 ? _a : 'inherit' : 'inherit'};
 }`;
        })
            .join('');
        styleTag.appendChild(document.createTextNode(styles));
        document.head.appendChild(styleTag);
        return function siteDidUnmountEffect() {
            document.head.removeChild(styleTag);
        };
    }, [site]);
}
/**
 * Returns the CSS class name that contains colour information generated
 * from the site file for the provided choice field.
 *
 * Descendent elements can use `--choice-colour` css variable value for
 * correct colouring of a choice.
 *
 * @param list The list the choice field is in
 * @param field The field reference of the choice field
 * @param choice The choice field
 *
 * @hook
 *
 * @category RAPID Application
 *
 * @returns A css class name to be put on parent elements to be coloured.
 */
export function useChoiceClassName(list, field, choice) {
    const [innerList] = useList(list);
    const [innerField] = useField(list, field);
    return useMemo(() => choice ? `${cssVariableName(innerList, innerField, choice)}` : '', [list, field, choice]);
}
