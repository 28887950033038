import { IField, IList } from "@rapid/data-model";
import { useCurrentSite } from "@rapid/sdk";
import { allFields } from "./allFields";

export function useListFields() {
  const [currentSite] = useCurrentSite();

  function getListFields(listOrTable: string | IList<any>): IField[]|undefined {
     
    const innerList =
      typeof listOrTable !== 'string'
        ? listOrTable
        : currentSite?.Lists.find(l => l.ListName.localeCompare(listOrTable) === 0 || l.Table.localeCompare(listOrTable) === 0);

    if (!innerList) return undefined;
  
    if (innerList.Extends === undefined)
      return allFields(innerList);
  
    const extendedList = currentSite?.Lists.find(
        l =>
          l.ListName.localeCompare(
            innerList.Extends!) === 0,
      )!;

    if (!extendedList?.Fields)
      return allFields(innerList);

    return [
      ...extendedList.Fields,
      ...allFields(innerList)
    ] as IField[];    
  }


  return { getListFields };
}