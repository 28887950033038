import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router';
import { Button } from '../../ui/button';
import { EnvironmentPicker } from '../../ui/environment-picker';
import { useInputState } from '../../use-input-state';
export default function NewConnection({ onNewConnection, }) {
    const params = useParams();
    const [tenant, onTenantChange] = useInputState(params.tenant);
    const [site, onSiteChange] = useInputState(params.site);
    const [env, setEnv] = useState("prod");
    const ref = useRef();
    function onSubmit(ev) {
        ev.preventDefault();
        onNewConnection === null || onNewConnection === void 0 ? void 0 : onNewConnection(env, tenant, site);
    }
    function onAddClicked(ev) {
        ev.preventDefault();
        onNewConnection === null || onNewConnection === void 0 ? void 0 : onNewConnection(env, tenant, site);
    }
    useEffect(function componentDidMount() {
        var _a;
        (_a = ref.current) === null || _a === void 0 ? void 0 : _a.focus();
    }, []);
    return (React.createElement("form", { className: "rp-login-panel-add-new rp-flex rp-flex-column rp-flex-middle", onSubmit: onSubmit },
        !params.tenant && (React.createElement("input", { ref: ref, value: tenant !== null && tenant !== void 0 ? tenant : '', onChange: onTenantChange, type: "text", className: "rp-input rp-login-input", placeholder: "Tenant", required: true })),
        !params.site && (React.createElement("input", { value: site !== null && site !== void 0 ? site : '', onChange: onSiteChange, type: "text", className: "rp-input rp-login-input rp-margin-small-top", placeholder: "Site", required: true })),
        React.createElement(EnvironmentPicker, { value: env, onChange: setEnv }),
        React.createElement(Button, { buttonSize: "small", buttonStyle: "primary", fullWidth: true, onClick: onAddClicked, className: "rp-login-button", isDisabled: !tenant || !site }, "Add")));
}
