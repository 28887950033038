import React from 'react';
export function ListItem({ children, ...props }) {
    return (React.createElement("a", Object.assign({ className: "rp-flex rp-list-item" }, props), children));
}
export function ListItemLeftContent({ children }) {
    return React.createElement("div", { className: "rp-list-left" }, children);
}
export function ListItemCenterContent({ children, }) {
    return React.createElement("div", { className: "rp-list-center" }, children);
}
export function ListItemRightContent({ children, }) {
    return React.createElement("div", { className: "rp-list-right" }, children);
}
