import React, { useContext, useEffect, useMemo } from 'react';
import { safeJsonParse } from '../safe-json-parse';
import { useImmer } from '../use-immer';
import { useLocalStorage } from '../use-local-storage-state';
const appContext = React.createContext(null);
const AppProvider = appContext.Provider;
appContext.displayName = 'RAPID Application Context';
export function ApplicationContext({ displayName, clientId, children, namespace, tenant, site, environment, copyright, }) {
    const connections = useMemo(() => safeJsonParse(localStorage.getItem(`${namespace}::connections`), []), [namespace]);
    const [appData, setAppData] = useImmer({
        clientId,
        displayName,
        namespace,
        copyright,
        connections,
        tenant,
        site,
        env: environment,
        environments: {},
    });
    useLocalStorage(`${namespace}::connections`, appData.connections, [
        appData.connections,
    ]);
    useEffect(function componentDidMountEffect() {
        fetch('https://cdn.rapidplatform.com/.well-known/environments.json')
            .then(res => res.json())
            .then(environments => {
            setAppData(draft => {
                draft.environments = environments;
            });
        });
    }, []);
    return (React.createElement(React.Fragment, null, !!Object.keys(appData.environments).length && (React.createElement(AppProvider, { value: [appData, setAppData] }, children))));
}
/**
 * Returns the currently running RAPID Application's context.
 *
 * @hook
 * @category RAPID Application
 */
export function useRapidApplication() {
    const [app, updateApp] = useContext(appContext);
    return [app, updateApp];
}
