import { AdaptiveRenderer, IFormElement } from '@rapid/adaptive-forms';
import { Updater, useImmer } from '@rapid/sdk';
import React, { useContext, useEffect, useMemo } from 'react';
import { AddGroup } from './actions/add-group';
import { AddRule } from './actions/add-rule';
import { RemoveItem } from './actions/remove-item';
import { OperandField } from './fields/operand-field';
import { FieldSelect } from './fields/field-select';
import { TableSelect } from './fields/table-select';
import { AndOrToggle } from './fields/and-or-toggle';
import { AddJoin } from './actions/add-join';
import type { RuleGroupType } from 'react-querybuilder';
import { DefaultFields, IList } from '@rapid/data-model';
import { GenericField } from './fields/generic-field';
import { ODataNode } from '@rapid/odata-parser';
import { queryParse } from './utils/parser-functions';

import './QueryBuilder.scss';
import { OrderBySelect } from './fields/orderby-select';
import { TableAliasInput } from './fields/table-alias-input';
import { IQueryBuilderJoin, IQueryBuilderSQL, IQueryBuilderTable } from '@rapid/data-model/lib/query-builder';

export enum IQueryBuilderType {
  'Basic',
  'SQL',
}

export interface TableAlias {
  id: string;
  table: string;
  alias?: string;
  list?: IList;
}

export interface FieldAlias {
  id: string;
  table: string;
  tableAlias?: string;
  column: string;
}
export interface IQueryBuilderProps {
  lists: IList<any>[];
  data?: IQueryBuilderSQL | RuleGroupType | ODataNode;
  type: IQueryBuilderType;
  onChange(data: Record<string, any>): void;
}

export interface IQueryBuilderContext {
  lists: IList<any>[];
  fieldChoices: (string | FieldAlias)[];
  tableChoices: string[];
  form: IFormElement;
  data: Record<string, any>;
  type: IQueryBuilderType;
}

const queryBuilderContext = React.createContext<
  [IQueryBuilderContext, Updater<IQueryBuilderContext>]
>({} as any);

export function QueryBuilder({
  onChange,
  lists,
  type,
  data,
}: React.PropsWithRef<IQueryBuilderProps>) {
  const [initialForm, initialData] = queryParse(type, data);

  const [context, updateContext] = useImmer<IQueryBuilderContext>({
    form: initialForm,
    data: initialData,
    lists: lists,
    type: type,
    tableChoices: [],
    fieldChoices: [],
  });

  const tableChoices = useMemo(() => lists.map(l => l.Table), [lists]);

  const basicChoices = useMemo(
    () =>
      context.lists.flatMap(list => [
        `${list.Table}.id`,
        ...DefaultFields(list).flatMap(
          field => `${list.Table}.${field.ColumnName}`,
        ),
        ...list.Fields.flatMap(field => `${list.Table}.${field.ColumnName}`),
      ]),
    [lists],
  );

  const formIds = useMemo(() => {
    if (!!context.form) {
      const rootId = context.form.id;
      const fromId = context.form.$children?.find(c =>
        c.id.match(/^From/i),
      )?.id;
      const joinsId = context.form.$children?.find(c =>
        c.id.match(/^JoinsGroup/i),
      )?.id;

      if (fromId === undefined || joinsId === undefined) return;

      return [rootId, fromId, joinsId];
    }
  }, [context.form]);

  const sqlChoices = useMemo(() => {
    if (!!context.lists && !!context.data && !!formIds) {
      const [rootId, fromId, joinsId] = formIds;
      const fields: FieldAlias[] = [];
      const queryTables: TableAlias[] = [];

      if (!context.data[rootId]) return;

      if (!!context.data[rootId][fromId]) {
        const from = context.data[rootId][fromId] as IQueryBuilderTable;
        queryTables.push({
          id: fromId,
          table: from.table,
          alias: from.alias,
          list: context.lists.find(l => l.Table === from.table),
        });
      }

      if (!!context.data[rootId][joinsId]) {
        const joinsGroup = context.data[rootId][joinsId];
        for (const key of Object.keys(joinsGroup)) {
          const joinUuid = key.replace('JoinRule:~:', '');
          const join = joinsGroup[key][
            `Join:~:${joinUuid}`
          ] as IQueryBuilderJoin;
          if (!!join) {
            queryTables.push({
              id: `Join:~:${joinUuid}`,
              table: join.table,
              alias: join.alias,
              list: context.lists.find(l => l.Table === join.table),
            });
          }
        }
      }

      if (queryTables.length === 0) return;

      for (const queryTable of queryTables) {
        if (!!queryTable.list)
          fields.push(
            ...[
              {
                ...queryTable,
                tableAlias: queryTable.alias,
                column: 'id',
              },
              ...DefaultFields(queryTable.list).map(f => {
                return {
                  ...queryTable,
                  tableAlias: queryTable.alias,
                  column: f.ColumnName,
                };
              }),
              ...queryTable.list.Fields.map(f => {
                return {
                  ...queryTable,
                  tableAlias: queryTable.alias,
                  column: f.ColumnName,
                };
              }),
            ],
          );
      }

      return fields;
    }
  }, [lists, context.data, formIds]);

  useEffect(
    function onTableChoicesUpdated() {
      updateContext(d => {
        d.tableChoices = tableChoices ?? [];
      });
    },
    [tableChoices],
  );

  useEffect(
    function onFieldChoicesUpdated() {
      if (context.type !== undefined) {
        updateContext(d => {
          d.fieldChoices =
            context.type === IQueryBuilderType.Basic
              ? basicChoices
              : sqlChoices ?? [];
        });
      }
    },
    [type, lists, context.data],
  );

  const onInnerChange = (element: IFormElement, value: Record<string, any>) =>
    updateContext(d => {
      d.data[element.id] = value;
    });

  useEffect(
    function onDataUpdated() {
      if (!!context.data) {
        onChange(context.data);
      }
    },
    [context.data],
  );

  return (
    <>
      <queryBuilderContext.Provider value={[context, updateContext]}>
        <div className="AdaptiveForm">
          <AdaptiveRenderer
            form={context.form}
            onChange={onInnerChange}
            data={context.data}
            components={{
              'Input.FieldSelect': FieldSelect as any,
              'Input.TableSelect': TableSelect,
              'Input.OperandField': OperandField,
              'Input.AndOrToggle': AndOrToggle,
              'Input.TableAlias': TableAliasInput,
              'Input.GenericField': GenericField as any,
              'Input.OrderBy': OrderBySelect,
              'Form.AddGroup': AddGroup as any,
              'Form.AddRule': AddRule as any,
              'Form.AddJoin': AddJoin as any,
              'Form.RemoveItem': RemoveItem as any,
            }}
          />
        </div>
      </queryBuilderContext.Provider>
    </>
  );
}

export function useQueryBuilderContext(): [
  IQueryBuilderContext,
  Updater<IQueryBuilderContext>,
] {
  return useContext(queryBuilderContext);
}
