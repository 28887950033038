import { useRapidApplication } from './rapid-application';
/**
 * Uses the current site object out of the RAPID application context.
 *
 * @requires ManagedApplicationContext
 * @hook
 */
export function useCurrentSite() {
    const [app, updateApp] = useRapidApplication();
    return [
        app.currentSite,
        function updateCurrentSite(d) {
            updateApp(draft => {
                draft.currentSite = d(draft.currentSite);
            });
        },
    ];
}
