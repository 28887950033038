import React from 'react';
import { Modal, Form, notification, Input } from 'antd';

interface AddDatasetFormProps {
  visible: boolean;
  onSubmit: (name: string) => void;
  onCancel: () => void;
}

const AddDatasetForm: React.FC<AddDatasetFormProps> = ({
  visible,
  onSubmit,
  onCancel,
}) => {
  const [form] = Form.useForm();

  return (
    <Modal
      visible={visible}
      title="Add Dataset"
      okText="Add"
      cancelText="Cancel"
      onCancel={() => {
        form.resetFields();
        onCancel();
      }}
      onOk={() => {
        form
          .validateFields()
          .then(values => {
            form.resetFields();
            onSubmit(values.name);
          })
          .catch(info => {
            notification.error({
              message: 'Validate Failed:',
              description: info,
              duration: 5,
            });
          });
      }}
    >
      <Form form={form} layout="vertical" name="form-in-modal">
        <Form.Item
          name="name"
          label="Enter a dataset name"
          rules={[{ required: true, message: 'Please enter a dataset name.' }]}
        >
          <Input></Input>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddDatasetForm;
