import React from 'react';
import { descriptionTag, fieldAttributesSchemaTag, iconTag, titleTag, } from '../symbols';
import { NumberField } from './number-field';
export function PercentageField(props) {
    return React.createElement(NumberField, { ...props });
}
PercentageField[titleTag] = 'Percentage';
PercentageField[descriptionTag] =
    'Number input with percentage symbol burnt in';
PercentageField[iconTag] = 'fa-percentage';
PercentageField[fieldAttributesSchemaTag] = [
    {
        $type: 'Input.Text',
        id: 'label',
        label: 'Label',
    },
    {
        $type: 'Input.Number',
        id: 'defaultValue',
        label: 'Default Value',
    },
    {
        $type: 'Input.Text',
        id: 'placeholder',
        label: 'Placeholder',
    },
    {
        $type: 'Input.Boolean',
        id: 'required',
        label: 'Required',
    },
];
