import React from 'react';
import { useComposedClassName } from '@rapid/sdk';
import { descriptionTag, fieldAttributesSchemaTag, iconTag, titleTag, } from '../symbols';
import { Input } from 'antd';
export function TextField({ disabled, id, value, columnName, placeholder, label, onChange, size, }) {
    const onChangeInner = (e) => {
        onChange(e.target.value);
    };
    const textFieldClass = useComposedClassName(function* () {
        yield 'Label TextInput';
        if (!!columnName)
            yield columnName;
        if (disabled)
            yield 'disabled';
    }, [columnName, disabled]);
    return (React.createElement("label", { className: textFieldClass, id: id, title: label },
        !!label && React.createElement("span", { className: "Name" }, label),
        React.createElement(Input, { disabled: disabled, onChange: onChangeInner, value: value !== null && value !== void 0 ? value : '', placeholder: placeholder, size: size })));
}
TextField[titleTag] = 'Single line of text';
TextField[descriptionTag] =
    'A single line of text input with the ability to be filled via QR code scan';
TextField[iconTag] = 'fa-text';
TextField[fieldAttributesSchemaTag] = [
    {
        $type: 'Input.Text',
        id: 'label',
        label: 'Label',
    },
    {
        $type: 'Input.Text',
        id: 'defaultValue',
        label: 'Default Value',
    },
    {
        $type: 'Input.Text',
        id: 'placeholder',
        label: 'Placeholder',
    },
    {
        $type: 'Input.Boolean',
        id: 'required',
        label: 'Required',
    },
];
