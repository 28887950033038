import { PublicClientApplication, InteractionRequiredAuthError, } from '@azure/msal-browser/dist/index';
import React, { useContext, useMemo } from 'react';
import { useRapidApplication, } from './rapid-application/index';
import { useLocalStorageState } from './use-local-storage-state';
const context = React.createContext(null);
/**
 * Provides an authentication context that allows authentication and account hooks to be
 * used anywhere underneath the context.
 *
 * > **Note:**
 * > `authority` is either `https://login.microsoftonline.com/<tenant-name>.onmicrosoft.com`
 * > for multi-tenant scenarios or `https://login.microsoftonline.com/common` for
 * > single-tenant scenarios.
 *
 * @example
 * ```ts
 * function App() {
 *   const [tenant] = useState("example-tenant");
 *   return (
 *     <AuthenticationContext clientId="82c27408-91df-431e-b3a0-1b2e7e801269"
 *       authority={`https://login.microsoftonline.com/${tenant}.onmicrosoft.com`}>
 *      {...}
 *     </AuthenticationContext>
 *   );
 * }
 * ```
 *
 * @category Authentication
 */
export function AuthenticationContext({ clientId, authority, children, }) {
    const msal = useMemo(() => new PublicClientApplication({
        auth: {
            clientId,
            authority,
            redirectUri: window.location.origin,
        },
        cache: {
            storeAuthStateInCookie: true,
            cacheLocation: 'localStorage',
        },
    }), [clientId, authority]);
    return React.createElement(context.Provider, { value: msal }, children);
}
/**
 * {@link AuthenticationContext} but takes its authority from the tenant information
 * supplied on the URL path.
 *
 * @category Authentication
 * @component
 * @internal
 */
export function ApplicationAuthenticationContext({ children, }) {
    const [app] = useRapidApplication();
    const authority = `https://login.microsoftonline.com/${app.tenant}.onmicrosoft.com`;
    return (React.createElement(AuthenticationContext, { clientId: app.clientId, authority: authority }, children));
}
/**
 * @category Authentication
 */
export function useMsal() {
    return useContext(context);
}
/**
 * @category Authentication
 */
export function useAccount(tenant, site) {
    const context = useMsal();
    const [accountId, setAccountId] = useLocalStorageState(`account::${tenant}::${site}`);
    const realAccount = useMemo(() => context.getAccountByHomeId(accountId), [tenant, site, context, accountId]);
    return [realAccount, setAccountId];
}
/**
 * Returns a window.fetch client primed with an authentication token
 * for the specified scopes.
 * @category Authentication
 */
export function useAuthenticatedFetch(authority, ...scopes) {
    const context = useMsal();
    return async function fetch(input, init = {}) {
        var _a;
        const token = await context.acquireTokenSilent({
            authority,
            scopes,
        })
            .catch(e => {
            if (e instanceof InteractionRequiredAuthError) {
                return context.acquireTokenPopup({
                    authority,
                    scopes,
                });
            }
        });
        return await window.fetch(input, {
            ...init,
            headers: {
                ...((_a = init.headers) !== null && _a !== void 0 ? _a : {}),
                authorization: `Bearer ${token.accessToken}`,
            },
        });
    };
}
/**
 * @category Authentication
 */
export function useRapidAuthenticatedFetch() {
    const [app] = useRapidApplication();
    const fetch = useAuthenticatedFetch(`https://login.microsoftonline.com/${app.tenant}.onmicrosoft.com`, `cd5db0ec-1419-4ae6-9434-21cfb83fc42d/.default`);
    return fetch;
}
/**
 * @category Authentication
 */
export function useGraphAuthenticatedFetch(...scopes) {
    const [app] = useRapidApplication();
    const fetch = useAuthenticatedFetch(`https://login.microsoftonline.com/${app.tenant}.onmicrosoft.com`, ...scopes);
    return fetch;
}
