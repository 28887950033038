import { IQueryBuilderSQL } from "@rapid/data-model/lib/query-builder";
import { IAdaptiveViewTableColumn } from "../components/dataTable/AdaptiveViewTable";
import { IAdaptiveView } from "../features/AdaptiveViewsList/adaptiveViewsList";

export const findTable = (tableOrAlias: string, query: IQueryBuilderSQL): string => {
  if (tableOrAlias === query.from.alias || tableOrAlias === query.from.table)
    return query.from.table;

  return (
    query.joins?.find(j => j.alias === tableOrAlias)?.table || tableOrAlias
  );
};

export const adaptiveViewColumns = (view: IAdaptiveView): IAdaptiveViewTableColumn[] => { 
    const columns: IAdaptiveViewTableColumn[] = [];
    for (const select of view.schema.select) {
      const [tableOrAlias, column] = select.split('.');
      columns.push({
        columnName: column,
        tableName: findTable(tableOrAlias, view.schema),
        dataIndex: select.replace('.', '$'),
      });
    }
    return columns;
}