import React from "react";
export default class TextField extends React.Component {
    constructor(props) {
        super(props);
        this.onValueChange = (event) => {
            const value = event.currentTarget.value.slice(0, this.props.maxLength);
            const newLength = value.length;
            this.setState((oldState) => {
                let newState = { ...oldState };
                newState.currentLength = newLength;
                if (!this.props.defaultValue) {
                    this.props.onChange && this.props.onChange(value, event);
                }
                else {
                    newState.defaultValue = value;
                }
                return newState;
            });
        };
        const value = props.defaultValue || props.value || "";
        this.state = {
            currentLength: value.length,
            defaultValue: value,
        };
    }
    render() {
        const { className, description, label, required, maxLength, value, } = this.props;
        const { currentLength, defaultValue } = this.state;
        return (React.createElement("label", { className: (!!className ? className : "") + " rp-TextField" },
            React.createElement("div", { className: `rp-TextField__label rp-text-secondary ${required ? "required" : ""}` }, label),
            React.createElement("input", { ref: this.props.ref, value: value || defaultValue, required: required, className: "rp-input", type: "text", onChange: this.onValueChange }),
            React.createElement("div", { className: "rp-TextField__description rp-text-meta" },
                React.createElement("div", null, description),
                maxLength && (React.createElement("div", { className: "rp-TextField__text-counter" },
                    React.createElement("span", null, currentLength),
                    React.createElement("span", null, "/"),
                    React.createElement("span", null, maxLength))))));
    }
}
