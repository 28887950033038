import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useMsal } from '../authentication';
import { AppLayout, Button, Card, CardBody, Heading, SiteMain, Spinner, } from '../ui/index';
import useCurrentConnections from '../use-current-connection';
import { useCurrentSiteEndpoint } from '../use-current-site-endpoint';
import { useSiteCSSVariables } from '../use-site-css-variables';
import { useRapidApplication } from './application-context';
export function MultiSiteContext({ children }) {
    const [app, updateApp] = useRapidApplication();
    const params = useParams();
    useEffect(function paramsDidChangeEffect() {
        updateApp(d => {
            d.tenant = params.tenant;
            d.site = params.site;
            d.env = params.env;
        });
    }, [params.env, params.tenant, params.site]);
    return !!app.env && !!app.tenant && !!app.site ? (React.createElement(SiteContext, null, children)) : null;
}
export function SiteContext({ children }) {
    const ua = useMsal();
    const [app, updateApp] = useRapidApplication();
    const conns = useCurrentConnections();
    const [signInRequired, setSignInRequired] = useState(!(conns === null || conns === void 0 ? void 0 : conns.length));
    const siteEp = useCurrentSiteEndpoint();
    useSiteCSSVariables();
    function onSignInClicked() {
        ua.loginPopup({
            authority: `https://login.microsoftonline.com/${app.tenant}.onmicrosoft.com`,
            scopes: [],
        })
            .then(account => {
            updateApp(d => {
                var _a, _b;
                d.connections.push({
                    key: `${app.tenant}-${app.site}-${app.env}`,
                    tenant: (_a = app.tenant) === null || _a === void 0 ? void 0 : _a.toLowerCase(),
                    site: (_b = app.site) === null || _b === void 0 ? void 0 : _b.toLowerCase(),
                    lastVisit: new Date().toISOString(),
                    env: app.env,
                    upn: account.account.username,
                });
            });
            ua.setActiveAccount(account.account);
            setSignInRequired(false);
        })
            .catch(err => { });
    }
    useEffect(function componentDidMountEffect() {
        if (conns.length) {
            ua.setActiveAccount(ua.getAccountByUsername(conns[0].upn));
        }
        if (!signInRequired) {
            let principalId = null;
            let principalUpn = null;
            let principalDisplayName = null;
            siteEp
                .get()
                .then(resp => {
                principalId = resp.headers.get('x-principal-id');
                principalUpn = resp.headers.get('x-principal-upn');
                principalDisplayName = resp.headers.get('x-principal-display-name');
                return resp.json();
            })
                .then(site => {
                updateApp(d => {
                    d.currentPrincipalId = +principalId;
                    d.currentPrincipalDisplayName = principalDisplayName;
                    d.currentPrincipalUpn = principalUpn;
                    d.currentSite = site;
                });
            })
                .catch(err => {
                setSignInRequired(true);
            });
        }
    }, [signInRequired]);
    return (React.createElement(React.Fragment, null, !app.currentSite ? (React.createElement(AppLayout, { templateName: "solidbackground" },
        React.createElement(SiteMain, null,
            React.createElement(Card, null,
                React.createElement(CardBody, null,
                    React.createElement("div", { className: "rp-flex rp-flex-middle rp-flex-column" },
                        React.createElement(Heading, null, app.displayName),
                        signInRequired ? (React.createElement(React.Fragment, null,
                            React.createElement(Button, { buttonStyle: "link", onClick: onSignInClicked }, "Sign in"))) : (React.createElement(React.Fragment, null,
                            React.createElement(Spinner, { size: 2 }),
                            React.createElement("small", null, "Signing you in..."))))))))) : (children)));
}
