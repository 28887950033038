import React, { useEffect, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useMsal } from '../../authentication';
import { AppLayout, Button, Card, CardBody, CardFooter, CardHeader, Heading, Icon, IconButton, ListItem, ListItemCenterContent, ListItemLeftContent, ListItemRightContent } from '../../ui/index';
import { SiteFooter, SiteFooterCopy } from '../../ui/site-footer';
import { SiteMain } from '../../ui/site-main';
import useCurrentConnections from '../../use-current-connection';
import { useRapidApplication } from '../application-context';
import NewConnection from './new-connection';
export function AddConnectionCard() {
    var _a, _b, _c, _d, _e;
    const [app, updateApp] = useRapidApplication();
    const conns = useCurrentConnections();
    const ua = useMsal();
    const history = useHistory();
    const match = useRouteMatch();
    const [displayAddNewSite, setDisplayAddNewSite] = useState(false);
    useEffect(function componentDidMountEffect() {
        if (conns.length === 1 && conns[0].env) {
            const conn = conns[0];
            if (conn.env) {
                openSite(conn);
            }
            else {
                setDisplayAddNewSite(true);
            }
        }
        else if (!conns.length) {
            setDisplayAddNewSite(true);
        }
    }, []);
    const toggleDisplayAddNewSite = () => {
        setDisplayAddNewSite(!displayAddNewSite);
    };
    function openSite(site) {
        history.push(`/_${site.env}/${site.tenant}/${site.site}`);
    }
    const onSiteClicked = (site) => (ev) => {
        ev.preventDefault();
        openSite(site);
    };
    function onNewConnection(env, tenant, site) {
        ua.loginPopup({
            authority: `https://login.microsoftonline.com/${tenant}.onmicrosoft.com`,
            scopes: [],
        })
            .then(account => {
            const newSite = {
                key: `${tenant}-${site}`,
                tenant: tenant === null || tenant === void 0 ? void 0 : tenant.toLowerCase(),
                site: site === null || site === void 0 ? void 0 : site.toLowerCase(),
                lastVisit: new Date().toISOString(),
                env,
                upn: account.account.username,
            };
            updateApp(d => {
                d.connections.push(newSite);
            });
            openSite(newSite);
        })
            .catch(err => { });
    }
    function onClearAllSitesClicked() {
        updateApp(d => {
            d.connections = [];
        });
    }
    const onClearSiteClicked = (id) => (ev) => {
        ev.stopPropagation();
        updateApp(d => {
            d.connections = d.connections.filter(i => i.key !== id);
        });
    };
    return (React.createElement(AppLayout, { templateName: "solidbackground" },
        React.createElement(SiteMain, null,
            React.createElement(Card, { className: "rp-login-panel rp-width-1-1 rp-branding-boxshadow" },
                React.createElement(CardHeader, null,
                    React.createElement("div", { className: "rp-flex rp-flex-middle rp-flex-column" },
                        React.createElement(Heading, null, app.displayName),
                        !displayAddNewSite && !!((_a = app.connections) === null || _a === void 0 ? void 0 : _a.length) && (React.createElement("div", null, "Pick from the list below")),
                        !!match.params.tenant &&
                            !!match.params.site &&
                            displayAddNewSite && (React.createElement("div", null, "Add a tenant and site name to log in")))),
                React.createElement(CardBody, null,
                    !displayAddNewSite && (React.createElement("div", { className: "rp-login-panel-recent-sites rp-flex rp-flex-column" },
                        React.createElement("div", { className: "rp-login-panel-recent-sites-scroll" }, (_b = app.connections) === null || _b === void 0 ? void 0 : _b.map(details => (React.createElement(ListItem, { key: `${details.tenant}-${details.site}`, onClick: onSiteClicked(details) },
                            React.createElement(ListItemLeftContent, null,
                                React.createElement("div", { className: "rp-icon-border rp-flex rp-flex-middle rp-flex-center" },
                                    React.createElement(Icon, { iconSize: "2x" }))),
                            React.createElement(ListItemCenterContent, null,
                                React.createElement("div", { className: "rp-flex rp-flex-column rp-list-center" },
                                    React.createElement("div", { className: "rp-list-item-title" }, details.site),
                                    React.createElement("div", { className: "rp-list-item-subtitle" }, details.tenant))),
                            React.createElement(ListItemRightContent, null,
                                React.createElement("div", { className: "rp-flex rp-flex-middle rp-flex-center rp-right-list-inner" },
                                    React.createElement(IconButton, { className: "rp-remove-site-icon rp-flex rp-flex-middle rp-flex-center", onClick: onClearSiteClicked(details.key), iconName: "fa-trash-alt", iconStyle: "light", buttonStyle: "text" }))))))),
                        React.createElement(Button, { buttonStyle: "primary", buttonSize: "small", className: "rp-add-site-button", fullWidth: true, onClick: toggleDisplayAddNewSite }, "Add"),
                        !!((_c = app.connections) === null || _c === void 0 ? void 0 : _c.length) && (React.createElement(IconButton, { margin: "small-top", buttonSize: "small", buttonStyle: "text", iconName: "fa-trash-alt", iconStyle: "light", onClick: onClearAllSitesClicked, fullWidth: true }, "Clear all")))),
                    displayAddNewSite && (React.createElement(React.Fragment, null,
                        React.createElement(NewConnection, { onNewConnection: onNewConnection }),
                        !!((_d = app.connections) === null || _d === void 0 ? void 0 : _d.length) && (React.createElement(IconButton, { buttonSize: "small", margin: "small-top", buttonStyle: "text", fullWidth: true, onClick: toggleDisplayAddNewSite, iconName: "fa-chevron-circle-left" }, "View saved sites"))))),
                React.createElement(CardFooter, null,
                    React.createElement("div", { className: "rp-login-panel-footer rp-card-footer rp-flex rp-flex-center" },
                        React.createElement(SiteFooter, null,
                            React.createElement(SiteFooterCopy, null, (_e = app.copyright) !== null && _e !== void 0 ? _e : (React.createElement(React.Fragment, null, "Copyright \u00A9 2020 RAPID PLATFORM PROJECTS PTY LTD"))))))))));
}
